import { FC, useEffect, useRef } from 'react';
import { useStateHandlers } from 'hooks';
import { education } from 'libs/http/api/education/education';

interface Props {
  episodeId: number;
  count: number;
  initialValue: number;
}

export const Counter: FC<Props> = ({ children, episodeId, count, initialValue }) => {
  const [state, setState] = useStateHandlers({ claps: 0, removeClasses: false });

  const timer = useRef<NodeJS.Timeout>();
  const timer2 = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (count !== initialValue) {
      setState((prevState) => ({ claps: ++prevState.claps, removeClasses: false }));
      education.addClapsAdd.action(Number(episodeId));
    }

    timer2.current = setTimeout(() => {
      setState({ removeClasses: true });
    }, 100);

    timer.current = setTimeout(() => {
      setState({ claps: 0 });
    }, 1000);

    return () => {
      if (timer.current !== undefined) {
        clearTimeout(timer.current);
      }

      if (timer2.current !== undefined) {
        clearTimeout(timer2.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  return (
    <div className={`m-counter ${state.claps && !state.removeClasses ? 'add' : ''}`}>
      <div
        className={`m-counter-number ${state.claps > 0 ? 'active' : ''} ${
          state.claps > 1 && !state.removeClasses ? 'add' : ''
        }`}
      >
        +{state.claps}
      </div>

      {children}
    </div>
  );
};
