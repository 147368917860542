import { AxiosResponse, Canceler } from 'axios';
import { axios, cancelToken } from 'libs/http/axios';

const EVENTS_URL = process.env.REACT_APP_EVENTS_URL;

export const events = {
  webinar: {
    action: (params = {}): Promise<AxiosResponse> =>
      axios.get(`${EVENTS_URL?.replace(/\/\s*$/, '')}/api/v2/webinars`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (events.webinar.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  profileWebinars: {
    action: (params = {}): Promise<AxiosResponse> =>
      axios.get(`${EVENTS_URL?.replace(/\/\s*$/, '')}/api/v2/profile/webinars`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (events.profileWebinars.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  shareLink: {
    action: (data = {}): Promise<AxiosResponse> =>
      axios.post(`${EVENTS_URL?.replace(/\/\s*$/, '')}/api/v2/share`, data, {
        cancelToken: new cancelToken((c: Canceler) => (events.shareLink.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
};
