import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { NotifyContext, Space } from 'ebs-design';
import { usePortal } from 'hooks/usePortal';
import { NotifyProps } from 'ebs-design/dist/components/molecules/Notify/Notify';
import { NotifyItem } from './NotifyItem';

export const NotifyContainer: FC<NotifyProps> = ({ size = 'medium', timeout = 3000 }) => {
  const createPortal = usePortal('notify-portal');
  const { list, remove } = useContext(NotifyContext);

  useEffect(() => {
    const interval = setInterval(() => {
      if (list.length) {
        remove(0);
      }
    }, timeout);

    return () => {
      clearInterval(interval);
    };
  }, [list]);

  const onClose = useCallback((i) => remove(i), []);

  const notification = useMemo(() => (list.length > 0 ? list[list.length - 1] : undefined), [list]);

  return createPortal(
    <Space direction="vertical" size={size} className="m-notify">
      {notification && <NotifyItem key={0} size={size} onClose={() => onClose(0)} {...notification} />}
    </Space>,
  );
};
