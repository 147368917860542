import { useStateHandlers } from 'hooks';
import { getRoute, setFieldsError } from 'libs/utils';
import { Button, Form, Label, Loader, useForm, useNotify } from 'ebs-design';
import { PasswordInput } from 'ui/molecules/PasswordInput';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { AxiosResponse } from 'axios';

interface SetNewPassFormProps {
  apiRequest: (data: object) => Promise<AxiosResponse<any>>;
  autoSetupToken?: string;
}

export const SetNewPassForm = ({ apiRequest, autoSetupToken }: SetNewPassFormProps) => {
  const { token } = useParams<{ token?: string }>();
  const { push } = useHistory();
  const { t } = useIntl();
  const notify = useNotify();
  const [form] = useForm();

  const [state, setState] = useStateHandlers<{ loading: boolean; errors: string }>({
    loading: false,
    errors: '',
  });

  const onFinish = (values: any) => {
    setState({ loading: true });

    apiRequest({ ...values, token: token || autoSetupToken })
      .then(() => {
        notify.success({
          title: t('success'),
          description: autoSetupToken ? t('yourPassWasSet') : t('passwordWasSet'),
        });
        push(getRoute('SignInPage'));
      })
      .catch(({ response }: any) => {
        if (response.data.token) {
          setState({ errors: response.data.token });
          notify.error({ title: '', description: response.data.token });
        }

        if (response.data && !response.data.detail) {
          setFieldsError(response.data, form);
        }
        if (response.data.detail) {
          notify.error({ title: 'Error', description: response.data.detail });
        }
        if (response.data.non_field_errors) {
          setState({ errors: response.data.non_field_errors[0] });
          notify.error({ title: 'Error', description: response.data.non_field_errors[0] });
        }
        if (Array.isArray(response.data)) {
          setState({ errors: response.data[0] });
          notify.error({ title: 'Error', description: response.data[0] });
        }
      })
      .finally(() => setState({ loading: false }));
  };

  const compareToFirstPassword = (rule: any, value: string, callback: any) => {
    if (value && value !== form.getFieldValue('password')) {
      callback(t('pass_dont_match'));
    } else {
      callback();
    }
  };

  return (
    <Loader loading={state.loading}>
      <Form form={form} onFinish={onFinish}>
        <div className="d-flex justify-content--center">
          <Label status="danger" type="ghost" text={state.errors} />
        </div>
        <Form.Field
          label={t('new_password')}
          name="password"
          className="sign-in-page__form-field"
          rules={[
            {
              required: true,
              message: t('this_field_required'),
            },
            {
              min: 8,
              message: t('pass_minim_8_chars'),
            },
          ]}
        >
          <PasswordInput placeholder={t('Password')} type="password" size="large" />
        </Form.Field>

        <Form.Field
          label={t('repeat_password')}
          name="confirm_password"
          className="sign-in-page__form-field"
          rules={[
            {
              required: true,
              message: t('this_field_required'),
            },
            {
              min: 8,
              message: t('pass_minim_8_chars'),
            },
            {
              required: true,
              validator: compareToFirstPassword,
            },
          ]}
        >
          <PasswordInput placeholder={t('repeat_password')} type="password" size="large" />
        </Form.Field>

        <div className="sign-in-page__form-footer">
          <Button type="primary" className="m-submit" submit>
            {t('Continue')}
          </Button>
        </div>
      </Form>
    </Loader>
  );
};
