import React, { ReactNode, useRef } from 'react';
import { useStateHandlers, useOutsideAlerter } from 'hooks';

interface Props {
  title?: string;
  tooltip?: ReactNode;
  className?: string;
  onClick?: () => void;
}

export const HeaderTooltip: React.FC<Props> = ({ children, title, tooltip, className = '', onClick }) => {
  const wrapperRef = useRef(null);

  const [state, setState] = useStateHandlers({ showTooltip: false });

  const onTooltipShowChange = () => {
    setState((prevState) => ({ showTooltip: !prevState.showTooltip }));
    onClick && onClick();
  };

  useOutsideAlerter(wrapperRef, onTooltipShowChange);

  const tooltipComponent = React.Children.map(tooltip, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { closeTooltip: () => setState({ showTooltip: false }) });
    }
    return child;
  });

  return (
    <>
      {state.showTooltip && (
        <div className={`header-tooltip ${className}`} ref={wrapperRef}>
          <div className="header-tooltip__body">
            {title && <div className="ebs-tooltip__body-title">{title}</div>}
            {tooltipComponent}
          </div>
        </div>
      )}
      <div onClick={onTooltipShowChange}>{children}</div>
    </>
  );
};
