import { useMemo } from 'react';
import { useIntl } from 'estafette-intl';
import cn from 'classnames';
import dayjs from 'dayjs';
import GoogleBtn from 'assets/images/button-google-min.png';
import AppleBtn from 'assets/images/button-apple-min.png';
import { LanguagesTooltip } from 'ui/atoms/LanguagesTooltip/LanguagesTooltip';
import { Container } from 'ui/atoms';
import { $Object } from 'libs/globalTypes';
import { Logo } from 'resources';

const blogLocalization: $Object = { en: '', ro: '-ro', pl: '-pl', ru: '-ru' };
const webVersion = process.env.REACT_APP_WEB_VERSION;

interface Props {
  isWatchPage: boolean;
}

export const Footer: React.FC<Props> = ({ isWatchPage }) => {
  const { t, locale } = useIntl();

  const correctBlogLink = useMemo(() => blogLocalization[locale], [locale]);

  return (
    <div className={cn('footer', { white: isWatchPage })}>
      <Container>
        <div className="footer__wrapper">
          <div className="footer__first-column">
            <div className="footer__about">
              <Logo className="footer__logo" />
              <span className="footer__meta-description">{t('dynamic_link_meta_description')}</span>
              <div className="footerTooltip">
                <LanguagesTooltip footer />
              </div>
              <div className="footer__copyright">
                © {dayjs().get('year')} {t('all_rights_reserved')}. {webVersion && `v${webVersion}`}
              </div>
            </div>
            <div className="footer__menu">
              <div>
                <a href={`https://parentsapp.co/blog${correctBlogLink}`} className="mb-12 d-flex">
                  {t('parents_blog')}
                </a>
              </div>
              <div>
                <a href="https://parentsapp.co/terms/" className="mb-12 d-flex">
                  {t('TermsAndConditions')}
                </a>
              </div>
              <div>
                <a href="https://parentsapp.co/privacy/" className="mb-12 d-flex">
                  {t('privacy_policy')}
                </a>
              </div>
            </div>
          </div>
          <div className="footer__apps">
            <a
              href="https://play.google.com/store/apps/details?id=com.parents.app&utm_campaign=website&utm_medium=website&utm_source=parentsapp&utm_content=googleplaybutton"
              className="footer__app-store"
            >
              <img src={GoogleBtn} alt="to-google-play" />
            </a>
            <a
              href="https://apps.apple.com/app/apple-store/id1468883913?pt=118961844&ct=website&mt=8&utm_campaign=website&utm_medium=website&utm_source=parentsapp&utm_content=appstorebutton"
              className="footer__app-store"
            >
              <img src={AppleBtn} alt="to-apple-store" />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};
