import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useQuery<T>(): T {
  const { search } = useLocation();

  return useMemo(() => {
    let result = {};
    // @ts-ignore
    new URLSearchParams(search).forEach((value: string, key: string) => (result[key] = value));
    return result as T;
  }, [search]);
}
