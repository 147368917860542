import { Route, Switch, useLocation } from 'react-router-dom';
import { useIntl } from 'estafette-intl';

import { protectedRoutes, publicRoutes } from 'routes';
import { ProtectedRoute } from 'ui/ProtectedRoute';

export const RouterSwitch = () => {
  const location = useLocation();
  const { t } = useIntl();

  return (
    <Switch>
      {publicRoutes.map(({ name, component: Component, path, exact }) => (
        <Route key={name} path={path} exact={exact} render={() => <Component />} />
      ))}
      {protectedRoutes.map(({ name, component: Component, path, exact }) => (
        <ProtectedRoute key={name} path={path} exact={exact} render={() => <Component />} />
      ))}
      <Route
        key="notFoundPage"
        path={location.pathname}
        render={() => (
          <section className="m-containter">
            <h1 className="text-white">{t('this_page_does_not_exist')}</h1>
          </section>
        )}
      />
    </Switch>
  );
};
