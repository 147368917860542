import { useContext, useEffect, useMemo } from 'react';
import { Button, Col, Icon, Row } from 'ebs-design';
import { useLocation } from 'react-router-dom';
import dayjs, { OpUnitType } from 'dayjs';
import { load, save } from 'react-cookies';
import { useIntl } from 'estafette-intl';
import { useRequest } from 'estafette';
import { StorageKeys } from 'libs/cookies';
import { firebase } from 'libs/http/api/firebase/firebase';
import { useStateHandlers } from 'hooks';
import { ResponsiveContext } from 'contexts/ResponsiveContext';
import { breakpoints } from 'libs/globalVars';
import { getActiveRouteByPath } from 'libs/utils';

import { getMobileRoute, mobileRoutes } from 'libs/mobileRoutes';

const timeToAppear: { value: number; unit: OpUnitType } = { value: 1, unit: 'd' };

export const RedirectToAppModal = () => {
  const { width } = useContext(ResponsiveContext);
  const location = useLocation();
  const { t } = useIntl();

  const redirectLink = useRequest<{ shortLink: string }>({ data: { shortLink: '#' } });

  const [state, setState] = useStateHandlers({ showRedirect: false, minimized: false });

  const mobileRoute = useMemo(
    () => getMobileRoute(mobileRoutes[getActiveRouteByPath(location.pathname) || ''], location.pathname),
    [location],
  );

  useEffect(() => {
    const tryRedirect = () => {
      const redirectObj: { time: dayjs.Dayjs } = load(StorageKeys.tryRedirect);
      const currentTime = dayjs();

      if (
        (!redirectObj || currentTime.diff(redirectObj.time, timeToAppear.unit) >= timeToAppear.value) &&
        width < breakpoints.sm &&
        mobileRoute
      ) {
        redirectLink.request(
          firebase.generateDynamicLink.action({
            dynamicLinkInfo: {
              domainUriPrefix: 'https://parentsapps.page.link',
              link: `https://parentsapp.co?${mobileRoute}`,
              androidInfo: {
                androidPackageName: process.env.REACT_APP_ANDROID_PACKAGE_NAME,
              },
              iosInfo: {
                iosBundleId: process.env.REACT_APP_IOS_BUNDLE_ID,
              },
            },
          }),
        );
        setState({ showRedirect: true });
        saveCurrentTime(currentTime);
      }
    };

    tryRedirect();
  }, [mobileRoute]);

  const saveCurrentTime = (time: dayjs.Dayjs) => save(StorageKeys.tryRedirect, { time }, { path: '/' });

  const onRedirectCloseClick = () =>
    setState((prevState) => ({ [prevState.minimized ? 'showRedirect' : 'minimized']: !prevState.minimized }));

  return (
    <>
      {state.showRedirect && (
        <div className="redirect-container">
          <div className="redirect-container__gradient" />

          <div className={state.minimized ? 'flex align-items--center' : ''}>
            <Row className={`redirect-container__bold ${state.minimized ? '' : 'mt-30'}`}>{t('wantToApp')}</Row>
            {!state.minimized && <Row className="redirect-container__info mt-5">{t('toAppInfo')}</Row>}
            <Row className={state.minimized ? `` : 'mt-15'}>
              <Col className="flex justify-content--center">
                <a href={redirectLink.data.shortLink} className="w-90">
                  <Button
                    type="primary"
                    loading={redirectLink.loading}
                    className={`redirect-container__redirect ${state.minimized ? 'w-auto' : ''}`}
                    disabled={redirectLink.loading}
                  >
                    {t(state.minimized ? 'openApp' : 'switchToApp')}
                  </Button>
                </a>
              </Col>
            </Row>
            <Row className={`redirect-container__bold ${state.minimized ? '' : 'mb-20'} mt-5`}>
              <Button type="text" className="redirect-container__not-now" onClick={onRedirectCloseClick}>
                {state.minimized ? <Icon type="close" model="bold" /> : t('not_now')}
              </Button>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};
