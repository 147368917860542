import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { getRoute, addToURL } from 'libs/utils';
import { now } from 'libs/date';
import { Webinar, WebinarStatus } from 'libs/http/api/events/events.types';
import { UserContext } from 'contexts/UserContext';
import { useStateHandlers } from 'hooks';
import { Play } from 'resources';

import { ProgramCardComposition, ProgramCardProps } from './types';
import { Program } from './Program';
import { Events } from './Events';
import dayjs from 'dayjs';

const ProgramCard: React.FC<ProgramCardProps> & ProgramCardComposition = ({
  id,
  title,
  image,
  webinar,
  is_video = false,
  is_program = true,
  isEnrolled,
  children,
}) => {
  const [state, setState] = useStateHandlers({ cover: false, loading: true });

  const onImageLoad = () => {
    setState({ loading: false });
  };

  const preview = image && (
    <>
      <img src={image} className="program-card__image image-cover" alt={title} onLoad={onImageLoad} />
      {is_video && (
        <div className="program-card__image-play">
          <Play />
        </div>
      )}
      {state.loading && (
        <div className="m-hover-div">
          <Skeleton height="100%" />
        </div>
      )}
    </>
  );

  return (
    <div key={id} className="program-card">
      {!webinar ? (
        <IFLink id={id} is_program={is_program}>
          {preview}
        </IFLink>
      ) : (
        <EventLink className="program-card__image-wrapper" isEnrolled={isEnrolled} {...webinar}>
          {preview}
        </EventLink>
      )}
      {children}
    </div>
  );
};

const IFLink: React.FC<{ id?: number; is_program?: boolean }> = ({ id, is_program = true, children }) => {
  if (id && is_program)
    return (
      <Link to={getRoute('WatchProgramPage', { programId: id })} className="program-card__image-wrapper">
        {children}
      </Link>
    );
  return <div className="program-card__image-wrapper">{children}</div>;
};

interface EventLinkProps extends Webinar {
  className?: string;
  isEnrolled?: boolean;
}

export const EventLink: React.FC<React.PropsWithChildren<EventLinkProps>> = ({
  className,
  isEnrolled,
  date,
  status,
  watch_replay,
  quiz_url,
  live_room_url,
  children,
}) => {
  const { userData } = useContext(UserContext);

  return (
    <div className={className}>
      {!isEnrolled ? (
        <a
          href={addToURL(quiz_url, { email: userData?.email, source_type: 'parents.web' })}
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      ) : status === WebinarStatus.ATTENDED && isEnrolled && now > dayjs(date).add(2, 'hours').unix() ? (
        children
      ) : watch_replay ? (
        now >= dayjs(date).add(3, 'hours').unix() && now < dayjs(date).endOf('day').unix() ? (
          <a href={live_room_url} target="_blank" rel="noreferrer">
            {children}
          </a>
        ) : now > dayjs(date).endOf('day').unix() ? (
          status === WebinarStatus.NOT_ATTENDED ? (
            children
          ) : status === WebinarStatus.ATTENDED ? (
            children
          ) : (
            children
          )
        ) : (
          children
        )
      ) : status !== WebinarStatus.NOT_ATTENDED && now > dayjs(date).add(2, 'hours').unix() ? (
        children
      ) : now < dayjs(date).subtract(10, 'minutes').unix() ? (
        children
      ) : now > dayjs(date).subtract(10, 'minutes').unix() && now < dayjs(date).add(2, 'hours').unix() ? (
        <a href={live_room_url} target="_blank" rel="noreferrer">
          {children}
        </a>
      ) : (
        children
      )}
    </div>
  );
};

ProgramCard.Program = Program;
ProgramCard.Events = Events;

export { ProgramCard };
