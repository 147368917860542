import React, { ReactNode } from 'react';

interface Props {
  icon: ReactNode;
  text: string;
  height?: string;
}

export const Placeholder = ({ icon, text, height = '500px' }: Props) => {
  return (
    <div className="m-placeholder" style={{ height }}>
      {icon}
      <div className="m-placeholder__text">{text}</div>
    </div>
  );
};
