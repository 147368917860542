import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { useIntl } from 'estafette-intl';
import { Webinar, WebinarStatus } from 'libs/http/api/events/events.types';
import { UserContext } from 'contexts/UserContext';
import { now, today } from 'libs/date';
import { addToURL } from 'libs/utils';

interface Props {
  webinar: Webinar;
  isEnrolled?: boolean;
}

export const EventActionButton: React.FC<Props> = ({ webinar, isEnrolled }) => {
  const { t } = useIntl();
  const { userData } = useContext(UserContext);

  if (!isEnrolled) {
    return (
      <div className="program-card__body__footer__get-free">
        <a
          href={addToURL(webinar.quiz_url, { email: userData?.email, source_type: 'parents.web' })}
          target="_blank"
          rel="noreferrer"
        >
          {t('getAFreeInvite')}
        </a>
      </div>
    );
  }

  if (webinar.status === WebinarStatus.ATTENDED && isEnrolled && now > dayjs(webinar.date).add(2, 'hours').unix()) {
    return <></>; //todo rate masterclass
  }

  if (webinar.watch_replay) {
    if (now >= dayjs(webinar.date).add(3, 'hours').unix() && now < dayjs(webinar.date).endOf('day').unix()) {
      return (
        <div className="program-card__body__footer__get-free">
          <a href={webinar.live_room_url} target="_blank" rel="noreferrer">
            {t('watchReplay')}
          </a>
        </div>
      );
    }

    if (now > dayjs(webinar.date).endOf('day').unix()) {
      if (webinar.status === WebinarStatus.NOT_ATTENDED) {
        return <>{t('not_attended')}</>;
      }

      if (webinar.status === WebinarStatus.ATTENDED) {
        return <></>; //todo rate this masterclass
      }
    }

    const time = dayjs(webinar.date).add(3, 'hours').diff(today, 'minutes');
    const hours = Math.floor(time / 60);
    const minutes = time % 60;

    return (
      <div className="program-card-wide__footer__remaining">
        {t('recordingIn')} {t('hours_minutes', { hours: hours.toString(), min: (minutes || 1).toString() })}
      </div>
    );
  }

  if (isEnrolled) {
    if (webinar.status !== WebinarStatus.NOT_ATTENDED && now > dayjs(webinar.date).add(2, 'hours').unix()) {
      return <>{t('not_attended')}</>;
    }

    if (now < dayjs(webinar.date).subtract(10, 'minutes').unix()) {
      const time = dayjs(webinar.date).diff(today, 'minutes');
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      return (
        <>
          {t('liveIn')} {t('hours_minutes', { hours: hours.toString(), min: minutes.toString() })}
        </>
      );
    }

    if (now > dayjs(webinar.date).subtract(10, 'minutes').unix() && now < dayjs(webinar.date).add(2, 'hours').unix()) {
      return (
        <div className="program-card__body__footer__get-free">
          <a href={webinar.live_room_url} target="_blank" rel="noreferrer">
            {t('attendNow')}
          </a>
        </div>
      );
    }
  }
  return <></>;
};
