export interface Webinar {
  id: number;
  title: string;
  quiz_url: string;
  image_url: string;
  date: string;
  is_free: boolean;
  attending: number;
  latest_attending: User[];
  live_room_url?: string;
  status?: WebinarStatus;
  cancel_registration_url?: string;
  language: string;
  watch_replay: boolean;
}

export enum WebinarStatus {
  PENDING = 'pending',
  ONLINE = 'online',
  ATTENDED = 'attended',
  NOT_ATTENDED = 'not_attended',
}

export interface User {
  id: number;
  parents_id?: number;
  first_name: string;
  last_name: string;
  email: string;
  avatar?: string;
}

export const emptyWebinar: Webinar = {
  id: 0,
  title: '',
  quiz_url: '',
  image_url: '',
  date: '',
  is_free: false,
  attending: 0,
  latest_attending: [],
  live_room_url: '',
  status: undefined,
  cancel_registration_url: '',
  language: '',
  watch_replay: false,
};
