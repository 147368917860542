import React from 'react';
import { Button } from 'ebs-design';
import { useIntl } from 'estafette-intl';
import { AudioPlayer } from 'ui/organisms/AudioPlayer/AudioPlayer';
import { ProgramReadOnly } from 'libs/http/api/education/education.types';
import { useStateHandlers } from 'hooks';
import { Play } from 'resources';

interface Props {
  program: ProgramReadOnly;
}

export const SampleAudio: React.FC<Props> = ({ program }) => {
  const { t } = useIntl();

  const [state, setState] = useStateHandlers({ showPlayer: false });
  return (
    <div className="sample-audio">
      {!state.showPlayer && (
        <div className="sample-audio__button-wrapper">
          <Button className="sample-audio__button" onClick={() => setState({ showPlayer: true })}>
            <div className="sample-audio__button__play">
              <Play />
            </div>
            {t('sampleNow')}
          </Button>
        </div>
      )}
      {state.showPlayer && program.sample_episode?.sample && <AudioPlayer src={program.sample_episode?.sample} />}
    </div>
  );
};
