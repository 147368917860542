import { FC, Suspense, useContext, useEffect, useMemo } from 'react';
import { useIntl } from 'estafette-intl';
import { useLocation } from 'react-router-dom';
import { Loader, NotifyContext, Space } from 'ebs-design';
import { Icon } from 'ui/atoms/Icon/Icon';
import { CommunityProvider } from 'contexts/CommunityContext';
import { NotifyContainer, SideNav } from 'ui/molecules';
import { Notifications } from 'features/programs/components/organisms/Notifications';
import { DevicesProvider } from 'contexts/DevicesContext';
import { RedirectToAppModal } from 'ui/molecules/RedirectToAppModal/RedirectToAppModal';
import { SubLayouts } from 'layouts';
import { Header } from 'ui/organisms/Header/Header';
import { Footer } from 'ui/organisms/Footer/Footer';

export const Layout: FC<{ activeRouteName: string }> = ({ children, activeRouteName }) => {
  const { t } = useIntl();
  const { list, remove } = useContext(NotifyContext);
  const location = useLocation();

  useEffect(() => {
    if (list.length > 1) {
      remove(0);
    }
  }, [list]);

  const isWatchPage = useMemo(() => ['WatchEpisodePage', 'WatchProgramPage'].includes(activeRouteName), [location]);

  const CurrentLayout = useMemo(() => {
    const splitPathName = location.pathname.split('/');

    if (splitPathName[1]) {
      return SubLayouts[splitPathName[1] as keyof typeof SubLayouts];
    }
  }, [location.pathname]);

  return (
    <>
      <NotifyContainer size="small" />

      <RedirectToAppModal />

      <DevicesProvider>
        {({ showDeviceSwitch, thisDevice, activeDevice }) => (
          <CommunityProvider>
            {({ sideNav, onSideNavChange, notifications }) => (
              <>
                <SideNav>
                  {sideNav === 'notifications' && <Notifications />}
                </SideNav>

                <div className="main-layout">
                  <Header
                    showDeviceSwitch={showDeviceSwitch}
                    thisDevice={thisDevice}
                    notifications={notifications}
                    onSideNavChange={onSideNavChange}
                  />
                  {thisDevice && thisDevice.status !== 'active' && (
                    <div className="on-hold-cover">
                      <div className="m-active-device">
                        <span className="m-device-name">
                          <Space size={0} align="center">
                            <Icon type="active-device-play" />
                            <span>{t('you_are_watching_on')}</span>
                            <span className="bold">{activeDevice && activeDevice.name}</span>
                          </Space>
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="layout">
                    {/*todo delete and refactor this class name*/}
                    {CurrentLayout ? (
                      <Suspense fallback={<Loader loading />}>
                        <CurrentLayout>{children}</CurrentLayout>
                      </Suspense>
                    ) : (
                      children
                    )}
                  </div>

                  <Footer isWatchPage={isWatchPage} />
                </div>
              </>
            )}
          </CommunityProvider>
        )}
      </DevicesProvider>
    </>
  );
};
