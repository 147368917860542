export enum mobileRoutesTypes {
  actionType = 'action_type',
  parentingProgram = 'parenting_program',
  episodeParenting = 'episode_parenting',
}

interface MobileRoute {
  type: mobileRoutesTypes;
  category?: string;
  withParams?: boolean;
}

interface MobileRoutes {
  [key: string]: MobileRoute;
}

export const mobileRoutes: MobileRoutes = {
  MyProgramsPage: { type: mobileRoutesTypes.actionType, category: 'my_programs' },
  OtherProgramsPage: { type: mobileRoutesTypes.actionType, category: 'other_programs' },
  MyOrdersPage: { type: mobileRoutesTypes.actionType, category: 'purchases' },
  HelpPage: { type: mobileRoutesTypes.actionType, category: 'contact_us' },
  WatchProgramPage: { type: mobileRoutesTypes.parentingProgram, withParams: true },
  WatchEpisodePage: { type: mobileRoutesTypes.episodeParenting, withParams: true },
};

export const getMobileRoute = (mobileRoute: MobileRoute, pathname: string) => {
  if (!mobileRoute) {
    return;
  }

  if (mobileRoute.type === mobileRoutesTypes.actionType) {
    return `?action_type=${mobileRoute.category}`;
  }

  const arrayPath = pathname.split('/');
  const id = arrayPath[arrayPath.length - 1];

  if (mobileRoute.type === mobileRoutesTypes.parentingProgram) {
    return `?parenting_program=${id}`;
  }

  if (mobileRoute.type === mobileRoutesTypes.episodeParenting) {
    return `?episode_parenting=${id}`;
  }
};
