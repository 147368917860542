import { createContext, ReactElement, ReactNode, useContext, useEffect } from 'react';
import DeviceDetector from 'device-detector-js';
import { useStateHandlers } from 'hooks';
import { UserDevice, UserDeviceStatus } from 'libs/http/api/users/users.types';
import { devices as devicesApi, users } from 'libs/http/api';
import { useSocket } from 'hooks/useSocket';
import { useRequest } from 'estafette';
import { UserContext } from './UserContext';
import { $Object } from 'libs/globalTypes';

enum SwitchDeviceActions {
  login = 'login',
  clientConnect = 'client_connect',
  clientDisconnect = 'client_disconnect',
  switch = 'switch_device',
}

interface Props extends State {
  loading: boolean;
  setDevices: (devices: UserDevice[]) => void;
  getActiveDevices: () => void;
}

interface State {
  showDeviceSwitch: boolean;
  thisDevice?: UserDevice;
  activeDevice?: UserDevice;
  activeDevices: UserDevice[];
}

export const DevicesContext = createContext<Props>({
  loading: false,
  showDeviceSwitch: false,
  setDevices: () => null,
  getActiveDevices: () => null,
  activeDevices: [],
});

export const DevicesProvider = ({
  children,
}: {
  children: (values: Props) => ReactNode;
}): ReactElement => {
  const { logged, onLogout } = useContext(UserContext);

  const { onSocketConnect, onSocketDisconnect } = useSocket(async (data: $Object) => {
    if (data.type === SwitchDeviceActions.switch) {
      setDeviceDataFormDevicesObject(data.context.devices);
    }

    if (data.type === SwitchDeviceActions.login) {
      setDeviceDataFormDevicesObject(data.context.devices);

      setState({ showDeviceSwitch: true });
    }

    if (data.type === SwitchDeviceActions.clientConnect) {
      getDevices();

      activeDevices.setData((prevState: any) => ({ connections: [...prevState.connections, data.context.device_id] }));

      setState({ showDeviceSwitch: true });
    }

    if (data.type === SwitchDeviceActions.clientDisconnect) {
      await users.activateDevice.action({ device_id: thisDeviceProps.device_id });

      getDevices();

      activeDevices.setData((prevState: any) => ({
        connections: prevState.connections.filter((i: string) => i !== data.context.device_id),
      }));

      setState({
        showDeviceSwitch: activeDevices.data.connections.length >= 2,
      });
    }
  });

  const devices = useRequest<UserDevice[]>({ data: [] });
  const activeDevices = useRequest<{ connections: string[] }>({ data: { connections: [] } });

  const [state, setState] = useStateHandlers<State>({ showDeviceSwitch: false, activeDevices: [] });

  useEffect(() => {
    if (logged) {
      onSocketConnect();

      getDevices();

      getActiveDevices();
    } else {
      setState({ showDeviceSwitch: false });
    }

    return () => {
      onSocketDisconnect();
      users.getDevices.cancel();
    };
  }, [logged]);

  useEffect(() => {
    if (logged) {
      const thisDevice = devices.data.find((i) => i.id === thisDeviceProps.device_id);

      const activeDevice = devices.data.find((i) => i.status === UserDeviceStatus.active);

      if (thisDevice) {
        if (thisDevice.status === UserDeviceStatus.loggedOut) {
          return onLogout();
        }

        if (thisDevice.status === UserDeviceStatus.onHold) {
          document.documentElement.style.overflowY = 'hidden';
        }

        if (thisDevice.status === UserDeviceStatus.active) {
          document.documentElement.style.overflowY = 'auto';
        }
      }

      setState({
        thisDevice: thisDevice,
        activeDevice: activeDevice,
      });
    }
  }, [logged, devices.data]);

  useEffect(() => {
    const refreshActiveDevices = () => {
      const localActiveDevices: UserDevice[] = devices.data.filter((device) =>
        activeDevices.data.connections.includes(device.id),
      );

      setState({ activeDevices: localActiveDevices, showDeviceSwitch: Boolean(localActiveDevices?.length > 1) });
    };

    refreshActiveDevices();
  }, [activeDevices.data, devices.data]);

  const getDevices = () => devices.request(users.getDevices.action());
  const getActiveDevices = () => activeDevices.request(devicesApi.getActiveDevices.action());
  const setDeviceDataFormDevicesObject = (devicesObject: $Object) =>
    devices.setData(Object.keys(devicesObject).map((i) => ({ id: i, ...devicesObject[i] })));

  const values = {
    loading: activeDevices.loading,
    showDeviceSwitch: state.showDeviceSwitch,
    thisDevice: state.thisDevice,
    setDevices: devices.setData,
    activeDevice: state.activeDevice,
    activeDevices: state.activeDevices,
    getActiveDevices: getActiveDevices,
  };

  return <DevicesContext.Provider value={values}>{children(values)}</DevicesContext.Provider>;
};

export const getDeviceName = () => {
  const deviceDetector = new DeviceDetector();
  const device = deviceDetector.parse(navigator.userAgent);

  return device.client ? device.client.name : navigator.appCodeName;
};

export const thisDeviceProps = {
  device_id: [navigator.appCodeName, navigator.platform, navigator.productSub].join('_'),
  device_name: getDeviceName(),
  device_type: 'pc',
};
