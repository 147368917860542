import React from 'react';
import cn from 'classnames';

interface Props {
  className?: string;
}

export const HeadTitle: React.FC<Props> = ({ className, children }) => {
  return <span className={cn('head-title', className)}>{children}</span>;
};
