import React from 'react';
import { useIntl } from 'estafette-intl';
import { Textarea, Form, useForm, Upload, Button, useNotify } from 'ebs-design';
import { useStateHandlers } from 'hooks';
import { DropZone } from 'ui/molecules/DropZone/DropZone';
import { HeadTitle } from 'ui/atoms';
import { users } from 'libs/http/api/users/users';
import { File } from 'libs/http/api/users/users.types';

export const HelpPage: React.FC = () => {
  const { t } = useIntl();
  const notify = useNotify();
  const [form] = useForm();

  const [state, setState] = useStateHandlers<{ imageLinks: string[]; text: string }>({ imageLinks: [], text: '' });

  const uploaderProps = {
    name: 'file',
    action: `${process.env.REACT_APP_MEDIA_URL}/file/upload/`,
    multiple: true,

    onSuccess(response: any) {
      setState({ imageLinks: response.map((i: File) => i.url) });
    },
  };

  const handleSubmit = (): void => {
    users.postContactUs
      .action({ ...form.getFieldsValue(), image_links: state.imageLinks })
      .then(() => {
        notify.primary({ title: t('success'), description: t('requestWasSent') });
        form.resetFields();
      })
      .catch(() => notify.error({ title: t('error'), description: t('requestWasntSent') }));
  };
  const onTextareaChange = (text: string) => setState({ text });

  return (
    <div className="d-flex justify-content--center">
      <div className="help-page">
        <Form className="help-page__form" form={form} onFinish={handleSubmit}>
          <HeadTitle className="help-page__head-title">{t('help')}</HeadTitle>
          <Form.Field
            className="help-page__form-field"
            name="message"
            label={t('SettingsContactUs')}
            rules={[{ required: true, message: t('this_field_required') }]}
          >
            <Textarea value={state.text} onChange={onTextareaChange} placeholder={t('write_your_experience')} />
          </Form.Field>
          <div>
            <Form.Field
              className="help-page__form-field"
              name="image_links"
              label={t('attachDocument')}
            >
              <Upload {...uploaderProps} accept="image/jpeg, image/png">
                <DropZone />
              </Upload>
            </Form.Field>
            <div className="help-page__form-footer">
              <Button className="" type="primary" onClick={() => form.submit()}>
                <p className="">{t('send')}</p>
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};
