import React, { useEffect, useRef, useState } from 'react';
import H5AudioPlayer from 'react-h5-audio-player';
import { Link } from 'react-router-dom';
import { Forward, NextAudio, Pause, Play, PreviousAudio, Rewind } from 'resources';

interface Props {
  src: string;
  autoPlay?: boolean;
  previousLink?: string;
  nextLink?: string;
  onSaveProgress?: (progress: number) => void;
  defaultCurrentTime?: number;
}

export const AudioPlayer: React.FC<Props> = ({
  src,
  autoPlay = true,
  previousLink,
  nextLink,
  onSaveProgress,
  defaultCurrentTime = 0,
}) => {
  const player = useRef<any>(null);
  const [timeIsSet, setTimeIsSet] = useState(true);

  useEffect(() => {
    if (defaultCurrentTime) {
      setTimeIsSet(false);
    }
  }, [defaultCurrentTime, src]);

  const handleSaveProgress = (event: Event) => {
    const audio = event.target as HTMLAudioElement;
    const { currentTime } = audio;
    if (onSaveProgress) {
      onSaveProgress(currentTime);
      setTimeIsSet(true);
    }
  };

  const handleCanPay = (event: Event) => {
    const audio = event.target as HTMLAudioElement;
    if (defaultCurrentTime && !timeIsSet) {
      audio.currentTime = defaultCurrentTime;
    }
  };

  return (
    <H5AudioPlayer
      className="audio-player"
      autoPlay={autoPlay}
      showDownloadProgress
      showSkipControls
      customAdditionalControls={[]}
      onCanPlay={handleCanPay}
      onListen={handleSaveProgress}
      onSeeked={handleSaveProgress}
      onPause={handleSaveProgress}
      defaultCurrentTime={defaultCurrentTime}
      listenInterval={5000}
      progressJumpSteps={{ backward: 10000, forward: 10000 }}
      customIcons={{
        play: <Play className="play" />,
        pause: <Pause className="pause" />,
        rewind: <Rewind />,
        forward: <Forward />,
        next: nextLink ? (
          <Link to={nextLink}>
            <NextAudio />
          </Link>
        ) : (
          <div />
        ),
        previous: previousLink ? (
          <Link to={previousLink}>
            <PreviousAudio />
          </Link>
        ) : (
          <div />
        ),
      }}
      ref={player}
      src={src}
    />
  );
};
