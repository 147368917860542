import { AxiosResponse, Canceler } from 'axios';
import { axios, cancelToken } from '../../axios';
import { RefreshAccess, User, UserOrders, ContactUs, UserDevice } from './users.types';

export const users = {
  signIn: {
    action: (params = {}): Promise<AxiosResponse> =>
      axios.post(`users/login`, params, {
        cancelToken: new cancelToken((c: Canceler) => (users.signIn.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  register: {
    action: (params = {}): Promise<AxiosResponse> =>
      axios.post(`users/register`, params, {
        cancelToken: new cancelToken((c: Canceler) => (users.register.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  tokenRegister: {
    action: (params = {}): Promise<AxiosResponse> =>
      axios.post(`users/token-register`, params, {
        cancelToken: new cancelToken((c: Canceler) => (users.tokenRegister.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  verify: {
    action: (params = {}): Promise<AxiosResponse> =>
      axios.post(`users/verify`, params, {
        cancelToken: new cancelToken((c: Canceler) => (users.verify.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  refresh: {
    action: (params = {}): Promise<AxiosResponse<RefreshAccess>> =>
      axios.post<RefreshAccess>(`users/refresh`, params, {
        cancelToken: new cancelToken((c: Canceler) => (users.refresh.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  get: {
    action: (): Promise<AxiosResponse<User>> =>
      axios.get<User>(`/users/profile`, {
        cancelToken: new cancelToken((c: Canceler) => (users.get.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  notifications: {
    action: (operation: string, category: string, token: string): Promise<AxiosResponse<User>> =>
      axios.post<User>(`/users/notifications/${operation}/${category}/${token}/`, {
        cancelToken: new cancelToken((c: Canceler) => (users.notifications.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },

  update: {
    action: (data = {}): Promise<AxiosResponse> =>
      axios.patch(`/users/profile`, data, {
        cancelToken: new cancelToken((c: Canceler) => (users.update.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getOrderUser: {
    action: (): Promise<AxiosResponse> =>
      axios.get<UserOrders>(`/sales/user-orders/`, {
        cancelToken: new cancelToken((c: Canceler) => (users.getOrderUser.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getOrderUserById: {
    action: (id: number): Promise<AxiosResponse<UserOrders>> =>
      axios.get<UserOrders>(`/sales/user-orders/${id}`, {
        cancelToken: new cancelToken((c: Canceler) => (users.getOrderUserById.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  postContactUs: {
    action: (params = {}): Promise<AxiosResponse<ContactUs>> =>
      axios.post<ContactUs>(`/content/contact-us`, params, {
        cancelToken: new cancelToken((c: Canceler) => (users.postContactUs.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getDevices: {
    action: (params = {}): Promise<AxiosResponse<UserDevice[]>> =>
      axios.get(`/users/devices `, {
        cancelToken: new cancelToken((c: Canceler) => (users.getDevices.cancel = c)),
        params: params,
      }),
    cancel: (() => null) as Canceler,
  },
  activateDevice: {
    action: (params = {}): Promise<AxiosResponse<UserDevice[]>> =>
      axios.post(`/users/devices/activate`, params, {
        cancelToken: new cancelToken((c: Canceler) => (users.activateDevice.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  requestPasswordRefresh: {
    action: (params = {}): Promise<AxiosResponse<any>> =>
      axios.post(`/users/restore-password`, params, {
        cancelToken: new cancelToken((c: Canceler) => (users.requestPasswordRefresh.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  confirmRestorePassword: {
    action: (params = {}): Promise<AxiosResponse<any>> =>
      axios.post(`/users/confirm-restore-password`, params, {
        cancelToken: new cancelToken((c: Canceler) => (users.confirmRestorePassword.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  confirmAdminInvite: {
    action: (params = {}): Promise<AxiosResponse<any>> =>
      axios.post(`/users/confirm-admin-invite`, params, {
        cancelToken: new cancelToken((c: Canceler) => (users.confirmAdminInvite.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getQR: {
    action: (auto_setup_token: string, params = {}): Promise<any> =>
      axios.get(`/users/autologin-qr-code?token=${auto_setup_token}`, {
        cancelToken: new cancelToken((c: Canceler) => (users.getDevices.cancel = c)),
        params: params,
      }),
    cancel: (() => null) as Canceler,
  },
};
