import { AxiosResponse, Canceler } from 'axios';
import { axios, cancelToken } from '../../axios';

export const firebase = {
  generateDynamicLink: {
    action: (data = {}): Promise<AxiosResponse<any>> =>
      axios.post(`/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_WEB_KEY}`, data, {
        baseURL: 'https://firebasedynamiclinks.googleapis.com',
        cancelToken: new cancelToken((c: Canceler) => (firebase.generateDynamicLink.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
};
