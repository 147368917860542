import React, { useMemo } from 'react';
import { useIntl } from 'estafette-intl';
import { Link } from 'react-router-dom';
import { Space } from 'ebs-design';
import { ProgressBar } from 'ui/atoms';
import { Attending } from '../../molecules/Attending/Attending';
import { getRoute, getSubscribers } from 'libs/utils';
import { ProgramProps } from './types';

export const Program: React.FC<ProgramProps> = ({ program }) => {
  const { t } = useIntl();

  const programProgress = useMemo(() => (program.progress / program.episodes_duration) * 100 || 0, [program]);

  const leftMinutes = useMemo(
    () =>
      Math.ceil(((program.current_episode?.duration || 0) - (program.current_episode?.progress || 0)) / 60) ||
      Math.ceil(((program.current_episode?.next?.duration || 0) - (program.current_episode?.next?.progress || 0)) / 60),
    [program],
  );

  return (
    <>
      <div className="program-card-wide__header">
        <div className="program-card-wide__header-title">
          <div className="program-card-wide__name">{program.title}</div>
        </div>
        <div className="program-card-wide__description">{program.description}</div>
      </div>
      <>
        <div className="program-card__body__progress-bar mt-0">
          <ProgressBar progress={programProgress} />
        </div>
        {((leftMinutes > 0 && program.current_episode) || program.current_episode?.next) && (
          <div className="program-card__body__chapter">
            <span className="bold">{t('nextEpisode')}: </span>
            {leftMinutes > 0 ? program.current_episode?.title : program.current_episode?.next?.title}
          </div>
        )}
        <div className="program-card__body__chapter-description">
          {leftMinutes} {t('minLeftInThisEpisode')}
        </div>
      </>
      <div className="program-card-wide__footer">
        <Space
          justify={
            !program.is_new && Boolean(program.last_subscriptions.length || program.subscriptions_count)
              ? 'space-between'
              : 'end'
          }
        >
          <div>
            {!program.is_new && Boolean(program.last_subscriptions.length || program.subscriptions_count) && (
              <div className="program-card-wide__footer__attending">
                <Attending users={program.last_subscriptions.map((i) => ({ avatar: i.photo }))} />{' '}
                {getSubscribers(program.subscriptions_count)} {t('attending')}
              </div>
            )}
          </div>
          <div className="program-card__body__action-button">
            <Link to={getRoute('WatchProgramPage', { programId: program.id })}>
              <span>
                {program.program_subscription &&
                (program.access_status === 'subscribed' || program.program_subscription.payment_failed)
                  ? program.progress < program.episodes_duration && program.progress
                    ? t('continueWatch')
                    : program.progress === 0
                    ? t('Start')
                    : t('startAgain')
                  : t('unlock_now')}
              </span>
            </Link>
          </div>
        </Space>
      </div>
    </>
  );
};
