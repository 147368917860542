import { AxiosResponse, Canceler } from 'axios';
import { axios, cancelToken } from '../../axios';
import { Content } from './content.types';

export const content = {
  getContent: {
    action: (page_type: string, language: string): Promise<AxiosResponse<Content>> =>
      axios.get(`/content/page/retrieve/${page_type}?language=${language}`, {
        cancelToken: new cancelToken((c: Canceler) => (content.getContent.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
};
