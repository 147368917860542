import React, { useEffect, useMemo } from 'react';
import { Avatar, Loader } from 'ebs-design';
import { useParams } from 'react-router-dom';
import { useRequest } from 'estafette';
import InfiniteScroll from 'react-infinite-scroller';
import { education } from 'libs/http/api/education/education';
import { pageSize } from 'libs/globalVars';
import { Rating, HeadTitle } from 'ui/atoms';
import { useIntl, useStateHandlers, useTimeUtils } from 'hooks';

interface Props {
  refresh: boolean;
  afterRefreshCallback: () => void;
}

export const Reviews = ({ refresh, afterRefreshCallback }: Props) => {
  const { t } = useIntl();
  const { getDiffFromToday } = useTimeUtils();
  const { programId } = useParams<{ programId: string }>();

  const reviews = useRequest<any>({ data: { results: [] } });

  const [state, setState] = useStateHandlers({ page: 1 });

  useEffect(() => {
    if (programId) {
      getReviews();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId, state.page]);

  useEffect(() => {
    if (refresh) {
      getReviews();
      afterRefreshCallback();
    }
  }, [refresh]);

  const isHasMore = useMemo(
    () => !reviews.loading && Math.ceil(reviews.data.count / pageSize) > state.page,
    [reviews.loading, reviews.data, state.page],
  );

  const getReviews = () =>
    reviews.request(
      education.getProgramReviews.action(Number(programId), { ...state, page_size: pageSize }),
      state.page > 1 ? { concat: 'results' } : {},
    );

  const onPageChange = () => {
    if (isHasMore) {
      setState((prevState) => ({ page: ++prevState.page }));
    }
  };

  if (!reviews.data.results?.length) {
    return null;
  }

  return (
    <div className="reviews">
      <HeadTitle className="reviews__title">{t('allReviews')}</HeadTitle>
      <InfiniteScroll
        initialLoad={false}
        hasMore={isHasMore}
        pageStart={state.page}
        loadMore={onPageChange}
        className="m-infinite-scroll"
        threshold={500}
      >
        {reviews.data.results.map((i: any) => (
          <div className="reviews__item" key={i.id}>
            <div className="reviews__item__avatar">
              <Avatar circle img={i.author.photo} alt={i.author.full_name} />
              <div>
                <div className="reviews__item__user-name">{i.author.full_name}</div>
                <Rating value={i.rating} />
              </div>
            </div>
            <div className="reviews__item__description">{i.text}</div>
            <div className="reviews__item__created-at">{getDiffFromToday(i.created_at)}</div>
          </div>
        ))}

        <Loader loading={reviews.loading} />
      </InfiniteScroll>
    </div>
  );
};
