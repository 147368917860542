import { load, remove, save } from 'react-cookies';
import { axiosHeadersUpdater } from './http/axios';

export enum StorageKeys {
  parentsWeb = 'parents-web',
  tryRedirect = 'try-redirect',
  jwtToken = 'jwt-token',
  jwtRefreshToken = 'jwt-refresh-token',
  localization = 'localization',
}

export const clearCredentials = () => {
  remove(StorageKeys.jwtToken, { path: '/' });
  remove(StorageKeys.jwtRefreshToken, { path: '/' });
};

export const clearCookies = (): void => {
  clearCredentials();

  clearOtherCookies();

  axiosHeadersUpdater();
};

export const clearOtherCookies = () => {
  remove(StorageKeys.localization, { path: '/' });
};

export const setTokens = (params?: {
  token?: string;
  refresh?: string;
  language?: string;
  guest?: boolean;
  payedOffer?: number;
}): void => {
  if (params) {
    if (params.token) {
      save(StorageKeys.jwtToken, params.token, { path: '/', sameSite: 'lax' });
    }

    if (params.refresh) {
      save(StorageKeys.jwtRefreshToken, params.refresh, { path: '/', sameSite: 'lax' });
    }

    if (params.language) {
      save('localization', params.language, { path: '/', sameSite: 'lax' });
    }

    axiosHeadersUpdater();
  }
};

export const ifAuthTokenExist = () => Boolean(load(StorageKeys.jwtToken));
