import { FC, useContext } from 'react';
import { Button, Form, Input, Loader, useForm, useNotify, Label, Space } from 'ebs-design';
import { useIntl } from 'estafette-intl';
import { useHistory } from 'react-router-dom';
import { useStateHandlers } from 'hooks';
import { getRoute, getRouteWithoutParams, setFieldsError } from 'libs/utils';
import { users } from 'libs/http/api';
import { ContactsProps } from 'libs/http/api/content/content.types';
import { UserContext } from 'contexts/UserContext';
import { useClearCredentials } from 'hooks';
import { HeadTitle } from 'ui/atoms';
import { LightBulb } from 'resources';

export const ResetPasswordPage: FC = () => {
  const { logged } = useContext(UserContext);
  const { t } = useIntl();
  const { push } = useHistory();
  const notify = useNotify();
  const [form] = useForm();

  const [state, setState] = useStateHandlers<{ loading: boolean; errors: string; contacts?: ContactsProps }>({
    loading: false,
    errors: '',
  });

  useClearCredentials();

  const onFinish = (values: any) => {
    setState({ loading: true });

    users.requestPasswordRefresh
      .action({ ...values, redirect_url: `${window.location.origin}${getRouteWithoutParams('ConfirmPasswordPage')}` })
      .then(() => {
        notify.success({ title: '', description: t('email_sent') });
        push(getRoute(logged ? 'MyProgramsPage' : 'OtherProgramsPage'));
      })
      .catch(({ response }: any) => {
        if (response.data && !response.data.detail) {
          setFieldsError(response.data, form);
        }
        if (response.data.detail) {
          notify.error({ title: 'Error', description: response.data.detail });
        }
        if (response.data.non_field_errors) {
          setState({ errors: response.data.non_field_errors[0] });
          notify.error({ title: 'Error', description: response.data.non_field_errors[0] });
        }
      })
      .finally(() => setState({ loading: false }));
  };

  return (
    <div className="sign-in-page">
      <HeadTitle className="sign-in-page__head-title">{t('restore_password')}</HeadTitle>
      <Space justify="space-between" align="start" size={12} className="sign-in-page__head-notification mb-24">
        <LightBulb className="sign-in-page__head-notification-icon" />
        <span className="sign-in-page__head-notification-text">{t('info_reset_pass')}</span>
      </Space>
      <Loader loading={state.loading}>
        <Form form={form} onFinish={onFinish}>
          <div className="d-flex justify-content--center">
            <Label status="danger" type="ghost" text={state.errors} />
          </div>
          <Form.Field
            label={t('enterEmail')}
            name="email"
            className="sign-in-page__form-field"
            rules={[
              {
                type: 'email',
                message: t('invalid_email'),
              },
              {
                required: true,
                message: t('this_email_not_exists'),
              },
            ]}
          >
            <Input placeholder={t('email_notif')} size="large" />
          </Form.Field>

          <div className="sign-in-page__form-footer">
            <Button type="primary" className="m-submit" submit>
              {t('Continue')}
            </Button>
          </div>
        </Form>
      </Loader>
    </div>
  );
};
