import { getMessaging, getToken, onMessage, FirebaseMessaging } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { useRef } from 'react';

export const useFirebaseMessaging = () => {
  const firebaseMessaging = useRef({} as FirebaseMessaging);

  const initializeFirebaseApp = () => {
    const firebaseApp = initializeApp(
      {
        apiKey: 'AIzaSyDm1fG_zcqilY2ZRUz19ZfJdIgbHHPeXrM',
        authDomain: 'tribes-239408.firebaseapp.com',
        databaseURL: 'https://tribes-239408.firebaseio.com',
        projectId: 'tribes-239408',
        storageBucket: 'tribes-239408.appspot.com',
        messagingSenderId: '214075808354',
        appId: '1:214075808354:web:316d40bc373a5094b48f3b',
        measurementId: 'G-5WCJYE0BXP',
      },
      'parents-web',
    );

    firebaseMessaging.current = getMessaging(firebaseApp);

    getToken(firebaseMessaging.current, {
      vapidKey: 'BIB_InPYivdIHZ6m7PdpVC_a1q_hRP9DGXEqwKVC21bVdRtiqJ-cFuYpucPgj_PX5gYlgii7t7MZBBQRn0YoCH4',
    })
      .then((currentToken: any) => {
        if (currentToken) {

        } else {
          console.log('No registration token available --- my');
        }
      })
      .catch(err => {
        // console.log('get token err ', err);
        // ...
      });

    onMessage(firebaseMessaging.current, payload => {
      console.log('Message received. ', payload);
      // ...
    });
  };

  const initializeFirebaseMessaging = () => {

  };

  return { initializeFirebaseApp, initializeFirebaseMessaging };
};
