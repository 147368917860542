import React, { useContext, useMemo } from 'react';
import cn from 'classnames';
import BasicCarousel from 'react-multi-carousel';
import { breakpoints } from 'libs/globalVars';
import { ResponsiveContext } from 'contexts/ResponsiveContext';
import { useNrOfSlidesByScreenWidth } from '../../../hooks';

interface Props {
  items: any[];
  buttonGroup: any;
  className?: string;
  containerClass?: string;
  itemClassName?: string;
}

export const Carousel: React.FC<Props> = ({ items, buttonGroup, className, containerClass, itemClassName }) => {
  const { isSm } = useContext(ResponsiveContext);
  const { nrOfSlides } = useNrOfSlidesByScreenWidth();

  const showCarouselOptions = useMemo(() => items.length > nrOfSlides, [items, nrOfSlides]);

  const partialVisibilityGutter = useMemo(() => {
    if (showCarouselOptions) {
      if (isSm) {
        return 40;
      }

      return 13;
    }

    return 0;
  }, [showCarouselOptions, isSm]);

  const responsive = {
    desktop: {
      breakpoint: { max: 4196, min: breakpoints.desktop },
      items: 3,
      slidesToSlide: 3,
      partialVisibilityGutter,
    },
    tablet: {
      breakpoint: { max: breakpoints.desktop, min: 0 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter,
    },
    mobile: {
      breakpoint: { max: breakpoints.sm, min: 0 },
      items: 1,
      partialVisibilityGutter,
    },
  };

  return (
    <div className={cn('carousel', className)}>
      {items.length > 0 && (
        <BasicCarousel
          responsive={responsive}
          keyBoardControl={true}
          containerClass={cn('carousel-container', showCarouselOptions ? 'show-carousel-options' : '', containerClass)}
          itemClass={cn('carousel-item', itemClassName)}
          swipeable
          arrows={false}
          partialVisible
          customButtonGroup={showCarouselOptions ? buttonGroup : null}
          renderButtonGroupOutside={true}
          additionalTransfrom={-10}
        >
          {items}
        </BasicCarousel>
      )}
    </div>
  );
};
