import { useMemo } from 'react';
import dayjs from 'dayjs';
import { Avatar, Col, Row } from 'ebs-design';
import { useIntl } from 'hooks/useIntl';
import { useTimeUtils } from 'hooks';
import { Notification as NotificationType } from 'libs/http/api/notifications/notifications.types';
import { dateFormat, tomorrow } from 'libs/date';

interface Props {
  notification: NotificationType;
  onRead: (id: number) => void;
}

let currentDate = tomorrow;

export const Notification = ({ notification, onRead }: Props) => {
  const { getDiffFromToday } = useTimeUtils();
  const { t } = useIntl();

  const message = useMemo(() => {
    if (notification.message) {
      const splitMessage = notification.message.split(' , ');

      return (
        <>
          <b className="m-bold">{splitMessage[0]}</b>, <span dangerouslySetInnerHTML={{ __html: splitMessage[1] }} />
        </>
      );
    }

    return `${t(`notification_${notification.category}`)}`;
  }, [notification]);

  const showDate = useMemo(() => {
    const notificationDate = dayjs(notification.created_at);
    if (notificationDate.format(dateFormat) !== currentDate.format(dateFormat)) {
      currentDate = notificationDate;

      return (
        <Col className="m-notification-category">
          {notificationDate.format('DD')} {t(notificationDate.format('MMMM'))}, {t(notificationDate.format('dddd'))}
        </Col>
      );
    }
  }, []);

  const onNotificationRead = () => {
    if (!notification.read) {
      onRead(notification.id);
    }
  };

  return (
    <>
      {message && (
        <>
          {showDate}

          <div onClick={onNotificationRead}>
            <Row className={`m-notification ${notification?.read ? '' : 'not-read'}`}>
              <Col size="auto">
                <Avatar circle img={notification?.author?.photo} size="big" />
              </Col>
              <Col className="p-0">
                <Row>
                  <Col size={12} className="p-0">
                    {message}
                  </Col>
                  <Col size={12} className="p-0">
                    {getDiffFromToday(notification?.created_at)}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};
