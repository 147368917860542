import { FC } from 'react';
import { useIntl } from 'estafette-intl';
import { SetNewPassForm } from '../components/molecules/SetNewPassForm';
import { users } from 'libs/http/api';
import { HeadTitle } from 'ui/atoms';
import { useClearCredentials } from 'hooks';

export const ConfirmPasswordPage: FC = () => {
  const { t } = useIntl();

  useClearCredentials();

  return (
    <div className="sign-in-page">
      <HeadTitle className="sign-in-page__head-title">{t('reset_pass')}</HeadTitle>
      <SetNewPassForm apiRequest={users.confirmRestorePassword.action} />
    </div>
  );
};
