import { useStateHandlers } from 'hooks';
import { createContext, ReactElement, useMemo } from 'react';

interface Props {
  fullWindowClass: string;
  setFullWindow: (newState: boolean | ((prevState: boolean) => boolean)) => void;
}

interface State {
  isFullWindow: boolean;
}

export const StylesContext = createContext<Props>({
  fullWindowClass: '',
  setFullWindow: () => null,
});

export const StylesProvider = ({ children }: { children: ReactElement }): ReactElement => {
  const [state, setState] = useStateHandlers<State>({ isFullWindow: false });

  const fullWindowClass = useMemo(() => (state.isFullWindow ? 'is-full-window' : ''), [state]);

  const setFullWindow = (newState: boolean | ((prevState: boolean) => boolean)): void => {
    if (typeof newState === 'function') {
      setState((prevState) => ({ isFullWindow: newState(prevState.isFullWindow) }));
    } else {
      setState({ isFullWindow: newState });
    }
  };

  const values = {
    fullWindowClass: fullWindowClass,
    setFullWindow: setFullWindow,
  };

  return <StylesContext.Provider value={values}>{children}</StylesContext.Provider>;
};
