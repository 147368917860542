import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl, useQuery } from 'hooks';
import { Icon } from 'ui/atoms/Icon/Icon';
import { getRoute } from 'libs/utils';
import NotFound from 'assets/images/svg/404.svg';

export const WebinarQRCode = () => {
  const { t } = useIntl();
  const { push } = useHistory();
  const { auto_setup_token } = useQuery<{ auto_setup_token?: string }>();
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    if (!auto_setup_token?.length) {
      return push(getRoute('NotFoundPage', {}));
    }
  }, []);

  const src = useMemo(
    () =>
      imageError
        ? NotFound
        : `${process.env.REACT_APP_API_URL?.replace(/\/$/, '')}/users/autologin-qr-code?token=${auto_setup_token}`,
    [auto_setup_token, imageError],
  );

  return (
    <div className="main-layout bg-white">
      <div className="m-children-container">
        {/*todo delete and refactor this class name*/}
        <section className="qr-code justify-content--center align-items--center">
          <div className="qr-code__wrapper">
            <Icon type="logo-blue" className="qr-code__logo" />
            <span className="qr-code__title">
              {!imageError ? t('youAreApparentlyUsingAComputer') : t('something_wrong')}
            </span>
            {auto_setup_token && (
              <>
                {!imageError && (
                  <span className="qr-code__subtitle">
                    {t('pleaseTurnOnThePhoneCamera')} {t('ifYourPhoneHasAnOlderAndroidVersion')}
                  </span>
                )}
                <img src={src} onError={() => setImageError(true)} alt="Parrents" />
              </>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};
