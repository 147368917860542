import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbsContext } from 'contexts/BreadcrumbsContext';
import { getRoute } from 'libs/utils';

export const Breadcrumbs = () => {
  const { breadcrumbs } = useContext(BreadcrumbsContext);
  return (
    <div className="breadcrumbs">
      {breadcrumbs.map((i, key) => (
        <div key={i.label + key} className="breadcrumbs__item">
          {key !== 0 && <span className="breadcrumbs__separator">/</span>}

          {i.route ? (
            <Link to={getRoute(i.route, i.params)} className="breadcrumbs__item__link">
              <span>{i.label}</span>
            </Link>
          ) : (
            <span className="breadcrumbs__item__label">{i.label}</span>
          )}
        </div>
      ))}
    </div>
  );
};
