import React /*, { useCallback, useContext, useEffect, useMemo, useState }*/ from 'react';
import { Redirect /*, useLocation*/ } from 'react-router-dom';
// import cn from 'classnames';
// import { useRequest } from 'estafette';
// import { useIntl } from 'estafette-intl';
// import { Row, Col, Loader, omitKeys, Space } from 'ebs-design';
// import { parseQuery } from 'libs/object';
// import { qa as api } from 'libs/http/api';
// import { QAResults } from 'libs/http/api/qa/qa.types';
// import { setHTMLClassName } from 'libs/utils';
// import { QAContext } from 'contexts/QAContext';
// import { useDebounce, useStateHandlers } from 'hooks';
// import { Container, Animated, HeadTitle } from 'ui/atoms';
//
// import { ReactComponent as Empty } from './empty.svg';
//
// import { isInViewport, isBeforeViewport } from '../../utils';
// import { Filters, Search, Item, Banner } from '../../components';
//
// enum Navigate {
//   PREV = 'prev',
//   NEXT = 'next',
// }

export enum Store {
  STATE = 'qa',
  ID = 'qa-id',
  RECENT = 'qa-recent',
  USER = 'qa-user',
}

//
// interface Props {
//   query: string;
//   page: number;
//   hitsPerPage: number;
//   facetFilters: string[][];
// }

export const QA: React.FC = () => {
  return <Redirect to="/" />;
  // const { t, locale, setLocale } = useIntl();
  // const { data, notAnimated, userToken, setData, setNotAnimated, setQueryId, setPosition } = useContext(QAContext);
  // const location = useLocation();
  // const { query } = parseQuery<{ query?: string }>(location.search);
  //
  // const lastOpenedItem = localStorage.getItem(Store.ID);
  //
  // const cacheState = localStorage.getItem(Store.STATE)
  //   ? JSON.parse(localStorage.getItem(Store.STATE) as string)
  //   : undefined;
  //
  // const [recentSearches, setRecentSearches] = useState<string[]>(
  //   localStorage.getItem(Store.RECENT) ? JSON.parse(localStorage.getItem(Store.RECENT) as string) : [],
  // );
  //
  // const [state, setState] = useStateHandlers<Props>(
  //   cacheState
  //     ? { ...cacheState, query: query || cacheState.query, page: lastOpenedItem ? cacheState.page : 0 }
  //     : {
  //         query: query || '',
  //         page: 0,
  //         hitsPerPage: 5,
  //         facetFilters: [[], []],
  //       },
  // );
  //
  // const [scrollY, setScrollY] = useState(0);
  // const [isMobileFilters, setIsMobileFilters] = useState(false);
  // const [animationEnd, setAnimationEnd] = useState(false);
  // const [minHeight, setMinHeight] = useState(150);
  //
  // const [isEnd, setIsEnd] = useState(false);
  // const [isLoaded, setIsLoaded] = useState(!!data.length);
  // const [isScrolled, setIsScrolled] = useState(false);
  //
  // const [nextPage, setNextPage] = useState(lastOpenedItem ? cacheState?.page || 0 : 0);
  // const [navigate, setNavigate] = useState<Navigate>();
  //
  // const { request, data: response, loading, setLoading } = useRequest<QAResults>({ data: {} });
  //
  // const isNext = useMemo(
  //   () =>
  //     isInViewport(
  //       document.getElementById(`qa-${[...([...data.filter((i) => i)]?.pop()?.hits || [])].pop()?.objectID}`),
  //     ),
  //   [scrollY, data, animationEnd],
  // );
  // const isAfter = useMemo(
  //   () =>
  //     isBeforeViewport(
  //       document.getElementById(`qa-${[...([...data.filter((i) => i)]?.pop()?.hits || [])].pop()?.objectID}`),
  //     ),
  //   [scrollY, data, animationEnd],
  // );
  //
  // useEffect(() => {
  //   setHTMLClassName('qa-list-page');
  //
  //   return () => {
  //     setHTMLClassName('');
  //   };
  // }, []);
  //
  // useEffect(() => {
  //   if (locale !== 'ro') {
  //     setLocale('ro');
  //     localStorage.setItem('locale', 'ro');
  //   }
  // }, [locale]);
  //
  // useEffect(() => {
  //   if (Object.keys(response).length) {
  //     if (!data.length) {
  //       setIsLoaded(true);
  //     }
  //
  //     if (response.hits.length) {
  //       setData((prevData) =>
  //         prevData.some((_, i) => i === state.page)
  //           ? prevData.map((val, i) => (i === state.page ? response : val))
  //           : navigate === Navigate.NEXT
  //           ? [...prevData, response]
  //           : [response],
  //       );
  //       setNavigate(undefined);
  //
  //       if (state.query.length) {
  //         const updateRecentSearch = recentSearches.some((i) => state.query.includes(i))
  //           ? recentSearches.map((i) => (state.query.includes(i) ? state.query : i))
  //           : [state.query, ...recentSearches];
  //
  //         setRecentSearches(updateRecentSearch.slice(0, 5));
  //         localStorage.setItem(Store.RECENT, JSON.stringify(updateRecentSearch.slice(0, 5)));
  //       }
  //     } else {
  //       setIsEnd(true);
  //     }
  //
  //     setQueryId(response.queryID);
  //   }
  // }, [response]);
  //
  // useEffect(() => {
  //   localStorage.setItem(Store.STATE, JSON.stringify({ ...state, page: nextPage }));
  // }, [state, nextPage]);
  //
  // useEffect(() => {
  //   document.getElementsByTagName('html')[0].style.overflow = isMobileFilters ? 'hidden' : '';
  // }, [isMobileFilters]);
  //
  // const onChangePage = (val: Navigate) => {
  //   setNavigate(val);
  //   setState({ page: nextPage + 1 });
  //   setLoading(true);
  //
  //   setNextPage(nextPage + 1);
  // };
  //
  // const [, cancelNextPage] = useDebounce(
  //   () => {
  //     if (!loading && isLoaded) {
  //       if (!isEnd && isNext) {
  //         onChangePage(Navigate.NEXT);
  //       }
  //     }
  //   },
  //   10,
  //   [scrollY],
  // );
  //
  // useEffect(() => {
  //   const onScroll = () => {
  //     cancelNextPage();
  //     setScrollY(window.scrollY);
  //   };
  //
  //   window.addEventListener('scroll', onScroll);
  //
  //   return () => {
  //     window.removeEventListener('scroll', onScroll);
  //   };
  // }, []);
  //
  // const getData = useCallback(() => {
  //   if (state.query.length) {
  //     request(api.searchQA.action(state.query, { ...omitKeys(['query'], state), userToken }));
  //   }
  // }, [state]);
  //
  // const [, cancel] = useDebounce(
  //   () => {
  //     getData();
  //   },
  //   350,
  //   [state],
  // );
  //
  // const onResetRecent = () => {
  //   setRecentSearches([]);
  //   localStorage.removeItem(Store.RECENT);
  // };
  // const onOpen = (id: string, pageIndex: number, itemIndex: number) => {
  //   const page = nextPage - (data.length - pageIndex);
  //
  //   localStorage.setItem(Store.ID, id);
  //   localStorage.setItem(Store.STATE, JSON.stringify({ ...state, page: page >= 0 ? page : 0 }));
  //
  //   setNotAnimated(data.length - 1);
  //   setPosition(itemIndex + 1);
  // };
  //
  // const onToggleFilters = () => setIsMobileFilters((i) => !i);
  //
  // const onChange = (val: Record<string, any>) => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  //
  //   cancel();
  //   setAnimationEnd(false);
  //   setIsLoaded(false);
  //   setIsEnd(false);
  //   setNavigate(undefined);
  //   setData([]);
  //   setState({ ...val, page: 0 });
  //   setNextPage(0);
  // };
  //
  // const onChangeFilters = (facetFilters: string[][]) => {
  //   setIsLoaded(false);
  //   onChange({ facetFilters });
  // };
  //
  // const onChangeSearch = (val: string) => {
  //   setIsLoaded(false);
  //   onChange({ query: val, facetFilters: [[], []] });
  // };
  //
  // const onAnimationEnd = (id: string) => {
  //   const prevItem = localStorage.getItem(Store.ID);
  //
  //   if (prevItem && id === prevItem && !isScrolled) {
  //     const prevEl = document.getElementById(`qa-${prevItem}`);
  //
  //     if (prevEl) {
  //       window.scrollTo({ top: prevEl?.offsetTop - 100, behavior: 'smooth' });
  //
  //       prevEl.className += ' prev-item';
  //
  //       setIsScrolled(true);
  //       localStorage.removeItem(Store.ID);
  //     }
  //   } else if (!isScrolled) {
  //     setTimeout(() => onAnimationEnd(id), 500);
  //   }
  //
  //   setTimeout(() => {
  //     setAnimationEnd(true);
  //   }, 50);
  // };
  //
  // return (
  //   <>
  //     <Container>
  //       <Row className="qa-list">
  //         <Filters
  //           visible={isMobileFilters}
  //           state={state.facetFilters}
  //           onChange={onChangeFilters}
  //           onToggleFilters={onToggleFilters}
  //         />
  //         <Col>
  //           <Row
  //             className={cn('qa-list__bar', {
  //               'is-search': !!state.query.length,
  //               'is-prev': !animationEnd || [isNext].includes(null) || !window.scrollY,
  //               'is-sticky': animationEnd && !isNext && !isAfter && ![isNext].includes(null) && window.scrollY,
  //             })}
  //           >
  //             <Col size={12} className="qa-list__bar-info">
  //               <Row g={0}>
  //                 <Col size={12}>
  //                   <HeadTitle>{t('qa_find_your_answer')}</HeadTitle>
  //                 </Col>
  //                 <Col size={12} className="mb-24 dark-blue">
  //                   {t('qa_relevant_questions')}
  //                 </Col>
  //               </Row>
  //             </Col>
  //             <Col size={12}>
  //               <Search
  //                 autoShowPopup
  //                 value={state.query}
  //                 recentSearches={recentSearches}
  //                 onChange={onChangeSearch}
  //                 onResetRecent={onResetRecent}
  //                 onToggleFilters={onToggleFilters}
  //                 onHeightChange={setMinHeight}
  //               />
  //             </Col>
  //           </Row>
  //           <Row g={4} style={{ minHeight }}>
  //             {!!state.query.length && (
  //               <Col size={12}>
  //                 <HeadTitle className="my-0">
  //                   {t('qa_search_results')} “{state.query}”
  //                 </HeadTitle>
  //               </Col>
  //             )}
  //             {!isLoaded && loading ? (
  //               <Col size={12}>
  //                 <Loader loading />
  //               </Col>
  //             ) : (
  //               <>
  //                 {data.length
  //                   ? data.map((page, pageIndex) =>
  //                       page?.hits?.map((hit, i) => (
  //                         <Col id={`qa-${hit.objectID}`} key={i} size={12}>
  //                           <Animated
  //                             loading={false}
  //                             height="auto"
  //                             delay={notAnimated > -1 && notAnimated >= pageIndex ? 0 : i * 300}
  //                             duration={notAnimated > -1 && notAnimated >= pageIndex ? 0 : undefined}
  //                             easing="ease-in-out"
  //                             animateOpacity
  //                             onAnimationEnd={() => onAnimationEnd(hit.objectID)}
  //                           >
  //                             <Item {...hit} onOpen={() => onOpen(hit.objectID, pageIndex, (pageIndex + 1) * i)} />
  //                           </Animated>
  //                         </Col>
  //                       )),
  //                     )
  //                   : isLoaded && (
  //                       <Space direction="vertical" className="qa-list__empty">
  //                         <Empty />
  //                         {t('qa_empty')}
  //                       </Space>
  //                     )}
  //                 {isNext && loading && isLoaded && (
  //                   <Col size={12}>
  //                     <Animated loading={false} animateOpacity>
  //                       <Loader.Inline />
  //                     </Animated>
  //                   </Col>
  //                 )}
  //               </>
  //             )}
  //           </Row>
  //         </Col>
  //       </Row>
  //     </Container>
  //
  //     <Banner />
  //   </>
  // );
};
