import cn from 'classnames';
import { Space, Tooltip } from 'ebs-design';
import { ChapterReadOnly, ProgramReadOnly } from 'libs/http/api/education/education.types';
import { useStateHandlers } from 'hooks';
import { ArrowDown, ArrowUp } from 'resources';

import { Episodes } from '../Episodes/Episodes';
import { useMemo } from 'react';

interface Props {
  chapter: ChapterReadOnly;
  program: ProgramReadOnly;
  activeChapter?: number;
}

export const Chapter: React.FC<Props> = ({ chapter, program, activeChapter }) => {
  const [state, setState] = useStateHandlers({ open: chapter.id === activeChapter });

  const onOpenChange = () => setState((prevState) => ({ open: !prevState.open }));

  const progress = useMemo(() => (chapter.progress / chapter.episodes_duration) * 100, [chapter]);

  return (
    <>
      <div onClick={onOpenChange}>
        <div className={cn('chapter', { open: state.open })}>
          <div>
            <div>
              <div>
                <div className="chapter__extra-title">
                  {chapter.extra_title}
                  <div
                    className="chapter__progress"
                    aria-valuenow={progress}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ '--value': progress } as any}
                  />
                  <div className="chapter__progress-text">{progress.toFixed(0)}% complete</div>
                </div>
                <div className="chapter__title">{chapter.title}</div>
              </div>
              <div className="chapter__control-button">{state.open ? <ArrowUp /> : <ArrowDown />}</div>
            </div>
            <Space className="chapter__body" justify="space-between" align="start" size={20}>
              <img className="chapter__avatar" src={chapter.cover_photo} alt={chapter.title} />
              <div className="chapter__description">
                <Tooltip tooltip={chapter.description} trigger="right-click" placement="top">
                  {chapter.description}
                </Tooltip>
              </div>
            </Space>
          </div>
        </div>
      </div>
      {state.open && (
        <div className="chapter__extended-episodes">
          <Episodes chapter={chapter.id} program={program} />
        </div>
      )}
    </>
  );
};
