import { useContext } from 'react';
import { useIntl } from 'estafette-intl';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { Avatar, Textarea, useNotify } from 'ebs-design';
import { UserContext } from 'contexts/UserContext';
import { useStateHandlers } from 'hooks';
import { education } from 'libs/http/api/education/education';

interface Props {
  onSubmit?: () => void;
  replyTo?: number;
  callback?: (comment: any) => void;
  increaseCommentCount: () => void;
}

export const WriteComment = ({ onSubmit, replyTo, callback, increaseCommentCount }: Props) => {
  const { t } = useIntl();
  const { userData } = useContext(UserContext);
  const { episodeId } = useParams<{ episodeId: string }>();
  const notify = useNotify();

  const [state, setState] = useStateHandlers({ text: '', reply: false });

  const setText = (text: string) => setState({ text });
  const onPostSubmit = () => {
    if (state.text.length > 0) {
      education[replyTo ? 'reply' : 'addComment']
        .action(replyTo ? replyTo : Number(episodeId), {
          text: state.text,
          episode: Number(episodeId),
        })
        .then(({ data }) => {
          notify.success({ title: t('success'), description: t('added_comment') });
          onSubmit && onSubmit();
          setState({ text: '' });

          if (callback) {
            callback(data);
          }
          increaseCommentCount();
        })
        .catch(() => notify.error({ title: t('error'), description: t('cant_add_comment') }));
    }
  };

  return (
    <div className="write-comment">
      <div className="write-comment__avatar">
        <Avatar circle img={userData.photo} alt={userData.full_name} />
      </div>
      <div className="write-comment__text-area">
        <Textarea
          className={cn({ 'has__more-value': state.text?.length > 150 })}
          placeholder={t('add_a_comment_placeholder')}
          value={state.text}
          onChange={setText}
        />
      </div>
      <div className="write-comment__post">
        <span onClick={onPostSubmit}>{t('post')}</span>
      </div>
    </div>
  );
};
