import { Store } from 'features/qa/pages';
import { QAResults } from 'libs/http/api/qa/qa.types';
import { uid } from 'libs/utils';
import { createContext, ReactElement, useEffect, useState } from 'react';

type State = (toggle: () => void) => React.ReactNode;

interface Props {
  data: QAResults[];
  userToken?: string;
  notAnimated: number;
  queryId?: string;
  position?: number;
  header?: State;
  setHeader: React.Dispatch<(toggle: () => void) => React.ReactNode>;
  setData: React.Dispatch<React.SetStateAction<QAResults[]>>;
  setNotAnimated: React.Dispatch<React.SetStateAction<number>>;
  setQueryId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPosition: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const QAContext = createContext<Props>({
  data: [],
  notAnimated: -1,
  header: () => null,
  setHeader: () => null,
  setData: () => null,
  setNotAnimated: () => null,
  setQueryId: () => null,
  setPosition: () => null,
});

export const QAProvider = ({ children }: { children: ReactElement }): ReactElement => {
  const cacheId = localStorage.getItem(Store.USER);
  const [data, setData] = useState<QAResults[]>([]);
  const [notAnimated, setNotAnimated] = useState(-1);
  const [queryId, setQueryId] = useState<string>();
  const [position, setPosition] = useState<number>();
  const [header, setHeader] = useState<State>();
  const [userToken] = useState<string>(cacheId ? cacheId.toString() : uid());

  useEffect(() => {
    localStorage.setItem(Store.USER, userToken);
  }, [userToken]);

  const values = {
    data,
    userToken,
    notAnimated,
    queryId,
    position,
    header,
    setHeader: (value: (toggle: () => void) => React.ReactNode) => setHeader(() => value),
    setData,
    setNotAnimated,
    setQueryId,
    setPosition
  };

  return <QAContext.Provider value={values}>{children}</QAContext.Provider>;
};
