import { useContext, useMemo } from 'react';
import { useIntl } from 'estafette-intl';
import { Loader, Space } from 'ebs-design';
import { Icon } from 'ui/atoms/Icon/Icon';
import { UserDevice } from 'libs/http/api/users/users.types';
import { DevicesContext } from 'contexts/DevicesContext';
import { users } from 'libs/http/api';
import { useStateHandlers } from 'hooks';

export const DeviceItem = ({ device, onChoose }: { device: UserDevice; onChoose: () => void }) => {
  const { thisDevice, setDevices } = useContext(DevicesContext);

  const { t } = useIntl();

  const [state, setState] = useStateHandlers({ loading: false });

  const onDeviceClick = () => {
    setState({ loading: true });
    onChoose();

    users.activateDevice
      .action({ device_id: device.id })
      .then(({ data }) => setDevices(data))
      .finally(() => setState({ loading: false }));
  };

  const isActive = useMemo(() => device.status === 'active', [device]);

  return (
    <div
      className={`switch-devices-tooltip__item ${device.status === 'active' ? 'active' : ''}`}
      onClick={onDeviceClick}
    >
      <div className="switch-devices-tooltip__item__icon">
        {state.loading ? <Loader.Spinner size="middle" /> : <Icon type={`${device.type}-icon`} />}
      </div>
      <Space justify="end" size={3}>
        {isActive && <div className="switch-devices-tooltip__item__active">{t('watching_on')}:</div>}
        {thisDevice && !isActive && (
          <div className="switch-devices-tooltip__item__this-device">
            {device.id === thisDevice.id ? t('thisComputer') : ''}
          </div>
        )}
        <span className="switch-devices-tooltip__item__name">{device.name}</span>
      </Space>
    </div>
  );
};
