import React from 'react';
import { Space } from 'ebs-design';
import { ProgramReadOnly } from 'libs/http/api/education/education.types';
import { getSubscribers } from 'libs/utils';
import { HeadTitle } from 'ui/atoms';
import { Author } from 'ui/molecules';
import { useIntl } from 'hooks/useIntl';

interface Props {
  program: ProgramReadOnly;
}

export const Overview = ({ program }: Props) => {
  const { t } = useIntl();

  return (
    <div className="overview">
      <div>
        <HeadTitle className="overview__title">{t('programHas')}</HeadTitle>
        <Space justify={'space-around'} className="overview__program-overview">
          {!program.is_new && Boolean(program.chapters_count) && (
            <div className="overview__program-overview__item">
              <span className="overview__program-overview__item-data">{program.chapters_count}</span>
              <span className="overview__program-overview__item-label">{t('chapters')}</span>
            </div>
          )}
          {!program.is_new && Boolean(program.episodes_count) && (
            <div className="overview__program-overview__item">
              <span className="overview__program-overview__item-data">{program.episodes_count}</span>
              <span className="overview__program-overview__item-label">{t('episodes')}</span>
            </div>
          )}
          {!program.is_new && Boolean(program.subscriptions_count) && (
            <div className="overview__program-overview__item">
              <span className="overview__program-overview__item-data">
                {getSubscribers(program.subscriptions_count)}
              </span>
              <span className="overview__program-overview__item-label">{t('members')}</span>
            </div>
          )}
          {Boolean(program.reviews_statistic?.average) && (
            <div className="overview__program-overview__item">
              <span className="overview__program-overview__item-data">{program.reviews_statistic.average}</span>
              <span className="overview__program-overview__item-label">{t('rating')}</span>
            </div>
          )}
        </Space>
      </div>

      <div className="overview__program-overview__description">
        <div className="overview__program-overview__description-label">{t('Description')}</div>
        <div className="overview__program-overview__description-text">{program.description}</div>
      </div>

      <Author program={program} />
    </div>
  );
};
