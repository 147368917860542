import { useContext } from 'react';
import { UserContext } from 'contexts/UserContext';
import { AvatarInline } from 'ebs-design';
import { useIntl } from 'estafette-intl';
import { Link } from 'react-router-dom';
import { getRoute } from 'libs/utils';
import { ResponsiveContext } from 'contexts/ResponsiveContext';
import { breakpoints } from 'libs/globalVars';

interface Props {
  onSignOut: () => void;
}

export const UserTooltip = ({ onSignOut }: Props) => {
  const { width } = useContext(ResponsiveContext);
  const { userData } = useContext(UserContext);

  const { t } = useIntl();

  return (
    <>
      <div className="user-tooltip d-flex justify-content--center mt-20">
        <AvatarInline
          circle
          img={userData.photo}
          alt={`${userData.first_name} ${userData.last_name}`}
          className="user-tooltip__avatar"
        />
      </div>
      <Link className="user-tooltip__item" to={getRoute('MyProgramsPage')}>
        <div>{t('my_programs')}</div>
      </Link>

      {width < breakpoints.sm && (
        <>
          <Link to={getRoute('MySavedPrograms')} className="user-tooltip__item">
            {t('saved_episodes')}
          </Link>
        </>
      )}

      <Link className="user-tooltip__item" to={getRoute('MyOrdersPage')}>
        <div>{t('Purchases')}</div>
      </Link>
      <Link className="user-tooltip__item" to={getRoute('HelpPage')}>
        <div>{t('help')}</div>
      </Link>
      <div className="user-tooltip__item" onClick={onSignOut}>
        {t('MyProfileMenuLogout')}
      </div>
    </>
  );
};
