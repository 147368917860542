import React from 'react';
import { ProgramCardWideComposition } from './types';
import { Program } from './Program';
import { Order } from './Order';
import { Events } from './Events';

interface Props {
  image?: string;
}

const ProgramCardWide: React.FC<Props> & ProgramCardWideComposition = ({ image, children }) => {
  return (
    <div className="program-card-wide">
      <div className="program-card-wide__image">
        <img src={image || '' /*todo default image*/} className="" alt="" />
      </div>
      <div className="program-card-wide__body">{children}</div>
    </div>
  );
};

ProgramCardWide.Program = Program;
ProgramCardWide.Events = Events;
ProgramCardWide.Order = Order;

export { ProgramCardWide };
