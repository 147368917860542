import { useContext, useEffect, useMemo } from 'react';
import { UserContext } from 'contexts/UserContext';
import { useIntl } from 'estafette-intl';
import { Loader } from 'ebs-design';
import dayjs from 'dayjs';
import { useRequest } from 'estafette';
import { Container, HeadTitle } from 'ui/atoms';
import { ProgramCardWide } from 'ui/organisms/ProgramCardWide/ProgramCardWide';
import { ProgramsCarousel } from '../../components/molecules/ProgramsCarousel/ProgramsCarousel';
import { ResponsiveContext } from 'contexts/ResponsiveContext';
import { ProgramReadOnly, ProgramType } from 'libs/http/api/education/education.types';
import { breakpoints } from 'libs/globalVars';
import { WithPagination } from 'libs/http/api/index.types';
import { education } from 'libs/http/api/education/education';

export const MyProgramsPage = () => {
  const { logged } = useContext(UserContext);
  const { width } = useContext(ResponsiveContext);
  const { t } = useIntl();

  const programsRequest = useRequest<WithPagination<ProgramReadOnly>>({ data: { results: [] } });

  useEffect(() => {
    programsRequest.request(education.getPrograms.action());
  }, []);

  const myPrograms = useMemo(
    () =>
      programsRequest.data?.results
        .filter(
          (i) =>
            (i.program_subscription || i.payment_type === 'free') &&
            (i.access_status === 'subscribed' || i.program_subscription?.payment_failed) &&
            (i.progress < i.episodes_duration || i.progress === 0),
        )
        .sort(
          (a, b) =>
            dayjs(b.current_episode?.progress_changed).unix() - dayjs(a.current_episode?.progress_changed).unix(),
        ),
    [programsRequest.data],
  );

  const finishedPrograms = useMemo(
    () =>
      programsRequest.data?.results.filter(
        (i) =>
          i.program_subscription &&
          (i.access_status === 'subscribed' || i.program_subscription.payment_failed) &&
          i.progress >= i.episodes_duration &&
          i.progress !== 0,
      ),
    [programsRequest.data],
  );

  const otherPrograms = useMemo(
    () =>
      programsRequest.data?.results.filter(
        (i) => i.program_type === ProgramType.VIDEO && i.access_status === 'locked' && !i.program_subscription,
      ),
    [programsRequest.data],
  );

  const audiobooks = useMemo(
    () =>
      programsRequest.data?.results.filter(
        (i) =>
          i.program_type === ProgramType.AUDIOBOOK && (i.access_status !== 'subscribed' || !i.program_subscription),
      ),
    [programsRequest.data],
  );

  const audioCourses = useMemo(
    () =>
      programsRequest.data?.results.filter(
        (i) =>
          i.program_type === ProgramType.AUDIO_COURSE && (i.access_status !== 'subscribed' || !i.program_subscription),
      ),
    [programsRequest.data],
  );

  return (
    <Container className="my-program">
      <Loader loading={programsRequest.loading} height="500px">
        {Boolean(logged && myPrograms.length) && (
          <div className="my-program__card">
            <HeadTitle className="my-program__title">{t('continueLearning')}</HeadTitle>

            {Boolean(width >= breakpoints.lg && myPrograms.length <= 2) &&
              myPrograms.map((i) => (
                <ProgramCardWide key={i.id} image={i.cover_photo}>
                  <ProgramCardWide.Program program={i} />
                </ProgramCardWide>
              ))}
            {Boolean(width <= breakpoints.lg || myPrograms.length > 2) && <ProgramsCarousel programs={myPrograms} />}
          </div>
        )}
        {Boolean(logged && finishedPrograms.length) && (
          <div className="my-program__card">
            <HeadTitle className="my-program__title">{t('completed')}</HeadTitle>
            <ProgramsCarousel programs={finishedPrograms} />
          </div>
        )}
        {Boolean(otherPrograms.length) && (
          <>
            <HeadTitle className="my-program__title">{t('allPrograms')}</HeadTitle>
            <ProgramsCarousel programs={otherPrograms} />
          </>
        )}
        {Boolean(audiobooks.length) && (
          <>
            <HeadTitle>{t('programs_audio')}</HeadTitle>
            <ProgramsCarousel programs={audiobooks} />
          </>
        )}
        {Boolean(audioCourses.length) && (
          <>
            <HeadTitle>{t('programs_audio_courses')}</HeadTitle>
            <ProgramsCarousel programs={audioCourses} />
          </>
        )}
      </Loader>
    </Container>
  );
};
