import { useStateHandlers } from '../hooks';
import { $Object } from '../libs/globalTypes';
import { ProgramReadOnly } from '../libs/http/api/education/education.types';
import { useIntl } from 'estafette-intl';
import { createContext, ReactElement } from 'react';

interface Props extends State {
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;
  getFirstBreadcrumbItem: (program: ProgramReadOnly) => { label: string; route: string };
}

interface State {
  breadcrumbs: Breadcrumb[];
}

interface Breadcrumb {
  label: string;
  route?: string;
  params?: $Object;
}

export const BreadcrumbsContext = createContext<Props>({
  breadcrumbs: [],
  setBreadcrumbs: () => null,
  getFirstBreadcrumbItem: () => ({
    label: '',
    route: '',
  }),
});

export const BreadcrumbsProvider = ({ children }: { children: ReactElement }): ReactElement => {
  const { t } = useIntl();

  const [state, setState] = useStateHandlers<State>({ breadcrumbs: [] });

  const setBreadcrumbs = (breadcrumbs: Breadcrumb[]) => setState({ breadcrumbs });

  const getFirstBreadcrumbItem = (program: ProgramReadOnly) => {
    if (program.access_status === 'subscribed') {
      return {
        label: t('my_programs'),
        route: 'MyProgramsPage',
      };
    }

    return {
      label: t('other_programs'),
      route: 'OtherProgramsPage',
    };
  };

  return (
    <BreadcrumbsContext.Provider
      value={{
        breadcrumbs: state.breadcrumbs,
        setBreadcrumbs: setBreadcrumbs,
        getFirstBreadcrumbItem: getFirstBreadcrumbItem,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};
