import React, { useMemo } from 'react';
import { Space } from 'ebs-design';
import { Link } from 'react-router-dom';
import { Rating, ProgressBar } from 'ui/atoms';
import { Attending } from 'ui/molecules/Attending/Attending';
import { getRoute, getSubscribers } from 'libs/utils';
import { useIntl as $useIntl } from 'hooks/useIntl';
import { ProgramProps } from './types';
import { ClapClap } from 'resources';

export const Program: React.FC<ProgramProps> = ({ program }) => {
  const { t, tPlural } = $useIntl();
  const programProgress = useMemo(() => (program.progress / program.episodes_duration) * 100 || 0, [program]);

  const leftMinutes = useMemo(
    () =>
      Math.ceil(((program.current_episode?.duration || 0) - (program.current_episode?.progress || 0)) / 60) ||
      Math.ceil(((program.current_episode?.next?.duration || 0) - (program.current_episode?.next?.progress || 0)) / 60),
    [program],
  );

  return (
    <div className="program-card__body">
      <div className="program-card__body__header">
        <span className="program-card__body__title">{program.title}</span>
        <div className="program-card__body__description">{program.description}</div>
      </div>
      {program.program_subscription &&
      (program.access_status === 'subscribed' || program.program_subscription.payment_failed) ? (
        <>
          <div className="program-card__body__progress-bar">
            <ProgressBar progress={programProgress} />
          </div>
          {(leftMinutes > 0 && program.current_episode) || program.current_episode?.next ? (
            <div className="program-card__body__chapter">
              <span className="bold">{t('nextEpisode')}: </span>
              {leftMinutes > 0 ? program.current_episode?.title : program.current_episode?.next?.title}
            </div>
          ) : (
            <div className="program-card__body__chapter">
              <span className="bold">{t('completed')}:</span>
              {t('wellDone')}
              <ClapClap className="program-card__body__chapter__clap-clap" />
              <ClapClap className="program-card__body__chapter__clap-clap" />
              <ClapClap className="program-card__body__chapter__clap-clap" />
            </div>
          )}
          <div className="program-card__body__chapter-description">
            {program.progress >= program.episodes_duration && program.progress !== 0 ? (
              t('youCanStartAgain')
            ) : (
              <>
                {leftMinutes} {t('minLeftInThisEpisode')}
              </>
            )}
          </div>

          <Space
            className="program-card__body__statistic"
            size={0}
            justify={
              !program.is_new && Boolean(program.last_subscriptions.length || program.subscriptions_count)
                ? 'space-between'
                : 'end'
            }
          >
            {!program.is_new && Boolean(program.last_subscriptions.length || program.subscriptions_count) && (
              <Space align="start" size={0} className="program-card__body__statistic-members">
                <Attending
                  users={(program.last_subscriptions.length
                    ? program.last_subscriptions
                    : [{ photo: '' }, { photo: '' }]
                  ).map((i) => ({
                    avatar: i.photo,
                  }))}
                />
                <span className="program-card__body__text-statistic">
                  {tPlural('members', getSubscribers(program.subscriptions_count))}
                </span>
              </Space>
            )}

            <div className="program-card__body__action-button">
              <Link to={getRoute('WatchProgramPage', { programId: program.id })}>
                <span>
                  {program.program_subscription &&
                  (program.access_status === 'subscribed' || program.program_subscription.payment_failed)
                    ? program.progress < program.episodes_duration && program.progress
                      ? t('continueWatch')
                      : program.progress === 0
                      ? t('Start')
                      : t('startAgain')
                    : t('unlock_now')}
                </span>
              </Link>
            </div>
          </Space>
        </>
      ) : (
        <div className="program-card__body__footer">
          <div className="program-card__body__rating">
            <Rating value={program.reviews_statistic?.average} className="m-ratings" />
          </div>
          {!program.is_new && Boolean(program.chapters_count && program.episodes_count) && (
            <div className="program-card__body__contain">
              {`${program.chapters_count} ${t('chapters')}, ${program.episodes_count} ${t('episodes')}`}
            </div>
          )}
          {program.is_new && <span className="program-card__body__new">{t('new')}</span>}
        </div>
      )}
    </div>
  );
};
