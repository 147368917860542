import { useContext, useEffect } from 'react';
import { Loader, Row, Col, useNotify } from 'ebs-design';
import { useIntl } from 'hooks/useIntl';
import { Scroll } from 'ui/atoms';
import { Notification as NotificationComponent } from '../molecules/Notification';
import { CommunityContext } from 'contexts/CommunityContext';
import { notifications as notificationsApi } from 'libs/http/api';
import { Icon } from 'ui/atoms/Icon/Icon';
import { Placeholder } from 'ui/atoms/Placeholder/Placeholder';

export const Notifications = () => {
  const { notifications } = useContext(CommunityContext);
  const notify = useNotify();
  const { t } = useIntl();

  useEffect(() => {
    if (notifications.unseen) {
      notificationsApi.setSeen.action({ ids: notifications.data.map((i) => i.id) });
      notifications.setUnseen(0);
    }
  }, [notifications.unseen]);

  const onNotificationRead = (id: number) =>
    notificationsApi.setRead
      .action(id)
      .then(() => {
        notify.success({ title: t('success'), description: t('notification_read') });
        notifications.setNotifications({
          results: notifications.data.map((i) => (i.id === id ? { ...i, read: true } : i)),
        });
      })
      .catch(() => notify.success({ title: t('error'), description: t('cant_read_notification') }));

  return (
    <Loader loading={notifications.loading}>
      <Scroll height="calc(100vh - 110px)">
        {notifications.data.length > 0 ? (
          <Row className="m-notifications">
            <Col className="m-notifications-wrapper">
              {notifications.data.map((i) => (
                <NotificationComponent notification={i} onRead={onNotificationRead} key={i.id} />
              ))}
            </Col>
          </Row>
        ) : (
          <Row className="m-notifications">
            <div className="m-notifications-title">{t('MyProfileMenuNotifications')}</div>
            <Placeholder icon={<Icon type="no-notifications" />} text={t('no_notifications')} />
          </Row>
        )}
      </Scroll>
    </Loader>
  );
};
