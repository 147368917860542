import React from 'react';
import { useIntl } from 'estafette-intl';
import { Link } from 'react-router-dom';
import { EpisodeReadOnly } from 'libs/http/api/education/education.types';
import { GoToIcon } from 'resources';
import { getRoute } from 'libs/utils';

interface Props {
  episode: EpisodeReadOnly;
}

export const EpisodeBlock: React.FC<Props> = ({ episode }) => {
  const { t } = useIntl();

  return (
    <div className="episode-block">
      <div className="episode-block__image">
        <img src={episode.cover_photo || '' /*todo default image*/} alt="" />
      </div>
      <div className="episode-block__body">
        <div className="episode-block__wrapper">
          <div className="episode-block__number">
            {t('episode')} {episode.number}
            <div className="episode-block__program-title">{episode.program?.title}</div>
          </div>
          <Link to={getRoute('WatchEpisodePage', { episodeId: episode.id })}>
            <div className="episode-block__title">{episode.title}</div>
          </Link>
          <div className="episode-block__description">{episode.program?.title || episode.description}</div>
        </div>
        <Link className="episode-block__button" to={getRoute('WatchEpisodePage', { episodeId: episode.id })}>
          <GoToIcon />
        </Link>
      </div>
    </div>
  );
};
