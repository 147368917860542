import { useEffect, useMemo } from 'react';
import { Loader } from 'ebs-design';
import { useRequest } from 'estafette';
import { ProgramsCarousel } from '../components/molecules/ProgramsCarousel/ProgramsCarousel';
import { Container, HeadTitle } from 'ui/atoms';
import { useIntl } from 'hooks';
import { WithPagination } from 'libs/http/api/index.types';
import { ProgramReadOnly } from 'libs/http/api/education/education.types';
import { education } from 'libs/http/api/education/education';

export const OtherProgramsPage = () => {
  const { t } = useIntl();

  const programsRequest = useRequest<WithPagination<ProgramReadOnly>>({ data: { results: [] } });

  useEffect(() => {
    programsRequest.request(education.getPrograms.action());
  }, []);

  const otherPrograms = useMemo(
    () => programsRequest.data.results.filter((i: any) => i.program_type === 'video' && !i.program_subscription),
    [programsRequest.data],
  );

  const audiobooks = useMemo(
    () =>
      programsRequest.data.results.filter((i) => i.program_type === 'audiobook' && i.access_status !== 'subscribed'),
    [programsRequest.data],
  );

  const audioCourses = useMemo(
    () =>
      programsRequest.data.results.filter((i) => i.program_type === 'audio_course' && i.access_status !== 'subscribed'),
    [programsRequest.data],
  );

  return (
    <Loader loading={programsRequest.loading} height="500px">
      <Container>
        {Boolean(otherPrograms.length) && (
          <>
            <HeadTitle>{t('allPrograms')}</HeadTitle>
            <ProgramsCarousel programs={otherPrograms} />
          </>
        )}
        {Boolean(audiobooks.length) && (
          <>
            <HeadTitle>{t('programs_audio')}</HeadTitle>
            <ProgramsCarousel programs={audiobooks} />
          </>
        )}
        {Boolean(audioCourses.length) && (
          <>
            <HeadTitle>{t('programs_audio_courses')}</HeadTitle>
            <ProgramsCarousel programs={audioCourses} />
          </>
        )}
      </Container>
    </Loader>
  );
};
