import { forwardRef, ForwardRefRenderFunction, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useVideoJsPlayer, VideoJsConfig } from './videoJsPlayer';
import { Icon } from 'ui/atoms/Icon/Icon';
import { Row, Col, Space, Tooltip, Loader } from 'ebs-design';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useStateHandlers } from 'hooks';
import { getTimeFromSeconds } from 'libs/utils';
import { Switch } from 'ui/atoms';
import { VideoProgressBar } from 'ui/atoms/VideoPorgressBar/VideoProgressBar';
import { education } from 'libs/http/api/education/education';
import { $Object } from 'libs/globalTypes';
import { UserDeviceStatus } from 'libs/http/api/users/users.types';
import { StylesContext } from 'contexts/StylesContext';
import { DevicesContext } from 'contexts/DevicesContext';
import { Play } from 'resources';

export type VideoPlayerProps = {
  className?: string;
  autoplay?: boolean;
  initialized?: boolean;
  playing?: boolean;
  nextEpisodeLink?: string;
  duration: number;
  currentTime: number;
  autoPlayNext: boolean;
  subtitlesSrc?: string;
  showSubtitles: 'showing' | 'hidden';
  onSettingsChange: (newValues: $Object) => void;
  onInitialized?: () => void;
} & VideoJsConfig;

const VideoPlayer: ForwardRefRenderFunction<HTMLVideoElement, VideoPlayerProps> = (
  {
    className,
    autoplay,
    initialized,
    playing,
    nextEpisodeLink,
    duration,
    currentTime = 0,
    onSettingsChange,
    autoPlayNext,
    subtitlesSrc,
    showSubtitles,
    onInitialized,
    ...videoJsConfig
  },
  externalRef,
) => {
  const { setFullWindow } = useContext(StylesContext);
  const { showDeviceSwitch, thisDevice } = useContext(DevicesContext);

  const [player, playerRef] = useVideoJsPlayer(videoJsConfig);
  const { episodeId } = useParams<{ episodeId: string }>();
  const { push } = useHistory();

  const { current: timer } = useRef<{ callback?: NodeJS.Timeout; time: number }>({ time: 5000 });

  const [isInit, setIsInit] = useState(false);
  const [state, setState] = useStateHandlers({
    actionsOverlay: false,
    playOverlayVisible: true,
    initialized: initialized || false,
    isPlaying: false,
    currentTime,
    totalTime: 0,
    progress: 0,
    savedProgress: 0,
    loading: false,
    fullScreen: false,
    fullWindow: false,
    muted: videoJsConfig.muted,
    localSrc: videoJsConfig.src,
    showVideoOverlay: false,
    isMouseMoving: 0,
  });

  useEffect(() => {
    if (!isInit && currentTime && playerRef.current) {
      playerRef.current.currentTime = currentTime;

      setState({ currentTime });
      setIsInit(true);
    }
  }, [currentTime, playerRef]);

  useEffect(() => {
    if (!player) {
      return;
    }
    if (thisDevice && thisDevice.status !== UserDeviceStatus.active) {
      player.pause();
    } else {
      player.play();
    }
  }, [showDeviceSwitch, thisDevice]);

  useEffect(() => {
    if (!player) {
      return;
    }

    player.controlBar.hide();

    const handler = () => {
      setState({ initialized: true });
      if (onInitialized) onInitialized();
    };

    player.on('loadstart', handler);

    return () => {
      player.off('loadstart', handler);
    };
  }, [player]);

  useEffect(() => {
    if (!player) {
      return;
    }

    const onVideoEnd = () => {
      education.setEpisodeProgress.action(Number(episodeId), { progress: duration }).then(() => {
        if (nextEpisodeLink && autoPlayNext) {
          push(nextEpisodeLink);
        }
      });
    };

    if (nextEpisodeLink) {
      player.on('ended', onVideoEnd);
    }

    return () => {
      player.off('ended', onVideoEnd);
    };
  }, [player, nextEpisodeLink]);

  useEffect(() => {
    if (!player) {
      return;
    }

    const onCurrentTimeChange = () => {
      const currentTime = player.currentTime();

      const progress = (currentTime / state.totalTime) * 100;

      if (progress) {
        setState({ currentTime, progress });
      }
    };

    player.on('timeupdate', onCurrentTimeChange);
  }, [player, state.totalTime]);

  // Save time
  useEffect(() => {
    const value = Math.round(state.currentTime);
    if (value % 5 === 0 && value !== state.savedProgress) {
      setState({ savedProgress: value });
      education.saveProgress.action(parseInt(episodeId), value);
    }
  }, [state.currentTime]);

  useEffect(() => {
    if (!player) {
      return;
    }

    const tracks = player.textTracks();

    if (subtitlesSrc && tracks.length === 0) {
      player.addRemoteTextTrack(
        {
          src: subtitlesSrc,
          kind: 'captions',
          default: true,
          mode: showSubtitles,
        },
        false,
      );
    }

    if (tracks.length > 0) {
      tracks[0].mode = showSubtitles;
    }
  }, [player, subtitlesSrc, showSubtitles]);

  useEffect(() => {
    if (!player) {
      return;
    }

    if (!state.playOverlayVisible && state.localSrc !== videoJsConfig.src) {
      getVideoSrc();
      player.reset();
    }
  }, [videoJsConfig.src]);

  useEffect(() => {
    setState({ totalTime: duration });
  }, [duration]);

  useEffect(() => {
    if (!player || !state.initialized || !autoplay) {
      return;
    }
    handlePlayOverlayClick();

    const playPromise = player.play();
    if (playPromise) {
      playPromise.catch((e) => {
        console.warn('Autoplay failed:', e);
      });
    }
  }, [player, state.initialized, autoplay]);

  useEffect(() => {
    if (!player) {
      return;
    }

    if (playing != null) {
      if (playing) {
        const playPromise = player.play();

        if (playPromise) {
          playPromise.catch((e) => {
            console.error('Video play failed:', e);
          });
        }
      } else {
        player.pause();
      }
    }
  }, [player, playing]);

  useEffect(() => {
    if (!player) {
      return;
    }

    const onPlay = () => setState({ playOverlayVisible: false, isPlaying: true });
    const onPause = () => {
      setState({ isPlaying: false });
      onSeeked();
    };

    const onFullScreenChange = () => {
      setState((prevState) => ({ fullScreen: !prevState.fullScreen }));
      setFullWindow((prevState) => !prevState);
    };

    const onFullWindowEnter = () => {
      setFullWindow(true);
      setState({ fullWindow: true, fullScreen: false });
    };

    const onFullWindowExit = () => {
      setFullWindow(false);
      setState({ fullWindow: false });
    };

    const onSeeked = () => {
      const value = Math.round(player.currentTime());
      education.saveProgress.action(parseInt(episodeId), value);
    };

    player.on('play', onPlay);
    player.on('pause', onPause);
    player.on('ended', onSeeked);
    player.on('seeked', onSeeked);
    player.on('fullscreenchange', onFullScreenChange);
    player.on('enterFullWindow', onFullWindowEnter);
    player.on('exitFullWindow', onFullWindowExit);

    return () => {
      player.off('play', onPlay);
      player.off('pause', onPause);
      player.off('fullscreenchange', onFullScreenChange);
      player.off('enterFullWindow', onFullWindowEnter);
      player.off('exitFullWindow', onFullWindowExit);
    };
  }, [player]);

  useEffect(() => {
    if (!externalRef) {
      return;
    }

    if (typeof externalRef === 'function') {
      externalRef(playerRef.current);
    } else {
      externalRef.current = playerRef.current;
    }
  }, [externalRef, playerRef]);

  useEffect(() => {
    if (!player) {
      return;
    }

    player.muted(state.muted || false);
  }, [player, state.muted]);

  useEffect(() => {
    if (state.isMouseMoving) {
      timer.callback = setTimeout(() => {
        setState(() => ({ isMouseMoving: 0 }));
      }, 2000);
    }

    return () => {
      if (timer.callback !== undefined) {
        clearTimeout(timer.callback);
      }
    };
  }, [state.isMouseMoving]);

  const showVideoHoverClass = useMemo(() => (Boolean(state.isMouseMoving) ? 'show' : ''), [state.isMouseMoving]);

  const getVideoSrc = async () => {
    if (player && videoJsConfig.src) {
      setState({ loading: true });

      if (videoJsConfig.src.includes('m3u8')) {
        player.src({
          src: videoJsConfig.src,
          type: 'application/x-mpegURL',
        });
      } else {
        await fetch(videoJsConfig.src).then(async (res) => {
          const blob = await res.blob();
          const blobUrl = URL.createObjectURL(blob);

          player.src({
            src: blobUrl,
            type: blob.type,
          });
        });
      }

      player.play();

      setState({
        loading: false,
        localSrc: videoJsConfig.src,
        playOverlayVisible: false,
        muted: videoJsConfig.muted || false,
      });
    }
  };

  const handlePlayOverlayClick = () => {
    if (!player) {
      return;
    }

    if (!player.currentSource().src) {
      getVideoSrc();
    }
  };

  const onPlay = () => {
    if (!player) {
      return;
    }
    player.play();
  };

  const onPause = () => {
    if (!player) {
      return;
    }

    player.pause();
  };

  const pass10Sec = () => {
    if (!player) {
      return;
    }

    player.currentTime(player.currentTime() + 10);
  };

  const revert10Sec = () => {
    if (!player) {
      return;
    }

    player.currentTime(player.currentTime() - 10);
  };

  const setCurrentTime = (value: number) => {
    if (!player) {
      return;
    }

    player.currentTime((value / 100) * state.totalTime);
  };

  const onFullScreenChange = () => {
    if (!player) {
      return;
    }

    if (!state.fullScreen && !state.fullWindow) {
      player.requestFullscreen();
    }

    if (player.isFullscreen()) {
      if (state.fullScreen) {
        player.exitFullscreen();
      }

      if (state.fullWindow) {
        player.exitFullWindow();
      }
    }
  };

  const onMuteChange = () => setState((prevState) => ({ muted: !prevState.muted }));
  const onNextEpisodeChange = () => onSettingsChange({ autoPlayNext: !autoPlayNext });
  const onDefaultMutedChange = () => onSettingsChange({ mutedByDefault: !videoJsConfig.muted });
  const onSubtitlesChange = () => {
    const subtitleStatus = showSubtitles === 'showing' ? 'hidden' : 'showing';

    onSettingsChange({ subtitles: subtitleStatus });
  };
  const onMouseMove = () => setState((prevState) => ({ isMouseMoving: ++prevState.isMouseMoving }));
  const onMouseLeave = () => setState({ isMouseMoving: 0 });

  return (
    <div data-vjs-player onMouseMove={onMouseMove} onMouseLeave={onMouseLeave}>
      <div className={className}>
        {(state.playOverlayVisible || state.loading) && (
          <>
            <Row className="video-js-hover-play">
              {state.loading ? (
                <Loader loading />
              ) : (
                <div className="watch-layout__video__play" onClick={handlePlayOverlayClick}>
                  <Play />
                </div>
              )}
            </Row>
          </>
        )}

        {(!state.playOverlayVisible || state.loading) && (
          <>
            <div className={`video-js-hover-background ${showVideoHoverClass}`} />

            <div>
              <Row className={`video-js-hover ${showVideoHoverClass}`}>
                <Col className="d-flex align-items--center">
                  <Row className="video-actions-container">
                    <Space size="large" justify="space-between" className="m-video-controls sm-no-padding">
                      <Icon type="video-back" className="cursor-pointer" />
                      <Icon type="seconds-back" className="cursor-pointer" onClick={revert10Sec} />
                      {state.loading ? (
                        <div className="m-play-holder" />
                      ) : state.isPlaying ? (
                        <Icon type="pause" className="cursor-pointer" onClick={onPause} />
                      ) : (
                        <Icon type="small-play" className="m-play-btn" onClick={onPlay} />
                      )}
                      <Icon type="seconds-next" className="cursor-pointer" onClick={pass10Sec} />
                      {nextEpisodeLink ? (
                        <Link to={nextEpisodeLink}>
                          <Icon type="video-next" className="cursor-pointer" />
                        </Link>
                      ) : (
                        <Icon type="video-next" className="cursor-pointer" />
                      )}
                    </Space>
                  </Row>

                  <Row className="m-control-bar justify-content--center">
                    <Col size="auto" className="m-action-mute desktop sm-no-padding">
                      <div>
                        <Icon
                          type={state.muted ? 'no-sound' : 'sound'}
                          onClick={onMuteChange}
                          className="cursor-pointer"
                        />
                      </div>
                    </Col>
                    <Col size={2} sm="auto" className="control-bar-text">
                      {getTimeFromSeconds(state.currentTime)}
                    </Col>
                    <Col size={8} className="m-video-progress-bar">
                      <VideoProgressBar progress={state.progress} onChange={setCurrentTime} />
                    </Col>
                    <Col size={2} sm="auto" className="control-bar-text">
                      {getTimeFromSeconds(state.totalTime)}
                    </Col>
                    <Col size={9} sm="auto" className="m-action-mute mobile m-mobile-margin">
                      <div>
                        <Icon
                          type={state.muted ? 'no-sound' : 'sound'}
                          onClick={onMuteChange}
                          className="cursor-pointer"
                        />
                      </div>
                    </Col>
                    <Col size={2} sm="auto" className="m-mobile-margin d-flex justify-content--end m-hide">
                      <Icon
                        type={showSubtitles === 'showing' ? 'subtitles' : 'no-subtitles'}
                        className="cursor-pointer"
                        onClick={onSubtitlesChange}
                      />
                    </Col>
                    <Col size={1} sm="auto" className="m-mobile-margin d-flex justify-content--center">
                      <Tooltip
                        placement="top"
                        trigger="click"
                        tooltip={
                          <div className="m-video-settings">
                            <Row className="mb-20">
                              <Col size={9} className="p-0">
                                Autoplay next episode
                              </Col>
                              <Col size={3} className="p-0">
                                <Switch checked={autoPlayNext} onChange={onNextEpisodeChange} />
                              </Col>
                            </Row>
                            <Row>
                              <Col size={9} className="p-0">
                                Sound off by default
                              </Col>
                              <Col size={3} className="p-0">
                                <Switch checked={videoJsConfig.muted} onChange={onDefaultMutedChange} />
                              </Col>
                            </Row>
                          </div>
                        }
                      >
                        <Icon type="video-settings" className="cursor-pointer" />
                      </Tooltip>
                    </Col>
                    <Col size={1} sm="auto" className="m-mobile-margin">
                      <Icon
                        type={state.fullScreen || state.fullWindow ? 'minimize' : 'full-size'}
                        className="cursor-pointer"
                        onClick={onFullScreenChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </>
        )}
      </div>

      <video ref={playerRef} className="video-js" />
    </div>
  );
};

export default forwardRef(VideoPlayer);
