import React, { useMemo } from 'react';
import { CarouselButtonGroup } from 'ui/organisms/Carousel/CarouselButtonGroup';
import { ProgramCard } from 'ui/organisms/ProgramCard/ProgramCard';
import { Webinar } from 'libs/http/api/events/events.types';
import { Carousel } from 'ui/organisms/Carousel/Carousel';

interface Props {
  webinars: Webinar[];
  isEnrolled?: boolean;
  enrolledList?: number[];
}

export const EventsCarousel: React.FC<Props> = ({ webinars, isEnrolled, enrolledList = [] }) => {
  const carouselCards = useMemo(() => {
    return webinars.map((i) => (
      <ProgramCard
        key={i.id}
        id={i.id}
        image={i.image_url}
        title={i.title}
        webinar={i}
        is_program={false}
        isEnrolled={isEnrolled}
      >
        <ProgramCard.Events webinar={i} isEnrolled={isEnrolled || enrolledList?.includes(i.id)} />
      </ProgramCard>
    ));
  }, [webinars]);

  return (
    <Carousel
      className="programs-carousel"
      items={carouselCards}
      buttonGroup={<CarouselButtonGroup count={webinars.length} />}
    />
  );
};
