import React from 'react';
import { Upload } from 'resources';
import { useIntl } from 'estafette-intl';

export const DropZone: React.FC = () => {
  const { t } = useIntl();
  return (
    <div className="drop-zone">
      <div className="drop-zone__wrapper">
        <Upload />
        <p className="drop-zone__title">
          {t('dragAndDropHere')} <span className="drop-zone__link">{t('browseFile')}</span>
        </p>
      </div>
    </div>
  );
};
