import { $Object } from '../../../globalTypes';
import { DefaultPrice } from '../sales/sales.types';
import { User } from '../users/users.types';

export interface ProgramReadOnly {
  id: number;
  episodes_duration: number;
  chapter?: ChapterReadOnly;
  chapters_count: number;
  episodes_count: number;
  current_episode?: CurrentEpisode;
  subscriptions_count: number;
  is_favorite: boolean;
  is_new?: boolean;
  show_reviews?: boolean;
  access_status: string;
  access_days: number;
  progress: number;
  diploma_gift: string;
  program_subscription: any;
  unfinished_checkout: string;
  mongo_id: string;
  created_at: string;
  modified_at: string;
  title: string;
  motto: string;
  cover_photo: string;
  description: string;
  comments_type_qa: boolean;
  keywords: string[];
  child_age_group: string[];
  payment_type: 'free' | 'trial' | 'premium';
  trial_period: number;
  badge_image: string;
  sales_page: string;
  author: Author;
  is_original: boolean;
  additional_invoice_item_text: string;
  isbn: string;
  program_type: ProgramType;
  sample_episode?: EpisodeDetail;
  language: string;
  original: number;
  merge_reviews_from: number;
  reviews_statistic: {
    average: number;
    reviews: number;
    users: number;
    stats: $Object;
  };
  default_price: DefaultPrice;
  last_subscriptions: User[];
}

export enum ProgramType {
  VIDEO = 'video',
  AUDIOBOOK = 'audiobook',
  AUDIO_COURSE = 'audio_course',
}

export const emptyProgram: ProgramReadOnly = {
  id: 0,
  episodes_duration: 0,
  chapters_count: 0,
  episodes_count: 0,
  subscriptions_count: 0,
  is_favorite: false,
  comments_type_qa: false,
  is_new: false,
  access_status: '',
  access_days: 0,
  progress: 0,
  diploma_gift: '',
  program_subscription: {},
  unfinished_checkout: '',
  mongo_id: '',
  created_at: '',
  modified_at: '',
  title: '',
  motto: '',
  cover_photo: '',
  description: '',
  keywords: [''],
  child_age_group: [''],
  payment_type: 'free',
  trial_period: 0,
  badge_image: '',
  sales_page: '',
  author: {
    photo: '',
    full_name: '',
    description: '',
  },
  is_original: false,
  additional_invoice_item_text: '',
  isbn: '',
  program_type: ProgramType.VIDEO,
  language: '',
  original: 0,
  merge_reviews_from: 0,
  reviews_statistic: {
    average: 0,
    reviews: 0,
    users: 0,
    stats: {},
  },
  default_price: {
    price: 0,
    discount: {
      value: 0,
      value_type: 'amount_off',
      duration: 'once',
      duration_in_months: 0,
    },
    shipping_price: 0,
    shipping_price_international: 0,
    description: '',
    installments: 0,
    currency: '',
  },
  last_subscriptions: [],
};

export interface ChapterReadOnly {
  id: number;
  extra_title: string;
  access_status: string;
  unlock_drip: number | null;
  title: string;
  cover_photo: string;
  description: string;
  program: ProgramReadOnly;
  number?: number;
  progress: number;
  episodes_count: number;
  episodes_duration: number;
}

export const emptyChapter: ChapterReadOnly = {
  id: 0,
  extra_title: '',
  access_status: '',
  unlock_drip: null,
  title: '',
  cover_photo: '',
  description: '',
  program: emptyProgram,
  progress: 0,
  episodes_count: 0,
  episodes_duration: 0
};

export interface EpisodeReadOnly {
  id: number;
  is_bookmarked: boolean;
  whatched_video: boolean;
  access_status: string;
  unlock_drip: number | null;
  my_reactions_count: number;
  comments_count: number;
  video: string;
  extra_title: string;
  title: string;
  cover_photo: string;
  description: string;
  duration: number;
  progress: number;
  program: ProgramReadOnly;
  chapter: ChapterReadOnly;
  number?: number;
}

export interface CurrentEpisode {
  id: number;
  title: string;
  number: number;
  duration: number;
  progress: number;
  progress_changed: string;
  chapter_number: number;
  next: CurrentEpisode;
}

export interface Author {
  photo: string;
  full_name: string;
  description: string;
}

export interface EpisodeDetail {
  id: number;
  audio?: string;
  is_bookmarked: boolean;
  whatched_video: boolean;
  completed_assignment: boolean;
  comments_count: number;
  chapter: ChapterReadOnly;
  program: ProgramReadOnly;
  title: string;
  extra_title: string;
  description: string;
  video: string;
  reactions_count: number;
  workbook: string;
  workbook_name: string;
  number: number;
  progress: number;
  access_status: string;
  cover_photo: string;
  is_sample: boolean;
  sample?: string;
  sample_start?: number;
  sample_end?: number;
  srt_file: string;
  duration: number;
  video_hls: string;
  my_reactions_count: number;
  last_reactions: User[];
}

export const emptyEpisode: EpisodeDetail = {
  id: 0,
  is_bookmarked: false,
  last_reactions: [],
  whatched_video: false,
  completed_assignment: false,
  comments_count: 0,
  progress: 0,
  chapter: emptyChapter,
  program: emptyProgram,
  title: '',
  extra_title: '',
  description: '',
  video: '',
  reactions_count: 0,
  workbook: '',
  workbook_name: '',
  number: 0,
  access_status: '',
  cover_photo: '',
  srt_file: '',
  is_sample: false,
  duration: 0,
  video_hls: '',
  my_reactions_count: 0,
};

export interface EpisodeBookmark {
  id: number;
  chapter: string;
  program: string;
  episode: EpisodeDetail;
  mongo_id: string;
  created_at: string;
  modified_at: string;
  title: string;
  user: number;
}

export interface GlobalSearch {
  programs: ProgramReadOnly[];
  chapters: ChapterReadOnly[];
  episodes: EpisodeReadOnly[];
}

export interface Bookmark {
  id: number;
  chapter: string;
  program: string;
  episode: EpisodeReadOnly;
  created_at: string;
  modified_at: string;
  title: string;
  user: number;
}

export interface IChapter {
  cover_photo: string;
  description: string;
  extra_title: string;
  id: number;
  title: string;
}

export interface BookmarkSaved {
  id: number;
  chapter: IChapter;
  program: ProgramReadOnly;
  episode: EpisodeReadOnly;
  created_at: string;
  modified_at: string;
  title: string;
  user: number;
  show?: boolean;
}

export interface BookmarkProps {
  [key: number]: BookmarkItem;
}

export interface BookmarkItem {
  id: number;
  episode: EpisodeReadOnly[];
  chapter: IChapter;
  program: ProgramReadOnly;
}

export interface Comment {
  id: number;
  text: string;
  author: {
    id: number;
    photo: string;
    full_name: string;
    description: string;
    first_name: string;
    last_name: string;
    email: string;
    is_staff: boolean;
    groups: string[];
  };
  tag: string;
  last_reply: any;
  created_at: string;
  children_count: number;
  media: any[];
  is_liked: boolean;
  reactions_count: number;
  text_translations: any;
  episode: {
    id: number;
    title: string;
    extra_title: string;
  };
  chapter: {
    id: number;
    title: string;
    extra_title?: string;
    description?: string;
    cover_photo?: string;
  };
  program: {
    id: number;
    title: string;
    badge_image?: string;
    cover_photo?: string;
    payment_type?: string;
    language?: string;
    access_status?: string;
    description?: string;
  };
  relevant: boolean;
}
