import { useIntl } from 'estafette-intl';
import dayjs from 'dayjs';

export const useTimeUtils = () => {
  const { t } = useIntl();

  const getDiffFromToday = (date: string) => {
    const now = dayjs();
    const timeToDecrease = dayjs(date);

    const year = now.diff(timeToDecrease, 'year');
    if (year > 0) {
      return t(year === 1 ? 'aboutAYear' : 'years', { count: year.toString() });
    }

    const month = now.diff(timeToDecrease, 'month');
    if (month > 0) {
      return t(month === 1 ? 'aboutAMonth' : 'months', { count: month.toString() });
    }

    const day = now.diff(timeToDecrease, 'day');
    if (day > 0) {
      return t(day === 1 ? 'aDay' : 'days', { count: day.toString() });
    }

    const hour = now.diff(timeToDecrease, 'hour');
    if (hour > 0) {
      return t(hour === 1 ? 'aboutAnHour' : 'hours', { count: hour.toString() });
    }

    const minute = now.diff(timeToDecrease, 'minute');
    if (minute > 0) {
      return t(minute === 1 ? 'aboutAMinute' : 'minutes', { min: minute.toString() });
    }

    const second = now.diff(timeToDecrease, 'second');
    if (second >= 0) {
      return t(second <= 1 ? 'aboutASecond' : 'seconds_ago', { sec: second.toString() });
    }
  };

  const getTimeFrom = (duration: number, withSeconds = false) => {
    const hours = Math.floor(duration / (60 * 60));
    const minutes = Math.floor((duration / 60) % 60);
    const seconds = Math.floor(duration % 60);

    if (withSeconds) {
      return t('minutes_seconds', { min: minutes.toString(), sec: seconds.toString() });
    } else {
      return t('hours_minutes', { hours: hours.toString(), min: minutes.toString() });
    }
  };

  return {
    getDiffFromToday,
    getTimeFrom,
  };
};
