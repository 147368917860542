import { FC } from 'react';
import { Icon } from 'ui/atoms/Icon/Icon';

import './NotFoundPage.scss';

export const NotFoundPage: FC = () => (
  <div className="app-404">
    <div className="app-404-right">
      <div className="app-404-img" />
      <Icon type="404" />
    </div>
    <div className="app-404-left">
      <h1>404</h1>
      <h3>Sorry, the page you visited does not exist</h3>
    </div>
  </div>
);
