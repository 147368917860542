import { FC, useContext } from 'react';
import { Button, Form, Input, Loader, useForm, useNotify, Label, Space } from 'ebs-design';
import { useIntl } from 'estafette-intl';
import { Link } from 'react-router-dom';
import { useStateHandlers } from 'hooks';
import { UserContext } from 'contexts/UserContext';
import { HeadTitle } from 'ui/atoms';
import { PasswordInput } from 'ui/molecules/PasswordInput';
import { users } from 'libs/http/api';
import { getRoute, setFieldsError } from 'libs/utils';
import { setTokens } from 'libs/cookies';
import { breakpoints } from 'libs/globalVars';
import { thisDeviceProps } from 'contexts/DevicesContext';
import { ResponsiveContext } from 'contexts/ResponsiveContext';

export const SignInPage: FC = () => {
  const { onFetchUserData } = useContext(UserContext);
  const { width } = useContext(ResponsiveContext);

  const { t } = useIntl();
  const notify = useNotify();
  const [form] = useForm();

  const [state, setState] = useStateHandlers<{ loading: boolean; errors: string }>({
    loading: false,
    errors: '',
  });

  const onFinish = (values: any) => {
    setState({ loading: true });

    users.signIn
      .action({
        ...values,
        ...thisDeviceProps,
      })
      .then(async ({ data }) => {
        setTokens({ token: data.access, refresh: data.refresh });
        await users.activateDevice.action({ device_id: thisDeviceProps.device_id });
        await onFetchUserData();
      })
      .catch(({ response }: any) => {
        if (response.data && !response.data.detail) {
          setFieldsError(response.data, form);
        }
        if (response.data.detail) {
          notify.error({ title: 'Error', description: response.data.detail });
        }
        if (response.data.non_field_errors) {
          setState({ errors: response.data.non_field_errors[0] });
          notify.error({ title: 'Error', description: response.data.non_field_errors[0] });
        }
      })
      .finally(() => setState({ loading: false }));
  };

  return (
    <div className="sign-in-page">
      <HeadTitle className="sign-in-page__head-title">
        {t(width < breakpoints.sm ? 'existingClient' : 'logInHeader')}
      </HeadTitle>
      <Loader loading={state.loading}>
        <Form form={form} onFinish={onFinish}>
          <div className="d-flex justify-content--center">
            <Label status="danger" type="ghost" text={state.errors} />
          </div>
          <Form.Field
            label={t('email_notif')}
            name="email"
            className="sign-in-page__form-field"
            rules={[
              {
                type: 'email',
                message: t('invalid_email'),
              },
              {
                required: true,
                message: t('this_email_not_exists'),
              },
            ]}
          >
            <Input placeholder={t('email_notif')} size="large" />
          </Form.Field>
          <Form.Field
            label={
              <Space justify="space-between" className="sign-in-page__form-field__extra">
                <div>
                  {t('Password')}
                  <span className="ebs-form__field__required">*</span>
                </div>
                <div>
                  <Link to={getRoute('RequestRestorePasswordPage')}>{t('ForgotPassword')}</Link>
                </div>
              </Space>
            }
            name="password"
            className="sign-in-page__form-field"
            rules={[
              {
                required: true,
                message: t('pass_minim_8_chars'),
              },
              {
                min: 8,
                message: t('pass_minim_8_chars'),
              },
            ]}
          >
            <PasswordInput placeholder={t('Password')} size="large" />
          </Form.Field>
          <div className="sign-in-page__form-footer">
            <Button type="primary" submit>
              {t('Continue')}
            </Button>
          </div>
        </Form>
      </Loader>
    </div>
  );
};
