import React, { useEffect, useState } from 'react';
import { useIntl } from 'estafette-intl';
import cn from 'classnames';
import { ConfirmModal } from 'ui/molecules/ConfirmModal/ConfirmModal';
import { EpisodeDetail } from 'libs/http/api/education/education.types';
import { education } from 'libs/http/api/education/education';
import { Checked, DownloadFile, UnChecked } from 'resources';

interface Props {
  episode: EpisodeDetail;
}

export const Resources = ({ episode }: Props) => {
  const { t } = useIntl();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [completed_assignment, setCompletedAssignment] = useState(episode.completed_assignment || false);

  useEffect(() => {
    setCompletedAssignment(episode.completed_assignment);
  }, [episode.id]);

  const handleComplete = () => {
    education.setTodo.action(Number(episode.id), { completed_assignment: true }).then(() => {
      setCompletedAssignment(true);
      setOpenConfirm(false);
    });
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  return (
    <div className="resources">
      {openConfirm && <ConfirmModal handleToggle={() => setOpenConfirm((s) => !s)} handleOk={handleComplete} />}
      {episode.workbook && episode.workbook_name && (
        <>
          <div className="resources__title">{t('practice')}</div>
          <div className="resources__item">
            <div className="resources__item__files">
              <DownloadFile />
              <a href={episode.workbook} download target="_blank" rel="noreferrer">
                {episode.workbook_name}
              </a>
            </div>
          </div>
        </>
      )}
      <div className="resources__title">{t('todo')}</div>
      <div className="resources__item">
        <div className={cn('resources__item__tasks', { done: episode.whatched_video })}>
          {episode.whatched_video ? <Checked /> : <UnChecked />}
          <div>{t('i_watched_video')}</div>
        </div>
      </div>
      <div className="resources__item">
        <div className={cn('resources__item__tasks', { done: completed_assignment })}>
          {completed_assignment ? <Checked /> : <UnChecked className="cursor-pointer" onClick={handleOpenConfirm} />}
          <div>{t('i_completed_assignment')}</div>
        </div>
      </div>
    </div>
  );
};
