import { useContext, useEffect, useMemo } from 'react';
import { useRequest } from 'estafette';
import dayjs from 'dayjs';
import { load } from 'react-cookies';
import { useIntl } from 'estafette-intl';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Space, Tabs } from 'ebs-design';
import WatchLayout from '../components/organisms/WatchLayout/WatchLayout';
import { Chapters } from '../components/molecules/Chapters/Chapters';
import { Overview } from '../components/molecules/Overview/Overview';
import { ReviewsTab } from '../components/molecules/ReviewsTab/ReviewsTab';
import { Reviews } from '../components/organisms/Reviews/Reviews';
import { SampleAudio } from 'ui/organisms/SampleAudio/SampleAudio';
import { BackBreadCrumbs, Breadcrumbs, HeadTitle } from 'ui/atoms';
import { BreadcrumbsContext } from 'contexts/BreadcrumbsContext';
import { ResponsiveContext } from 'contexts/ResponsiveContext';
import { UserContext } from 'contexts/UserContext';
import { getRoute, getSubscribers } from 'libs/utils';
import { breakpoints } from 'libs/globalVars';
import { StorageKeys } from 'libs/cookies';
import { ProgramReadOnly, ProgramType } from 'libs/http/api/education/education.types';
import { education } from 'libs/http/api/education/education';
import { useStateHandlers } from 'hooks';
import { ApprovedUser, Cup, Play, Star } from 'resources';

export const WatchProgramPage = () => {
  const { t } = useIntl();
  const { setBreadcrumbs, getFirstBreadcrumbItem } = useContext(BreadcrumbsContext);
  const { width } = useContext(ResponsiveContext);
  const { programId } = useParams<{ programId: string }>();
  const { userData, logged } = useContext(UserContext);
  const { push } = useHistory();
  const token = load(StorageKeys.jwtToken);

  const programRequest = useRequest<ProgramReadOnly>({ data: {} });
  const lastEpisodeRequest = useRequest<any>({ data: {} });

  useEffect(() => {
    if (programId) {
      programRequest.request(education.getProgramDetails.action(parseInt(programId)));
      lastEpisodeRequest.request(education.getEpisodeByProgress.action({ program: programId }));
    }
  }, [programId]);

  const [state, setState] = useStateHandlers({
    activeTab: width < breakpoints.desktop + 1 ? 'content' : 'overview',
    refreshReviews: false,
  });

  const [program, lastEpisode] = useMemo(
    () => [
      programRequest.data,

      Math.ceil(
        ((programRequest.data.current_episode?.duration || 0) - (programRequest.data.current_episode?.progress || 0)) /
          60,
      )
        ? programRequest.data.current_episode
        : programRequest.data.current_episode?.next || lastEpisodeRequest.data,
    ],
    [programRequest, lastEpisodeRequest],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setBreadcrumbs([
      getFirstBreadcrumbItem(program),
      { label: program.title, route: 'WatchProgramPage', params: { programId: program.id } },
    ]);
  }, [program]);

  const setActiveTab = (tab: string) => setState({ activeTab: tab });
  const afterReviewsRefreshCallback = () => setState({ refreshReviews: false });
  const beforeReviewsRefreshCallback = () => setState({ refreshReviews: true });

  const tabs = useMemo(() => {
    const initialTabs = [
      {
        label: <span>{t('overview')}</span>,
        key: 'overview',
        content: <Overview program={program} />,
      },
    ];

    if (program.show_reviews) {
      initialTabs.push({
        label: <span>{t('rating_reviews')} </span>,
        key: 'reviews',
        content: (
          <>
            <ReviewsTab program={program} onSubmit={beforeReviewsRefreshCallback} />
            <Reviews refresh={state.refreshReviews} afterRefreshCallback={afterReviewsRefreshCallback} />
          </>
        ),
      });
    }

    if (width < breakpoints.desktop + 1) {
      initialTabs.unshift({
        label: <span>Content</span>,
        key: 'content',
        content: <Chapters program={program} nextEpisode={lastEpisode} />,
      });
    }

    return initialTabs;
  }, [program, width]);

  const onBack = () => {
    push(getRoute(userData?.id ? 'MyProgramsPage' : 'OtherProgramsPage'));
  };

  const statistics = useMemo(
    () =>
      program.reviews_statistic && (
        <Space className="watch-layout__statistics" align="start" size={0}>
          {Boolean(program.reviews_statistic?.average) && (
            <div className="watch-layout__statistics__star">
              <Star />
              {program.reviews_statistic.average}
            </div>
          )}
          {Boolean(program.reviews_statistic?.users) && (
            <div className="watch-layout__statistics__approved-user">
              <ApprovedUser />
              {getSubscribers(program.subscriptions_count)}
            </div>
          )}
          {Boolean(program.diploma_gift) && (
            <div className="watch-layout__statistics__cup">
              <Cup />
              {t('certificate_completion')}
            </div>
          )}
        </Space>
      ),
    [program],
  );

  const handleSubScribeFree = () => {
    if (program.payment_type === 'free' && !program.program_subscription) {
      education.subscribeFree.action(program.id);
    }
  };

  return (
    <WatchLayout sideContent={<Chapters program={program} nextEpisode={lastEpisode} />}>
      <BackBreadCrumbs onBack={onBack} />
      <Space justify="space-between" className="watch-layout__mobile-hide">
        <Breadcrumbs />
        {statistics}
      </Space>
      <div className="watch-layout__image">
        <img src={program.cover_photo} alt={program.title} />

        <div className="watch-layout__image__layer" />

        <div className="watch-layout__image__content-mobile">
          {program.access_status === 'subscribed' &&
          ((program.program_subscription && !program.program_subscription.payment_failed) ||
            program.payment_type === 'free') ? (
            <>
              <Link
                className="watch-layout__image__mobile-play-button"
                to={getRoute('WatchEpisodePage', { programId: program.id, episodeId: lastEpisode.id })}
              >
                <Play />
              </Link>
              <div className="watch-layout__image__content-continue">{t('continueWatch')}</div>
              <div className="watch-layout__image__content-title">{program.current_episode?.title}</div>
              {program.current_episode?.chapter_number && program.current_episode?.number && (
                <div className="watch-layout__image__content-continue">
                  {t('chapter')} {program.current_episode?.chapter_number}, {t('episode')}{' '}
                  {program.current_episode?.number}
                </div>
              )}
            </>
          ) : (
            Boolean(program.sales_page) && (
              <>
                <a
                  href={`${program.sales_page}${
                    token ? `${program.sales_page?.includes('?') ? '&' : '?'}token=${token}` : ''
                  }`}
                  className="watch-layout__image__mobile-play-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Play />
                </a>
                <div className="watch-layout__image__content-continue">{t('unlock_now')}</div>
                <div className="watch-layout__image__content-title">{program.current_episode?.title}</div>
              </>
            )
          )}
        </div>

        <div className="watch-layout__image__content">
          <div className="watch-layout__image__content-title">{program.title}</div>
          {program.motto && <div className="watch-layout__image__content-description">{program.motto}</div>}
          {program.author && (
            <div className="watch-layout__image__content-author">
              <div>
                {t('created_by')} <span>{program.author.full_name}</span>
              </div>
              <div className="watch-layout__image__content-author-photo">
                <img src={program.author.photo} alt={program.author.full_name} />
              </div>
            </div>
          )}
          <div className="watch-layout__image__content-button-wrapper">
            {program.access_status === 'subscribed' &&
            ((program.program_subscription && !program.program_subscription.payment_failed) ||
              program.payment_type === 'free') ? (
              <Link
                to={getRoute('WatchEpisodePage', { programId: program.id, episodeId: lastEpisode.id })}
                onClick={handleSubScribeFree}
              >
                <Button type="primary" className="watch-layout__image__content-button">
                  {program.progress < program.episodes_duration && program.progress
                    ? t('continueWatch')
                    : program.progress === 0
                    ? t('Start')
                    : t('startAgain')}
                </Button>
              </Link>
            ) : (
              Boolean(program.sales_page) && (
                <a
                  href={`${program.sales_page}${
                    token ? `${program.sales_page?.includes('?') ? '&' : '?'}token=${token}` : ''
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button type="primary" className="watch-layout__image__content-button">
                    {t('unlock_now')}
                  </Button>
                </a>
              )
            )}
          </div>
          {program.program_subscription && program.access_status === 'subscribed' && (
            <div className="m-hover-mid-text mt-20">
              {t('startedOn', {
                date: dayjs(program.program_subscription.subscription_date).format('MMM. DD, YYYY'),
              })}
            </div>
          )}
        </div>
      </div>
      <div className="watch-layout__under-tabs-mobile">
        <HeadTitle className="watch-layout__under-tabs-mobile__title">{program.title}</HeadTitle>
        {statistics}
        <div className="watch-layout__under-tabs-mobile__description">{program.description}</div>
      </div>
      {program.program_type === ProgramType.AUDIO_COURSE && program.sample_episode?.sample && !logged && (
        <SampleAudio program={program} />
      )}
      <div className="watch-layout__tabs">
        <Tabs activeTab={state.activeTab} setActiveTab={setActiveTab}>
          {tabs.map((item) => (
            <Tabs.Tab {...item} tabKey={item.key} />
          ))}
          {tabs.map((item) => (
            <Tabs.Panel key={item.key} tabKey={item.key}>
              {item.content}
            </Tabs.Panel>
          ))}
        </Tabs>
      </div>
    </WatchLayout>
  );
};
