import { ChangeEvent } from 'react';
import { Col, Row } from 'ebs-design';

interface Props {
  progress: number;
  onChange: (value: number) => void;
}

export const VideoProgressBar = ({ progress, onChange }: Props) => {
  const onProgressChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(Number(target.value));
    }
  };

  return (
    <Row className="p-0">
      <Col className="m-video-progress-bar p-0">
        <Row className="p-0">
          <Col className="p-0">
            <div className="m-bar-container">
              <div className="m-bar-outer">
                <div className="m-bar-inner">
                  <div className="m-bar-progress" style={{ width: `${progress}%` }} />
                  <input type="range" className="m-progress-input" value={progress} onChange={onProgressChange} />
                  <div className="m-progress-icon" style={{ left: `calc(${progress}% - 10px)` }} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
