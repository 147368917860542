import React, { useEffect } from 'react';
import { useIntl } from 'estafette-intl';
import { Loader, Icon } from 'ebs-design';
import { useRequest } from 'estafette';
import { education } from 'libs/http/api/education/education';
import { WithPagination } from 'libs/http/api/index.types';
import { BookmarkProps, BookmarkSaved, EpisodeReadOnly } from 'libs/http/api/education/education.types';
import { useStateHandlers } from 'hooks';
import { Placeholder } from 'ui/atoms/Placeholder/Placeholder';
import { CollapseProgramCard } from 'ui/organisms/CollpaseProgramCard/CollapseProgramCard';
import { Container, HeadTitle } from 'ui/atoms';

const removeBookmark = (bookmarks: BookmarkProps, id: number) => {
  let response: BookmarkProps = {};
  Object.values(bookmarks).forEach((i) => {
    if (i.episode.find((ii: EpisodeReadOnly) => ii.id === id)) {
      response = {
        [i.chapter.id]: {
          ...i,
          episode: i.episode.filter((ii: EpisodeReadOnly) => {
            return ii.id !== id;
          }),
        },
      };
    }
  });
  return response;
};

export const MySavedPrograms = () => {
  const { t, locale } = useIntl();
  const [bookmarksState, setBookmarks] = useStateHandlers<BookmarkProps>({});

  const bookmarks = useRequest<WithPagination<BookmarkSaved>>({ data: { results: [] } });

  useEffect(() => {
    bookmarks.request(education.getSavedBookmarks.action());
  }, []);

  useEffect(() => {
    bookmarks.data.results.forEach((i) => {
      setBookmarks((prev) => ({
        ...prev,
        [i.chapter.id]: {
          id: i.id,
          episode: prev[i.chapter.id] ? [...prev[i.chapter.id].episode, i.episode] : [i.episode],
          chapter: i.chapter,
          program: i.program,
        },
      }));
    });
  }, [bookmarks.data.results]);

  const handleReFetchBookmarks = (id: number) => {
    setBookmarks((prev) => ({ ...prev, ...removeBookmark(prev, id) }));
  };

  return (
    <Container className="my-program">
      <Loader loading={bookmarks.loading} height="500px">
        <HeadTitle>{t('saved_episodes')}</HeadTitle>
        {Object.keys(bookmarksState).length > 0 ? (
          Object.values(bookmarksState)
            .filter((i) => i.program.language === locale)
            .map((i, k) => {
              if (i.episode.length > 0) {
                return <CollapseProgramCard key={k} bookmark={i} reFetch={handleReFetchBookmarks} />;
              } else {
                return null;
              }
            })
        ) : (
          <Placeholder icon={<Icon type="no-notifications" />} text={t('havent_saved_episodes')} />
        )}
      </Loader>
    </Container>
  );
};
