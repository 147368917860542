import { FC, ReactNode, useContext, useEffect } from 'react';
import cn from 'classnames';
import { Loader, Space } from 'ebs-design';
import { CommunityContext } from 'contexts/CommunityContext';
import { ResponsiveContext } from 'contexts/ResponsiveContext';
import { breakpoints } from 'libs/globalVars';
import { StylesContext } from 'contexts/StylesContext';
import { Container } from 'ui/atoms';
import { setHTMLClassName } from 'libs/utils';

interface Props {
  HTMLClassName?: string;
  sideContent?: ReactNode;
  className?: string;
}

const WatchLayout: FC<Props> = ({ children, HTMLClassName, sideContent, className }) => {
  const { loading } = useContext(CommunityContext);
  const { fullWindowClass } = useContext(StylesContext);
  const { width } = useContext(ResponsiveContext);

  useEffect(() => {
    setHTMLClassName(['watch-page', HTMLClassName].filter((i) => i).join(' '));

    return () => {
      setHTMLClassName('');
    };
  }, [HTMLClassName]);

  return (
    <Loader loading={loading}>
      <Container className={cn('watch-layout', className)}>
        <Space justify="space-between" className="watch-layout__space" size={54} align="start">
          <div className="watch-layout__body">{children}</div>
          {sideContent && width > breakpoints.desktop && (
            <div className={cn('watch-layout__left-side', fullWindowClass)}>{sideContent}</div>
          )}
        </Space>
      </Container>
    </Loader>
  );
};

export default WatchLayout;
