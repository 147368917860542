import React, { useContext, useEffect } from 'react';
import { Loader } from 'ebs-design';
import { useRequest } from 'estafette';
import { useParams } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { ProgramsCarousel } from 'features/programs/components/molecules/ProgramsCarousel/ProgramsCarousel';
import { EventsCarousel } from 'features/programs/components/molecules/EventsCarousel/EventsCarousel';
import { BackBreadCrumbs, Container, HeadTitle } from 'ui/atoms';
import { education } from 'libs/http/api/education/education';
import { GlobalSearch } from 'libs/http/api/education/education.types';
import { WithData } from 'libs/http/api/index.types';
import { Webinar } from 'libs/http/api/events/events.types';
import { events } from 'libs/http/api/events/events';
import { ChapterBlock } from '../../components/organisms/ChapterBlock/ChapterBlock';
import { EpisodeBlock } from '../../components/organisms/EpisodeBlock/EpisodeBlock';
import { country } from 'libs/globalVars';
import { UserContext } from 'contexts/UserContext';

export const SearchPage: React.FC = () => {
  const { t, locale } = useIntl();
  const { userData } = useContext(UserContext);
  const { search = '' } = useParams<{ search?: string }>();

  const searchResults = useRequest<GlobalSearch>({
    data: {
      programs: [],
      episodes: [],
      chapters: [],
    },
  });

  const webinars = useRequest<WithData<Webinar>>({ data: {} });

  useEffect(() => {
    searchResults.request(education.search.action({ search }));
    webinars.request(
      events.webinar.action({
        search,
        country:
          country[
            (userData.language as 'en' | 'en-us' | 'ru' | 'pl' | 'ro' | 'de' | 'uk') ||
              (locale as 'en' | 'en-us' | 'ru' | 'pl' | 'ro' | 'de' | 'uk')
          ],
      }),
    );
  }, [search]);

  return (
    <Loader loading={searchResults.loading || webinars.loading}>
      <Container className="search-page">
        <BackBreadCrumbs />
        <HeadTitle className="search-page__title">{t('searchPageTitle', { search })}</HeadTitle>
        <>
          {Boolean(searchResults.data.programs?.length) && (
            <>
              <HeadTitle className="search-page__mini-title">{t('programs')}</HeadTitle>
              <ProgramsCarousel programs={searchResults.data.programs} />
            </>
          )}
          {Boolean(searchResults.data.chapters?.length) && (
            <>
              <HeadTitle className="search-page__mini-title">{t('chapters')}</HeadTitle>
              {searchResults.data.chapters?.map((i, k) => (
                <ChapterBlock key={`chapter-${k}`} chapter={i} />
              ))}
            </>
          )}
          {Boolean(searchResults.data.episodes?.length) && (
            <>
              <HeadTitle className="search-page__mini-title">{t('episodes')}</HeadTitle>
              {searchResults.data.episodes?.map((i, k) => (
                <EpisodeBlock key={`episode-${k}`} episode={i} />
              ))}
            </>
          )}
          {webinars.data?.data?.length && (
            <div className="mb-20">
              <HeadTitle className="search-page__mini-title">{t('events')}</HeadTitle>
              <EventsCarousel webinars={webinars.data.data} />
            </div>
          )}
        </>
      </Container>
    </Loader>
  );
};
