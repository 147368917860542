import { FC, useContext, useRef } from 'react';
import { Row, Col } from 'ebs-design';
import { Icon } from 'ui/atoms/Icon/Icon';
import { useOutsideAlerter } from 'hooks';
import { CommunityContext } from 'contexts/CommunityContext';

export const SideNav: FC = ({ children }) => {
  const { sideNav, onSideNavChange } = useContext(CommunityContext);

  const wrapperRef = useRef(null);

  const onSideNavClose = () => onSideNavChange('');

  useOutsideAlerter(wrapperRef, onSideNavClose);

  return (
    <>
      {sideNav && (
        <div ref={wrapperRef}>
          <Row className="m-side-nav">
            <Col className="m-side-nav-content active p-0" size={11} sm={6} xxl={4}>
              <Row className="justify-content--end mt-30">
                <Col size="auto" className="m-side-nav-close">
                  <Icon type="close" onClick={onSideNavClose} />
                </Col>
              </Row>

              <div className="m-side-bar-divider" />

              {children}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
