import React, { useContext, useEffect, useMemo } from 'react';
import { Col, Loader, Row, Space, Tabs, Tooltip, useNotify } from 'ebs-design';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useRequest } from 'estafette';
import cn from 'classnames';
import { Resources } from '../components/molecules/Resources/Resources';
import WatchLayout from '../components/organisms/WatchLayout/WatchLayout';
import { Chapters } from '../components/molecules/Chapters/Chapters';
import { Comments } from '../components/molecules/Comments/Comments';
import { Icon } from 'ui/atoms/Icon/Icon';
import VideoPlayer from 'ui/molecules/VideoPlayer/VideoPlayer';
import { BackBreadCrumbs, Breadcrumbs, HeadTitle } from 'ui/atoms';
import { Counter } from 'ui/molecules/Counter/Counter';
import { Attending } from 'ui/molecules/Attending/Attending';
import { AudioPlayer } from 'ui/organisms/AudioPlayer/AudioPlayer';
import { useIntl } from 'hooks/useIntl';
import { EpisodeDetail, ProgramReadOnly, ProgramType } from 'libs/http/api/education/education.types';
import { education } from 'libs/http/api/education/education';
import { getRoute } from 'libs/utils';
import { breakpoints } from 'libs/globalVars';
import { ResponsiveContext } from 'contexts/ResponsiveContext';
import { BreadcrumbsContext } from 'contexts/BreadcrumbsContext';
import { UserContext } from 'contexts/UserContext';
import { useCookies, useStateHandlers } from 'hooks';
import { ArrowLeft, ArrowRight, Bookmark, Chat, ClapClap, PaperAirPlane } from 'resources';

export const WatchEpisodePage = () => {
  const { userData } = useContext(UserContext);
  const { setBreadcrumbs, getFirstBreadcrumbItem } = useContext(BreadcrumbsContext);
  const { width } = useContext(ResponsiveContext);
  const { episodeId } = useParams<{ episodeId: string }>();
  const { push } = useHistory();
  const notify = useNotify();
  const { t } = useIntl();

  const programRequest = useRequest<ProgramReadOnly>();
  const nextEpisode = useRequest<EpisodeDetail>();
  const previousEpisode = useRequest<EpisodeDetail>();
  const episodeRequest = useRequest<EpisodeDetail>();

  const [state, setState] = useStateHandlers({
    activeTab: width < breakpoints.desktop + 1 ? 'content' : 'comments',
    claps: 0,
    isBookmark: false,
    videoFile: '',
    initialized: false,
    comments_count: 0,
  });

  const { storage, onStorageChange } = useCookies<{
    mutedByDefault: boolean;
    autoPlayNext: boolean;
    subtitles: 'showing' | 'hidden';
  }>(
    {
      mutedByDefault: false,
      autoPlayNext: true,
      subtitles: 'hidden',
    },
    'watch-video-settings',
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (episodeId) {
      episodeRequest.request(education.getEpisode.action(parseInt(episodeId)));
      nextEpisode.request(education.getNextEpisode.action(parseInt(episodeId)));
      previousEpisode.request(education.getPreviousEpisode.action(parseInt(episodeId)));
    }
  }, [episodeId]);

  useEffect(() => {
    if (episodeRequest.data?.program?.id) {
      programRequest.request(education.getProgramDetails.action(episodeRequest.data.program.id));
      if (episodeRequest.data.access_status !== 'subscribed') {
        push(getRoute('WatchProgramPage', { programId: episodeRequest.data.program.id }));
      }
    }

    setState({
      claps: episodeRequest.data ? episodeRequest.data.reactions_count : 0,
      isBookmark: episodeRequest.data ? episodeRequest.data.is_bookmarked : false,
      comments_count: episodeRequest.data ? episodeRequest.data.comments_count : 0,
    });
  }, [episodeRequest.data]);

  useEffect(() => {
    if (!programRequest.data.id || !episodeRequest.data.id) {
      return;
    }
    setBreadcrumbs([
      getFirstBreadcrumbItem(programRequest.data),
      {
        label: episodeRequest.data?.program?.title,
        route: 'WatchProgramPage',
        params: { programId: programRequest.data.id },
      },
      {
        label: episodeRequest.data?.title,
      },
    ]);
  }, [programRequest.data, episodeRequest.data]);

  const increaseCommentCount = () => {
    setState((prev) => ({ comments_count: prev.comments_count + 1 }));
  };

  const tabs = useMemo(() => {
    const initialTabs = [
      {
        label: (
          <Space justify="center" size={4}>
            <span>{t(programRequest.data?.comments_type_qa ? 'q&a' : 'comments')}</span>
            {Boolean(state.comments_count) && <span className="tab-badge">{state.comments_count}</span>}
          </Space>
        ),
        key: 'comments',
        content: (
          <Comments
            isQA={programRequest.data?.comments_type_qa}
            episode={episodeRequest.data}
            increaseCommentCount={increaseCommentCount}
            comments_count={state.comments_count}
          />
        ),
      },
      {
        label: <span>{t('tasks')}</span>,
        key: 'resources',
        content: <Resources episode={episodeRequest.data} />,
      },
    ];

    if (width < breakpoints.desktop + 1) {
      initialTabs.unshift({
        label: <span>Content</span>,
        key: 'content',
        content: <Chapters program={programRequest.data} nextEpisode={episodeRequest.data} />,
      });
    }

    return initialTabs;
  }, [programRequest.data, episodeRequest.data, width, state]);

  const onInitializedPlayer = () => {
    setState({ initialized: true });
  };

  const onBookmarkAdd = () => {
    if (episodeRequest.data.is_bookmarked) {
      return education.deleteBookmark.action(episodeRequest.data.id).then(() => {
        episodeRequest.setData((prevState: EpisodeDetail) => ({ ...prevState, is_bookmarked: false }));
        notify.success({ title: t('success'), description: t('removed_bookmark') });
      });
    } else {
      try {
        education.addBookmark.action(Number(episodeId)).then(({ data }) => {
          if (data.episode) {
            episodeRequest.setData((prevState: EpisodeDetail) => ({ ...prevState, ...data.episode }));
          }

          notify.success({ title: t('success'), description: t('added_bookmark') });
        });
      } catch {
        notify.error({ title: t('error'), description: t('something_wrong') });
      }
    }
  };

  const onBack = () => {
    push(getRoute('WatchProgramPage', { programId: episodeRequest.data.program.id }));
  };

  const onClapsAdd = () => {
    setState((prevState) => ({ claps: ++prevState.claps }));

    if (episodeRequest.data.my_reactions_count === 0) {
      episodeRequest.setData((prevState: EpisodeDetail) => ({
        ...prevState,
        my_reactions_count: ++prevState.my_reactions_count,
      }));
    }
  };

  const nextEpisodeLink = useMemo(() => {
    if (programRequest.data.id && nextEpisode.data.id && nextEpisode.data.id !== parseInt(episodeId)) {
      return getRoute('WatchEpisodePage', {
        episodeId: nextEpisode.data.id,
      });
    }

    return;
  }, [programRequest, nextEpisode]);

  const previousEpisodeLink = useMemo(() => {
    if (programRequest.data.id && previousEpisode.data.id) {
      return getRoute('WatchEpisodePage', {
        episodeId: previousEpisode.data.id,
      });
    }

    return;
  }, [programRequest, previousEpisode]);

  const setActiveTab = (tab: string) => setState({ activeTab: tab });

  const onCopyEpisode = () => {
    navigator.clipboard.writeText(`${window.location.href}`).then(
      () => notify.success({ title: t('success'), description: t('copied') }),
      () => notify.success({ title: t('error'), description: t('cant_copy') }),
    );
  };

  const clapsComponents = useMemo(
    () =>
      Boolean(episodeRequest.data.reactions_count || state.claps) && (
        <div className="watch-layout__episode__clap-text">
          <Attending
            users={
              episodeRequest.data.last_reactions
                ? episodeRequest.data.last_reactions.map((i) => ({ avatar: i.photo })) || []
                : [{ avatar: userData.photo || undefined }]
            }
          />
          <span>
            {state.claps} {t('clapsBy')} {episodeRequest.data.last_reactions[0]?.first_name || userData.first_name}{' '}
            {episodeRequest.data.last_reactions.length > 1 && t('andOthers')}
          </span>
        </div>
      ),
    [episodeRequest, state.claps, userData],
  );

  const handleSaveProgress = (progress: number) => {
    education.saveProgress.action(parseInt(episodeId), Math.round(progress));
  };

  return (
    <WatchLayout
      className="episode-layout"
      HTMLClassName="episode-page"
      sideContent={
        /*newProgram.id === program.data.id*/
        Boolean(programRequest.data.id && episodeRequest.data.id) && (
          <Chapters
            className="episode-chapter"
            program={programRequest.data}
            nextEpisode={episodeRequest.data}
            activeChapter={episodeRequest.data.chapter?.id}
          />
        )
      }
    >
      <BackBreadCrumbs onBack={onBack} />
      <Breadcrumbs />

      <div className="watch-layout__header">
        <div>
          <HeadTitle className="watch-layout__header-title">{programRequest.data.title}</HeadTitle>
          <span className="watch-layout__header-subtitle">
            {programRequest.data.author && `${t('created_by')} ${programRequest.data.author.full_name}`}
          </span>
        </div>
        <div className="watch-layout__header-buttons">
          <span className="watch-layout__header-buttons--title">
            {t('lesson')} {episodeRequest.data?.number}
            {!programRequest.data?.is_new &&
              episodeRequest.data.chapter?.number &&
              ` / ${t('chapter')} ${episodeRequest.data.chapter?.number}`}
          </span>
          <Link
            to={previousEpisodeLink || ''}
            className={cn('watch-layout__header-buttons--left', { disabled: !previousEpisodeLink })}
          >
            <ArrowLeft />
          </Link>
          <Link
            to={nextEpisodeLink || ''}
            className={cn('watch-layout__header-buttons--right', {
              disabled: !nextEpisodeLink,
            })}
          >
            <ArrowRight />
          </Link>
        </div>
      </div>
      {programRequest.data.program_type !== ProgramType.AUDIO_COURSE && (
        <div className="watch-layout__video">
          <Loader loading={episodeRequest.loading} height="700px">
            {!episodeRequest.loading && (
              <>
                <VideoPlayer
                  src={episodeRequest.data.video_hls || episodeRequest.data.video}
                  posterUrl={episodeRequest.data.cover_photo}
                  duration={episodeRequest.data.duration}
                  currentTime={
                    episodeRequest.data.duration - episodeRequest.data.progress <= 2 ? 0 : episodeRequest.data.progress
                  }
                  muted={storage.mutedByDefault}
                  autoplay={storage.autoPlayNext}
                  initialized={state.initialized}
                  onInitialized={onInitializedPlayer}
                  fill
                  fluid
                  subtitlesSrc={episodeRequest.data.srt_file}
                  showSubtitles={storage.subtitles}
                  autoPlayNext={storage.autoPlayNext}
                  nextEpisodeLink={nextEpisodeLink}
                  onSettingsChange={onStorageChange}
                />
              </>
            )}
          </Loader>
        </div>
      )}
      {programRequest.data.program_type === ProgramType.AUDIO_COURSE && episodeRequest.data.audio && (
        <div className="watch-layout__audio" style={{ backgroundImage: `url('${episodeRequest.data.cover_photo}')` }}>
          <div className="watch-layout__audio__wrapper">
            <AudioPlayer
              src={episodeRequest.data.audio}
              nextLink={nextEpisodeLink}
              previousLink={previousEpisodeLink}
              onSaveProgress={handleSaveProgress}
              defaultCurrentTime={
                episodeRequest.data.duration - episodeRequest.data.progress <= 2 ? 0 : episodeRequest.data.progress
              }
            />
          </div>
        </div>
      )}
      <div className="watch-layout__episode">
        <HeadTitle className="watch-layout__episode__title">{episodeRequest.data?.title}</HeadTitle>
        <div className="watch-layout__episode__clap-wrapper-mobile">
          {clapsComponents}
          {Boolean(state.comments_count) && (
            <div className="watch-layout__episode__clap-wrapper-mobile__messages">
              {state.comments_count}
              <Chat />
            </div>
          )}
        </div>
        <Space justify="space-between">
          <div className="watch-layout__episode__clap-wrapper">
            <Counter
              count={state.claps}
              episodeId={Number(episodeId)}
              initialValue={episodeRequest.data.reactions_count}
            >
              <div className="watch-layout__episode__clap-cap" onClick={onClapsAdd}>
                <ClapClap />
                <span>{t('clap')}</span>
              </div>
            </Counter>
            {clapsComponents}
          </div>
          <Space className="watch-layout__episode__actions" size={30}>
            {/*<Message className="watch-layout__episode__actions--message" onClick={onCommentOpen} />*/}
            <Tooltip
              placement="top"
              trigger="click"
              tooltip={
                <div className="m-copy-episode">
                  <Row>
                    <Col className="m-copy-link" size={10}>
                      {t('copy_link')}
                    </Col>
                    <Col className="p-0" size={2}>
                      <Icon type="copy-icon" onClick={onCopyEpisode} />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="m-copy-text">{t('episode_will_be_visible_access')}</Col>
                  </Row>
                </div>
              }
            >
              <PaperAirPlane />
            </Tooltip>
            <Bookmark
              className={cn('watch-layout__episode__bookmark', { bookmarked: episodeRequest.data.is_bookmarked })}
              onClick={onBookmarkAdd}
            />
          </Space>
        </Space>
      </div>

      <div className="watch-layout__tabs watch-layout__tabs--border">
        <Tabs activeTab={state.activeTab} setActiveTab={setActiveTab}>
          {tabs.map((item) => (
            <Tabs.Tab {...item} tabKey={item.key} />
          ))}
          {tabs.map((item) => (
            <Tabs.Panel key={item.key} tabKey={item.key}>
              {item.content}
            </Tabs.Panel>
          ))}
        </Tabs>
      </div>
    </WatchLayout>
  );
};
