import React from 'react';
import cn from 'classnames';
import { useIntl } from 'estafette-intl';
import { Button, Textarea, useNotify } from 'ebs-design';
import { Comment as CommentProps } from 'libs/http/api/education/education.types';
import { education } from 'libs/http/api/education/education';
import { useStateHandlers } from 'hooks';

interface Props {
  comment: CommentProps;
  callback: (comment: any) => void;
}

export const EditComment: React.FC<Props> = ({ comment, callback }) => {
  const { t } = useIntl();
  const notify = useNotify();
  const [state, setState] = useStateHandlers({ text: comment.text });
  const setText = (text: string) => setState({ text });
  const handleSubmit = () => {
    if (state.text.length > 0) {
      education.patchComment
        .action(comment.id, {
          text: state.text,
        })
        .then(({ data }) => {
          notify.success({ title: t('success'), description: t('edited_comment') });

          callback(data);
        })
        .catch(() => notify.error({ title: t('error'), description: t('cant_edit_comment') }));
    }
  };

  const handleCancel = () => {
    callback(comment);
  };

  return (
    <div className="edit-comment">
      <Textarea
        className={cn('edit-comment__text-area', { 'has__more-value': state.text?.length > 150 })}
        placeholder={t('add_a_comment_placeholder')}
        value={state.text}
        onChange={setText}
      />
      <div className="edit-comment__action-buttons">
        <Button type="ghost" size="small" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button type="primary" size="small" onClick={handleSubmit}>
          {t('Save')}
        </Button>
      </div>
    </div>
  );
};
