import { Avatar } from 'ebs-design';
import { useIntl } from 'estafette-intl';
import { ProgramReadOnly } from 'libs/http/api/education/education.types';

interface Props {
  program: ProgramReadOnly;
}

export const Author = ({ program }: Props) => {
  const { t } = useIntl();

  if (!program.author) {
    return null;
  }

  return (
    <div className="author">
      <div className="author__label">{t('presentedBy')}</div>
      <div className="author__avatar-data">
        <div className="author__avatar-data__full-name">{program.author.full_name}</div>
        <div className="author__avatar-data__position">{t('parenting_expert')}</div>
        <div className="author__avatar-data__description">
          <div className="author__avatar-data__fake-author" />
          {program.author.description}
        </div>
      </div>
      <Avatar className="author__avatar" circle img={program.author.photo} />
    </div>
  );
};
