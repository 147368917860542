import React, { useCallback, useContext } from 'react';
import { useIntl } from 'estafette-intl';
import { languages } from 'libs/globalVars';
import { HeaderTooltip } from 'ui/molecules/HeaderTooltip/HeaderTooltip';
import { users } from 'libs/http/api';
import { UserContext } from 'contexts/UserContext';
import { CommunityContext } from 'contexts/CommunityContext';
import { setTokens } from 'libs/cookies';
import { User } from 'libs/http/api/users/users.types';
import { EnFlag, Globe, PlFlag, PtFlag, RoFlag, RuFlag } from 'resources';
import { useLocation } from 'react-router-dom';

export const flags: { [key: string]: any } = {
  en: <EnFlag />,
  ro: <RoFlag />,
  ru: <RuFlag />,
  pl: <PlFlag />,
  pt: <PtFlag />,
};

interface Props {
  footer?: boolean;
}

export const LanguagesTooltip: React.FC<Props> = ({ footer = false }) => {
  const { pathname } = useLocation();
  const { logged, setUserData } = useContext(UserContext);
  const { setLoading } = useContext(CommunityContext);

  const { t, locale, setLocale } = useIntl();

  const onLocaleChange = useCallback(async (language: string) => {
    setTokens({ language: language });

    if (logged) {
      await users.update.action({ language });

      setUserData((prevState: User) => ({ ...prevState, language }));
    }

    setLocale(language);
    setLoading(true);
    window.location.href = '/';
  }, []);

  const Flag = flags[locale];

  return (
    <HeaderTooltip
      tooltip={
        <div className="languages">
          {Object.keys(languages)
            .filter((i) => !pathname.includes('q-and-a') || i === 'ro')
            .map((i) => (
              <div
                className={`languages__item ${i === locale ? 'active' : ''}`}
                onClick={() => onLocaleChange(i)}
                key={i}
              >{`${languages[i]} (${t(`${i}_${locale}`)})`}</div>
            ))}
        </div>
      }
    >
      {footer ? (
        <div className="languages-button">
          <Globe />
          {languages[locale]}
        </div>
      ) : (
        <div className="languages-flag">{Flag}</div>
      )}
    </HeaderTooltip>
  );
};
