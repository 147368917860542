import React from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { useIntl } from 'hooks';
import { BackIcon } from 'resources';

interface Props {
  onBack?: () => void;
  className?: string;
}

export const BackBreadCrumbs: React.FC<Props> = ({ className, onBack }) => {
  const { t } = useIntl();
  const { goBack } = useHistory();

  const handleClick = () => {
    if (onBack) {
      onBack();
    } else {
      goBack();
    }
  };

  return (
    <div className={cn('back-bread-crumbs', className)} onClick={handleClick}>
      <BackIcon className="back-bread-crumbs__button" />
      <span className="back-bread-crumbs__text">{t('back')}</span>
    </div>
  );
};
