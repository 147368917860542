import $axios from 'axios';
import { load } from 'react-cookies';
import { defaultLang } from '../globalVars';
import { clearCookies, setTokens } from '../cookies';
import { history } from '../history';
import { users } from './api';

export const axios = $axios.create();
export const cancelToken = $axios.CancelToken;

const pendingRequests: Array<() => void> = [];
let isRefreshingNow: boolean = false;

axios.defaults.headers['Accept-Language'] = defaultLang;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if ($axios.isCancel(error)) {
      return Promise.reject({ cancel: true, message: 'The endpoint was cancelled' });
    }
    if (error.response) {
      if (
        error.response.status === 401 &&
        error.response.data.reason !== 'on_hold' &&
        !error.request.responseURL.includes(process.env.REACT_APP_EVENTS_URL)
      ) {
        if (
          error.response.data &&
          (error.response.data.code === 'token_not_valid' || error.response.data.reason === 'all_logged_out')
        ) {
          return onError();
        }

        const originalRequestRetry = new Promise((resolve) =>
          pendingRequests.push(() => {
            resolve(
              axios({
                ...error.config,
                headers: { ...error.config.headers, Authorization: getAuthorizationToken().authorization },
              }),
            );
          }),
        );

        if (!isRefreshingNow) {
          await onRefreshToken();

          pendingRequests.forEach((callback) => callback());
        }

        return originalRequestRetry;
      }
    }

    return Promise.reject(error);
  },
);

const getAuthorizationToken = () => {
  const token = load('jwt-token');
  const guest = load('guest');

  return { token: token, authorization: `${guest ? 'Guest-' : ''}Token ${token}` };
};

export const axiosHeadersUpdater = (): void => {
  const language = load('localization');
  const { token, authorization } = getAuthorizationToken();

  if (language) {
    axios.defaults.headers['Accept-Language'] = language;
  }

  if (token) {
    axios.defaults.headers.common.Authorization = authorization;
  } else if (axios.defaults.headers.common.Authorization) {
    delete axios.defaults.headers.common.Authorization;
  }
};

export const onRefreshToken = async (): Promise<void> => {
  const refresh = load('jwt-refresh-token');

  isRefreshingNow = true;

  if (refresh) {
    await users.refresh
      .action({ refresh })
      .then(({ data }) => {
        setTokens({ token: data.access });
      })
      .catch(() => onError());
  }

  isRefreshingNow = false;
};

const onError = () => {
  clearCookies();
  history.go(0);
};

axiosHeadersUpdater();
