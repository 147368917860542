import { useIntl as $useIntl } from 'estafette-intl';

import { messages } from 'libs/translations/messages';

export const throwErrorLog = (message: string): void => console.error(message);

export const useIntl = () => {
  const { t, locale, setLocale } = $useIntl();

  const tPlural = (key: string, number: number | string = 0): string => {
    if (locale) {
      const pluralSuffix = new Intl.PluralRules(locale).select(Number(number));

      const $message = messages[`${key}__${pluralSuffix}`][locale];

      return $message.replace('{}', number?.toString());
    }

    return key;
  };

  return { t, tPlural, locale, setLocale };
};
