import { AxiosResponse, Canceler } from 'axios';
import { axios, cancelToken } from '../../axios';
import { WithPagination } from '../index.types';
import { Notification } from './notifications.types';

export const notifications = {
  getNotifications: {
    action: (params = {}): Promise<AxiosResponse<WithPagination<Notification>>> =>
      axios.get(`/notifications/list`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (notifications.getNotifications.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  setRead: {
    action: (id: number): Promise<AxiosResponse<WithPagination<Notification>>> =>
      axios.post(
        `/notifications/read`,
        { notification_id: id },
        {
          cancelToken: new cancelToken((c: Canceler) => (notifications.setRead.cancel = c)),
        },
      ),
    cancel: (() => null) as Canceler,
  },
  setSeen: {
    action: (data: { ids: number[] }): Promise<AxiosResponse<WithPagination<Notification>>> =>
      axios.post(`/notifications/seen`, data, {
        cancelToken: new cancelToken((c: Canceler) => (notifications.setSeen.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
};
