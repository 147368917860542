import React from 'react';

import { NotFoundPage } from './features/notFound/pages/NotFoundPage';
import { HomePage } from './features/home/pages/HomePage';
import { IndexEntry } from './features/index/pages/IndexEntry';
import { WebinarQRCode } from 'features/webinarQrCode/pages/WebinarQRCode';

import { SignInPage } from 'features/account/pages/SignInPage';
import { ResetPasswordPage } from 'features/account/pages/ResetPasswordPage';
import { ConfirmPasswordPage } from './features/account/pages/ConfimPasswordPage';
import { ConfirmAdminInvitePage } from './features/account/pages/ConfirmAdminInvitePage';
import { SetPasswordPage } from 'features/account/pages/SetPasswordPage';

import { MyProgramsPage } from 'features/programs/pages/MyProgramsPage/MyProgramsPage';
import { OtherProgramsPage } from 'features/programs/pages/OtherProgramsPage';
import { EventsPage } from 'features/programs/pages/EventsPage';
import { WatchProgramPage } from 'features/watch/pages/WatchProgramPage';
import { WatchEpisodePage } from 'features/watch/pages/WatchEpisodePage';

import { HelpPage } from 'features/settings/pages/HelpPage';
import { MyOrdersPage } from 'features/settings/pages/MyOrdersPage';
import { SearchPage } from './features/search/pages/SearchPage/SearchPage';
import { MySavedPrograms } from 'features/programs/pages/MySavedPrograms/MySavedPrograms';

import { QA, QADetails } from 'features/qa/pages';

interface RouteConfig {
  name: string;
  path: string;
  component: React.ComponentType;
  exact: boolean;
  suspense?: string;
  parent?: string;
  withOutLayout?: boolean;
}

export const publicRoutes: RouteConfig[] = [
  { name: 'IndexEntry', path: '/redirect', component: IndexEntry, suspense: 'index', exact: true, parent: '' },
  {
    name: 'WebinarQrCode',
    path: '/webinar-qr-code',
    component: WebinarQRCode,
    suspense: 'webinarQrCode',
    exact: true,
    parent: '',
    withOutLayout: true,
  },

  { name: 'Home', path: '/', component: HomePage, suspense: 'home', exact: true },

  { name: 'NotFoundPage', path: '/404', component: NotFoundPage, suspense: 'notFound', exact: true },

  { name: 'SignInPage', path: '/account/sign-in', component: SignInPage, suspense: 'account', exact: true },
  {
    name: 'RequestRestorePasswordPage',
    path: '/account/reset-password-request',
    component: ResetPasswordPage,
    suspense: 'account',
    exact: true,
  },
  {
    name: 'ConfirmPasswordPage',
    path: '/account/confirm-password/:token',
    component: ConfirmPasswordPage,
    suspense: 'account',
    exact: true,
  },
  {
    name: 'ConfirmPasswordPage2',
    path: '/account/reset-password/:token',
    component: ConfirmPasswordPage,
    suspense: 'account',
    exact: true,
  },
  {
    name: 'ConfirmAdminInvitePage',
    path: '/account/confirm-admin-invite/:token',
    component: ConfirmAdminInvitePage,
    suspense: 'account',
    exact: true,
  },
  {
    name: 'SetPasswordPage',
    path: '/account/set-password',
    component: SetPasswordPage,
    suspense: 'account',
    exact: true,
  },
];

export const protectedRoutes: RouteConfig[] = [
  {
    name: 'MyProgramsPage',
    path: '/programs/my-programs',
    component: MyProgramsPage,
    suspense: 'programs',
    exact: true,
  },
  {
    name: 'OtherProgramsPage',
    path: '/programs/other-programs',
    component: OtherProgramsPage,
    suspense: 'programs',
    exact: true,
  },
  {
    name: 'MySavedPrograms',
    path: '/programs/saved',
    component: MySavedPrograms,
    suspense: 'programs',
    exact: true,
  },
  {
    name: 'EventsPage',
    path: '/events',
    component: EventsPage,
    suspense: 'programs',
    exact: true,
  },
  {
    name: 'WatchProgramPage',
    path: '/watch/program/:programId',
    component: WatchProgramPage,
    suspense: 'watch',
    exact: true,
  },
  {
    name: 'WatchEpisodePage',
    path: '/watch/episode/:episodeId',
    component: WatchEpisodePage,
    suspense: 'watch',
    exact: true,
  },

  { name: 'SearchPage', path: '/search/:search?', component: SearchPage, suspense: 'programs', exact: true },
  { name: 'MyOrdersPage', path: '/settings/my-orders', component: MyOrdersPage, suspense: 'settings', exact: true },
  { name: 'HelpPage', path: '/settings/help', component: HelpPage, suspense: 'settings', exact: true },

  { name: 'QA', path: '/q-and-a', component: QA, exact: true },
  { name: 'QADetails', path: '/q-and-a/:id', component: QADetails, exact: true },
];

export const allRoutes = [...publicRoutes, ...protectedRoutes];
