import React from 'react';
import { useIntl } from 'estafette-intl';
import { Button, Modal } from 'ebs-design';

interface Props {
  handleToggle: () => void;
  handleOk: () => void;
  title?: string;
  bodyText?: string | React.ReactNode;
  okButtonText?: string;
  cancelButtonText?: string;
}

export const ConfirmModal: React.FC<Props> = ({
  handleToggle,
  handleOk,
  title,
  bodyText,
  okButtonText,
  cancelButtonText,
}) => {
  const { t } = useIntl();

  return (
    <Modal className="confirm-modal" size="small" onClose={handleToggle} title={title || t('confirm')} open>
      <Modal.Content className="confirm-modal__content">
        {bodyText && <div className="confirm-modal__description">{bodyText}</div>}
      </Modal.Content>
      <Modal.Footer>
        <Button className="confirm-modal__button confirm-modal__button-cancel" onClick={handleToggle}>{cancelButtonText || t('cancel')}</Button>
        <Button className="confirm-modal__button" type="primary" onClick={handleOk}>
          {okButtonText || t('ok')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
