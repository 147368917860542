import { FC, ReactNode, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useStateHandlers } from 'hooks';

interface Props {
  className?: string;
  children: ReactNode;
  height?: string;
  backgroundColor?: string;
}

export const Scroll: FC<Props> = ({ className = '', children, height = 1000, backgroundColor = '#fff' }) => {
  const [state, setState] = useStateHandlers({ showScroll: false });

  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    timer.current = setTimeout(() => {
      setState({ showScroll: false });
    }, 1000);

    return () => {
      if (timer.current !== undefined) {
        clearTimeout(timer.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.showScroll]);

  const onScrollChange = () => setState({ showScroll: true });

  return (
    <div
      className={cn(`zh-scroll`, className, state.showScroll ? 'scrollbar-holder' : 'hidden')}
      style={{
        maxHeight: height,
        scrollbarColor: state.showScroll ? 'auto' : `${backgroundColor} ${backgroundColor}`,
        scrollbarWidth: 'auto',
      }}
      onScroll={onScrollChange}
    >
      {children}
    </div>
  );
};
