import { createMessages } from 'estafette-intl';

import en from './categories/en.json';
import enUs from './categories/en-us.json';
import ru from './categories/ru.json';
import ro from './categories/ro.json';
import pl from './categories/pl.json';
import pt from './categories/pt.json';

const translations = [
  ...Object.keys(en),
  ...Object.keys(enUs),
  ...Object.keys(ru),
  ...Object.keys(ro),
  ...Object.keys(pl),
  ...Object.keys(pt),
].reduce((accumulator, value) => {
  return {
    ...accumulator,
    [value]: {
      // @ts-ignore
      'en-us': enUs[value],
      // @ts-ignore
      en: en[value],
      // @ts-ignore
      ro: ro[value],
      // @ts-ignore
      pl: pl[value],
      // @ts-ignore
      pt: pt[value],
      // @ts-ignore
      ru: ru[value],
    },
  };
}, {});

export const messages = createMessages([{ ...translations }]);
