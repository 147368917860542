import React, { useState } from 'react';
import { BookmarkItem } from 'libs/http/api/education/education.types';
import { ArrowDown, ArrowUp } from 'resources';
import { CollapseProgramItem } from './CollapseProgramItem';

interface Props {
  bookmark: BookmarkItem;
  reFetch: (id: number) => void;
}

export const CollapseProgramCard: React.FC<Props> = ({ bookmark, reFetch }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="collapse-program-card">
      <div className="collapse-program-card__body">
        <div className="collapse-program-card__image">
          <img src={bookmark.chapter.cover_photo} alt={bookmark.chapter.title} />
        </div>
        <div className="collapse-program-card__header" onClick={() => setIsOpen((prev) => !prev)}>
          <div className="collapse-program-card__header-title">
            <div className="collapse-program-card__name">{bookmark.chapter.extra_title}</div>
            {Boolean(bookmark.episode.length) && (
              <div className="collapse-program-card__control-button">
                {isOpen ? <ArrowUp className="active" /> : <ArrowDown />}
              </div>
            )}
          </div>
          <div className="collapse-program-card__chapter-title">{bookmark.chapter.title}</div>
          <div className="collapse-program-card__image-mobile__wrapper">
            <div className="collapse-program-card__image-mobile">
              <img src={bookmark.chapter.cover_photo} alt={bookmark.chapter.title} />
            </div>
            <div className="collapse-program-card__chapter-description">{bookmark.chapter.description}</div>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="collapse-program-card__episodes">
          {bookmark.episode
            .sort((a, b) => (a?.number || 0) - (b?.number || 0))
            .map((i, k) => (
              <CollapseProgramItem episode={i} programId={bookmark.program.id} reFetch={reFetch} key={k} />
            ))}
        </div>
      )}
      <div className="collapse-program-card__border-bottom" />
    </div>
  );
};
