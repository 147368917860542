import { AxiosResponse, Canceler } from 'axios';
import { axios, cancelToken } from '../../axios';
import {
  Bookmark,
  BookmarkSaved,
  ChapterReadOnly,
  EpisodeBookmark,
  EpisodeDetail,
  EpisodeReadOnly,
  GlobalSearch,
  ProgramReadOnly,
} from './education.types';
import { WithPagination } from '../index.types';

export const education = {
  getPrograms: {
    action: (params: object = {}): Promise<AxiosResponse<WithPagination<ProgramReadOnly>>> =>
      axios.get(`/education/programs/`, {
        ...params,
        cancelToken: new cancelToken((c: Canceler) => (education.getPrograms.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getUserSubscription: {
    action: (params: object = {}): Promise<AxiosResponse<any[]>> =>
      axios.get(`/education/user-subscriptions/`, {
        ...params,
        cancelToken: new cancelToken((c: Canceler) => (education.getUserSubscription.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getProgramDetails: {
    action: (id: number): Promise<AxiosResponse<ProgramReadOnly>> =>
      axios.get(`/education/programs/${id}/`, {
        cancelToken: new cancelToken((c: Canceler) => (education.getProgramDetails.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getProgramChapters: {
    action: (id: number, params = {}): Promise<AxiosResponse<WithPagination<ChapterReadOnly>>> =>
      axios.get(`/education/programs/${id}/chapters/`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (education.getProgramChapters.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getProgramReviews: {
    action: (id: number, params = {}): Promise<AxiosResponse<EpisodeDetail>> =>
      axios.get(`/education/programs/${id}/reviews/`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (education.getProgramReviews.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  subscribeFree: {
    action: (id: number): Promise<AxiosResponse<EpisodeDetail>> =>
      axios.post(
        `/education/programs/${id}/subscribe-free/`,
        {},
        {
          cancelToken: new cancelToken((c: Canceler) => (education.getProgramReviews.cancel = c)),
        },
      ),
    cancel: (() => null) as Canceler,
  },
  getChapterEpisodes: {
    action: (id: number, params = {}): Promise<AxiosResponse<WithPagination<EpisodeReadOnly>>> =>
      axios.get(`/education/chapters/${id}/episodes/`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (education.getChapterEpisodes.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getEpisodeByProgress: {
    action: (params: object = {}): Promise<AxiosResponse<EpisodeDetail>> =>
      axios.get(`/education/subscriptions/left-off-episode/`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (education.getEpisodeByProgress.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getEpisode: {
    action: (id: number): Promise<AxiosResponse<EpisodeDetail>> =>
      axios.get(`/education/episodes/${id}/`, {
        cancelToken: new cancelToken((c: Canceler) => (education.getEpisode.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getEpisodeComments: {
    action: (params = {}): Promise<AxiosResponse<any>> =>
      axios.get(`/education/episodes/comments/`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (education.getEpisodeComments.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  search: {
    action: (params = {}): Promise<AxiosResponse<GlobalSearch>> =>
      axios.get(`/education/search/`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (education.search.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  addBookmark: {
    action: (id: number, data = {}): Promise<AxiosResponse<EpisodeBookmark>> =>
      axios.post(`/education/episodes/${id}/bookmark/`, data, {
        cancelToken: new cancelToken((c: Canceler) => (education.addBookmark.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  addClapsAdd: {
    action: (id: number): Promise<AxiosResponse<any>> =>
      axios.post(
        `/education/episodes/${id}/react/`,
        {},
        {
          cancelToken: new cancelToken((c: Canceler) => (education.addClapsAdd.cancel = c)),
        },
      ),
    cancel: (() => null) as Canceler,
  },
  setTodo: {
    action: (
      id: number,
      data: { whatched_video?: boolean; downloaded_workbook?: boolean; completed_assignment?: boolean } = {},
    ): Promise<AxiosResponse<any>> =>
      axios.post(`/education/episodes/${id}/set_todo/`, data, {
        cancelToken: new cancelToken((c: Canceler) => (education.setTodo.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getBookmarks: {
    action: (): Promise<AxiosResponse<WithPagination<Bookmark>>> =>
      axios.get(`/education/bookmarks/`, {
        cancelToken: new cancelToken((c: Canceler) => (education.getBookmarks.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getSavedBookmarks: {
    action: (): Promise<AxiosResponse<WithPagination<BookmarkSaved>>> =>
      axios.get(`/education/bookmarks/`, {
        cancelToken: new cancelToken((c: Canceler) => (education.getBookmarks.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  deleteBookmark: {
    action: (id: number): Promise<AxiosResponse<any>> =>
      axios.delete(`/education/episodes/${id}/bookmark/`, {
        cancelToken: new cancelToken((c: Canceler) => (education.deleteBookmark.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  addComment: {
    action: (id: number, data = {}): Promise<AxiosResponse<any>> =>
      axios.post(`/education/episodes/comments/`, data, {
        cancelToken: new cancelToken((c: Canceler) => (education.addComment.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  patchComment: {
    action: (id: number, data = {}): Promise<AxiosResponse<any>> =>
      axios.patch(`/education/episodes/comments/${id}/`, data, {
        cancelToken: new cancelToken((c: Canceler) => (education.patchComment.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  likeComment: {
    action: (id: number, data = {}): Promise<AxiosResponse<any>> =>
      axios.post(`/education/episodes/comments/${id}/like/`, data, {
        cancelToken: new cancelToken((c: Canceler) => (education.likeComment.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  unLikeComment: {
    action: (id: number): Promise<AxiosResponse<any>> =>
      axios.delete(`/education/episodes/comments/${id}/like/`, {
        cancelToken: new cancelToken((c: Canceler) => (education.unLikeComment.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getVideoContent: {
    action: (url: string, params = {}): Promise<AxiosResponse<any>> =>
      axios.get(url, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (education.getVideoContent.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getNextEpisode: {
    action: (id: number, params = {}): Promise<AxiosResponse<any>> =>
      axios.get(`/education/episodes/${id}/next/`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (education.getNextEpisode.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getPreviousEpisode: {
    action: (id: number, params = {}): Promise<AxiosResponse<any>> =>
      axios.get(`/education/episodes/${id}/previous/`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (education.getPreviousEpisode.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  postReview: {
    action: (id: number, data = {}): Promise<AxiosResponse<any>> =>
      axios.post(`/education/programs/${id}/reviews/`, data, {
        cancelToken: new cancelToken((c: Canceler) => (education.postReview.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  setEpisodeProgress: {
    action: (id: number, data = {}): Promise<AxiosResponse<any>> =>
      axios.post(`/education/episodes/${id}/progress/`, data, {
        cancelToken: new cancelToken((c: Canceler) => (education.setEpisodeProgress.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  reply: {
    action: (id: number, data = {}): Promise<AxiosResponse<any>> =>
      axios.post(`/education/episodes/comments/${id}/replies/`, data, {
        cancelToken: new cancelToken((c: Canceler) => (education.reply.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  getReplies: {
    action: (id: number, params = {}): Promise<AxiosResponse<any>> =>
      axios.get(`/education/episodes/comments/${id}/replies/`, {
        params,
        cancelToken: new cancelToken((c: Canceler) => (education.getReplies.cancel = c)),
      }),
    cancel: (() => null) as Canceler,
  },
  saveProgress: {
    action: (id: number, progress: number): Promise<AxiosResponse<any>> =>
      axios.post(
        `/education/episodes/${id}/progress/`,
        { progress },
        {
          cancelToken: new cancelToken((c: Canceler) => (education.saveProgress.cancel = c)),
        },
      ),
    cancel: (() => null) as Canceler,
  },
};
