import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { UserContext } from 'contexts/UserContext';

export interface ProtectedRouteProps extends RouteProps {}

export function ProtectedRoute({ children, render, ...props }: ProtectedRouteProps) {
  const { logged } = useContext(UserContext);

  return (
    <Route {...props} render={logged ? render || (() => <>{children}</>) : () => <Redirect to="/account/sign-in" />} />
  );
}
