import { FormInstance } from 'rc-field-form/es/interface';
import { default as queryString } from 'query-string';
import { $Object } from './globalTypes';
import { allRoutes } from '../routes';
import { parseObject } from './object';
import { Webinar, WebinarStatus } from './http/api/events/events.types';
import { now, today } from './date';
import dayjs from 'dayjs';

export const setFieldsError = (fieldsError: $Object, form: FormInstance): void => {
  const { getFieldsValue, setFields } = form;
  const currentField = getFieldsValue();

  setFields(parser(fieldsError, currentField));
};

const parser = (answer: any, values: any) => {
  const recursiveParser = (localAcc: any[], answer: any, values: any, name: string[] = []) =>
    Object.keys(answer).forEach((key) => {
      if (answer[key].length === undefined) {
        recursiveParser(localAcc, answer[key], values[key], [...name, key]);
      }

      localAcc.push({
        name: isNaN(parseInt(key)) ? [...name, key] : name,
        value: values ? values : undefined,
        errors: answer[key],
      });
    });

  return Object.keys(answer).reduce<any[]>((acc, current) => {
    recursiveParser(acc, answer[current], values[current], [current]);

    return acc;
  }, []);
};

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const validate = (
  errors: any,
  key: string,
  validateStatus: '' | 'error' | 'validating' | 'success' | 'warning' | undefined = 'error',
) =>
  errors[key]
    ? {
        validateStatus,
        help: errors[key].errors[0],
      }
    : null;

export const random = (minimum = 0, maximum = 10): number => Math.round(Math.random() * (maximum - minimum) + minimum);

export const getRoute = (name: string, params: { [key: string]: any } = {}) => {
  const route = allRoutes.find((item) => item.name === name);

  if (route) {
    let { path } = route;

    Object.keys(params).map((key) => (path = path.replace(`:${key}`, params[key])));

    return params.query ? `${path}${parseObject(params.query)}` : path;
  }

  return '';
};

export const getRouteWithoutParams = (name: string) => {
  const route = allRoutes.find((item) => item.name === name);

  if (route) {
    return route.path
      .split('/')
      .filter((i) => !i.includes(':') && !i.includes('$'))
      .join('/');
  }

  return '';
};

export const getActiveRouteByPath = (path: string) => {
  const activeRoute = allRoutes.find((i) => comparePaths(i.path, path) && !i.withOutLayout);

  return (activeRoute && activeRoute.name) || '';
};

export const comparePaths = (path1: string, path2: string) => {
  const path2Array = path2.split('/');
  let isRightPath = true;

  path1.split('/').map((i, key) => {
    if (i.includes(':') && path2Array[key]) {
      return i;
    }

    if (i !== path2Array[key]) {
      isRightPath = false;
    }

    return i;
  });

  return isRightPath;
};

export const getTimeFromSeconds = (seconds: number) => {
  const localSeconds = Number(seconds);

  const putRightForm = (number: number) => (number < 10 ? `0${Math.floor(number)}` : Math.floor(number));

  return `${putRightForm(localSeconds / 60)}:${putRightForm(localSeconds % 60)}`;
};

export const getWsSocketLink = () => {
  // if (process.env.NODE_ENV === 'development') {
  //   return `ws://${process.env.REACT_APP_SOCKET_URL}/`;
  // }

  return `wss://${process.env.REACT_APP_SOCKET_URL}/`;
};

export const getHttpSocketLink = () => {
  // if (process.env.NODE_ENV === 'development') {
  //   return `http://${process.env.REACT_APP_SOCKET_URL}`;
  // }

  return `https://${process.env.REACT_APP_SOCKET_URL}`;
};

export const getSubscribers = (number: number) => (number > 1000 ? `${(number / 1000).toFixed(1)}K` : number);

export const clearUserInfo = (text?: string) => text?.replace(/{.*}/g, '');

export const getEventSubTitle = (
  event: Webinar,
  t: (x: string, y?: { [key: string]: string }) => string,
  isEnrolled?: boolean,
): string => {
  if (event.status === WebinarStatus.ATTENDED) {
    return t('attended');
  }

  if (event.status === WebinarStatus.NOT_ATTENDED) {
    return t('not_attended');
  }

  if (event.watch_replay) {
    if (now >= dayjs(event.date).add(3, 'hours').unix() && now < dayjs(event.date).endOf('day').unix()) {
      const time = dayjs(event.date).endOf('day').diff(today, 'minutes');
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      return t('replayExpiresIn', { hours: `${hours}`, minutes: `${minutes}` });
    }

    if (now < dayjs(event.date).unix() || now > dayjs(event.date).endOf('day').unix()) {
      return t('youAreRegisteredForThisSeminar');
    }
  }

  if (now > dayjs(event.date).add(2, 'hours').unix()) {
    return t('not_attended');
  }

  if (isEnrolled) {
    return t('youAreRegisteredForThisSeminar');
  }

  if (event.status === WebinarStatus.PENDING) {
    t('limited_invite');
  }
  return '';
};

export const isMyEvents = (event: Webinar, isEnrolled = true): boolean => {
  if (!isEnrolled) {
    return false;
  }

  return (
    now <= dayjs(event.date).add(3, 'hours').unix() ||
    (event.watch_replay &&
      now > dayjs(event.date).add(3, 'hours').unix() &&
      now < dayjs(event.date).endOf('day').unix())
  );
};

export const isHistory = (event: Webinar, isEnrolled = true): boolean => {
  if (!isEnrolled) {
    return false;
  }

  return (
    (now > dayjs(event.date).add(2, 'hours').unix() && !event.watch_replay) ||
    (now > dayjs(event.date).endOf('day').unix() && event.watch_replay)
  );
};

export const setHTMLClassName = (className: string) => {
  document.getElementsByTagName('html')[0].className = className;
};

export function uid() {
  return (performance.now().toString(36) + Math.random().toString(36)).replace(/\./g, '');
}

export const addToURL = (url: string, params: { [key: string]: any }): string => {
  const parsedUrl = queryString.parseUrl(url);

  return queryString.stringifyUrl(
    { ...parsedUrl, query: { ...parsedUrl.query, ...params } },
    {
      skipEmptyString: true,
      skipNull: true,
    },
  );
};
