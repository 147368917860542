import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getRoute } from 'libs/utils';
import { ifAuthTokenExist } from 'libs/cookies';

export const HomePage = () => {
  const { push } = useHistory();

  useEffect(() => {
    if (!ifAuthTokenExist()) {
      return push(getRoute('OtherProgramsPage'));
    }

    return push(getRoute('MyProgramsPage'));
  }, []);

  return (
    <section className="m-containter d-flex justify-content--center align-items--center">
      <img src="logo.png" alt="Parrents" />
    </section>
  );
};
