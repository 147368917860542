import { useEffect, useRef } from 'react';
import { load } from 'react-cookies';
import { useStateHandlers } from './useStateHandlers';
import { $Object } from 'libs/globalTypes';
import { thisDeviceProps } from 'contexts/DevicesContext';
import { getWsSocketLink } from '../libs/utils';
import { users } from '../libs/http/api';

export const useSocket = (onMessage: (data: $Object) => void) => {
  let socket = useRef<WebSocket>();

  const timeToRefresh = 1000;

  const timer = useRef<NodeJS.Timeout>();

  const [state, setState] = useStateHandlers({ refresh: false });

  useEffect(() => {
    timer.current = setTimeout(() => {
      if (socket.current) {
        switch (socket.current.readyState) {
          case 0:
            break;
          case 1:
            break;
          case 2:
            break;
          case 3:
            onSocketDisconnect();
            onSocketConnect();
            break;
        }
      }

      // onRefreshChange();
    }, timeToRefresh);

    return () => {
      if (timer.current !== undefined) {
        clearTimeout(timer.current);
      }
    };
  }, [state.refresh]);

  const onRefreshChange = () => setState((prevState) => ({ refresh: !prevState.refresh }));

  const onSocketConnect = () => {
    const token = load('jwt-token');

    if (token) {
      socket.current = new WebSocket(`${getWsSocketLink()}ws?device_id=${thisDeviceProps.device_id}&token=${token}`);

      socket.current.onopen = () => {
        global.console.log('[SOCKET] Socket has been connected');

        users.activateDevice.action({ device_id: thisDeviceProps.device_id });
      };

      socket.current.onmessage = ({ data }) => {
        if (data) {
          const socketData = JSON.parse(data);

          onMessage(socketData);
        }
      };

      socket.current.onclose = () => {
        global.console.log('[SOCKET] Socket has been disconnected');

        onRefreshChange();
      };

      socket.current.onerror = () => {
        global.console.log('[SOCKET] Socket got an error');
      };
    }
  };

  const onSocketDisconnect = () => {
    if (socket.current) {
      socket.current.close();
    }

    return false;
  };

  return { onSocketConnect, onSocketDisconnect };
};
