import { useStateHandlers } from 'hooks';
import { createContext, ReactElement, useEffect, useMemo } from 'react';
import { breakpoints } from '../libs/globalVars';

interface State {
  width: number;
  height: number;
}

interface ResponsiveContextInterface extends State {
  isDesktop: boolean;
  isSmallTablet: boolean;
  isFhd: boolean;
  isSm: boolean;
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};

export const ResponsiveContext = createContext<ResponsiveContextInterface>({
  ...getWindowDimensions(),
  isDesktop: false,
  isSmallTablet: false,
  isFhd: false,
  isSm: false,
});

export const ResponsiveProvider = ({ children }: { children: ReactElement }): ReactElement => {
  const [state, setState] = useStateHandlers(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setState(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isDesktop = useMemo(() => state.width < breakpoints.desktop, [state.width]);

  const isSmallTablet = useMemo(() => state.width < breakpoints['small-tablet'], [state.width]);

  const isFhd = useMemo(() => state.width < breakpoints.fhd, [state.width]);

  const isSm = useMemo(() => state.width < breakpoints.sm, [state.width]);

  return (
    <ResponsiveContext.Provider value={{ ...state, isDesktop, isSmallTablet, isFhd, isSm }}>
      {children}
    </ResponsiveContext.Provider>
  );
};
