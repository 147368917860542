import React, { useEffect, useMemo } from 'react';
import { Input, Icon, Loader, Tooltip, Space } from 'ebs-design';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { useRequest } from 'estafette';
import { education } from 'libs/http/api/education/education';
import { pageSize } from 'libs/globalVars';
import { Comment as CommentProps, EpisodeDetail } from 'libs/http/api/education/education.types';
import { WithPagination } from 'libs/http/api/index.types';
import { useIntl, useStateHandlers } from 'hooks';
import { ArrowDown } from 'resources';
import { ReactComponent as Empty } from 'features/qa/pages/List/empty.svg';
import { Comment } from '../Comment/Comment';
import { WriteComment } from '../WriteComment/WriteComment';
import { Animated } from 'ui/atoms';

interface State {
  page: number;
  ordering: Sort;
  search: string;
}

interface Props {
  episode: EpisodeDetail;
  isQA: boolean;
  increaseCommentCount: () => void;
  comments_count: number;
}

enum Sort {
  OLDEST = 'created_at',
  NEWEST = '-created_at',
  RELEVANT = '-relevant',
}

const sortKeys = {
  [Sort.OLDEST]: 'oldest',
  [Sort.NEWEST]: 'newest',
  [Sort.RELEVANT]: 'relevant',
};

export const Comments: React.FC<Props> = ({ episode, isQA, increaseCommentCount /*, comments_count */ }) => {
  const { t } = useIntl();
  const { episodeId } = useParams<{ episodeId: string }>();

  const comments = useRequest<WithPagination<CommentProps>>({ data: { results: [] } });

  const [state, setState] = useStateHandlers<State>({ page: 1, ordering: Sort.RELEVANT, search: '' });

  useEffect(() => {
    Promise.all([comments.setData({ results: [] }), setState({ page: 1, ordering: Sort.RELEVANT }), getComments(1)]);
  }, [episodeId]);

  useEffect(() => {
    getComments(state.page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page, state.search, isQA]);

  useEffect(() => {
    Promise.all([
      comments.setData({ results: [] }),
      ...Array.from({ length: state.page }).map((_, i) => getComments(i + 1)),
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.ordering]);

  const isHasMore = useMemo(
    () => !comments.loading && Math.ceil(comments.data.count / pageSize) > state.page,
    [comments.loading, comments.data, state.page],
  );

  const getComments = (page: number): Promise<any> =>
    comments.request(
      education.getEpisodeComments.action({
        episode: Number(episodeId),
        page_size: pageSize,
        page: page,
        ordering: state.ordering,
        type: isQA ? 'qa' : 'comment',
        ...(state.search.length && { search: state.search }),
      }),
      { concat: page > 1 ? 'results' : false },
    );

  const onSetRefresh = () => {
    Promise.all([
      comments.setData({ results: [] }),
      ...Array.from({ length: state.page }).map((_, i) => getComments(i + 1)),
    ]);
    setState({ page: 1 });
  };
  const onPageChange = () => {
    if (isHasMore) {
      setState((prevState) => ({ page: ++prevState.page }));
    }
  };

  const handleChooseSorting = (ordering: Sort) => {
    setState({ ordering });
  };

  return (
    <div className="comments">
      {/* <div className="comments__head-title">
        {comments_count} {t('comments')}
      </div> */}
      <Input
        placeholder={t('Search')}
        value={state.search}
        prefix={<Icon type="search" />}
        containerClass="comments__search"
        onChange={(value) => setState({ search: value as string })}
      />

      <div className="comments__list">
        <div className="comments__sorting">
          <span>{t('sortBy')}:</span>
          <Tooltip
            placement="bottom"
            trigger="click"
            tooltip={
              <div className="comments__sorting__options">
                <span onClick={() => handleChooseSorting(Sort.NEWEST)}>{t('newest')}</span>
                <span onClick={() => handleChooseSorting(Sort.OLDEST)}>{t('oldest')}</span>
                <span onClick={() => handleChooseSorting(Sort.RELEVANT)}>{t('relevant')}</span>
              </div>
            }
          >
            <span className="comments__sorting__selected">
              {t(sortKeys[state.ordering])}
              <ArrowDown />
            </span>
          </Tooltip>
        </div>
        <InfiniteScroll initialLoad={false} hasMore={isHasMore} pageStart={state.page} loadMore={onPageChange}>
          {comments.loading && (
            <Animated loading={false} animateOpacity>
              <Space direction="vertical">
                <Loader.Inline />
              </Space>
            </Animated>
          )}
          {comments.data.results?.length ? (
            <Animated loading={false} animateOpacity>
              {comments.data.results.map((comment, i) => (
                <Comment comment={comment} key={`${comment.id}-${i}`} increaseCommentCount={increaseCommentCount} />
              ))}
            </Animated>
          ) : (
            <Animated loading={false} animateOpacity>
              <Space direction="vertical">
                <Empty style={{ minHeight: 200 }} />
              </Space>
            </Animated>
          )}
        </InfiniteScroll>
      </div>

      <WriteComment onSubmit={onSetRefresh} increaseCommentCount={increaseCommentCount} />
    </div>
  );
};
