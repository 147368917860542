export interface User {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  nick_name: string;
  photo: string;
  href: string;
  email: string;
  role: string;
  date_of_birth: string;
  created_at: string;
  post_count: number;
  member_count: number;
  child_count: number;
  phone: string;
  blocked: boolean;
  is_staff: boolean;
  has_push_notifications: boolean;
  has_hided_my_posts: boolean;
  groups: string[];
  address: UserAddress;
  delivery_address: UserAddress;
  delivery_address_is_the_same: boolean;
  language: string;
  device_id: string;
  device_name: string;
  device_type: string;
}

export interface RefreshAccess {
  access: string;
}

export interface CreatedBy {
  id: string;
  full_name: string;
  nick_name: string;
  photo: string;
  is_staff: boolean;
}

export interface UserAddress {
  country: string;
  county: string;
  city: string;
  postcode: string;
  street: string;
  street_number: string;
  block: string;
  entrance: string;
  floor: string;
  apartment: string;
  line1: string;
  line2: string;
}

export interface UserOrders {
  id: number;
  user: string[];
  date: string;
  price: PriceGet;
  program_subscriptions: string[];
  event_subscriptions: string[];
  billing_status: BillingStatus;
  error: string;
  billing_error: string;
  billing_error_detail: string;
  utm_source: string;
  utm_medium: string;
  utm_type: string;
  start: string;
  paid_installments: number;
  payment_failed: boolean;
  failed_invoice: {
    id: number;
    amount: number;
    currency: string;
  };
  failed_invoice_url?: string;
  next_debit_date: string;
  extra: string[];
}

export enum BillingStatus {
  initializing = 'initializing',
  error = 'error',
  done = 'done',
  refunded = 'refunded',
  active = 'active',
  suspended = 'suspended',
  deactivated = 'deactivated',
  installments_complete = 'installments complete',
  unpaid = 'unpaid',
  payment_error = 'payment error',
  deactivated_unpaid = 'deactivatedunpaid',
  completed = 'completed',
  archived = 'archived',
}

export interface PriceGet {
  id: number;
  discount: string[];
  price: number;
  shipping_price: number;
  shipping_price_international: number;
  description: string;
  created_by: string[];
  modified_by: string[];
  product: Product;
  mongo_id: string;
  created_at: string;
  modified_at: string;
  warehouse_id: number;
  warehouse_data: string[];
  billing_ids: number;
  is_active: boolean;
  price_type: PriceType;
  period: Period;
  currency: Currency;
  subscription_type: SubscriptionType;
  valid_to: string;
  shipping: boolean;
  installments: number;
  name: string;
  photo: string;
  page: string;
  success_url: string;
  email_start_events: string[];
  webinar_look: boolean;
  language: string;
}

export enum PriceType {
  one_time = 'one_time',
  recurring = 'recurring',
  installment = 'installment',
}

export enum Period {
  month = 'month',
  day = 'day',
}

export enum Currency {
  EUR = 'EUR',
  RON = 'RON',
  PLN = 'PLN',
  AZN = 'AZN',
  GBP = 'GBP',
  USD = 'USD',
}

export enum SubscriptionType {
  all = 'all',
  drip = 'drip',
}

export interface Product {
  id: number;
  name: string;
  products: any;
  programs: any;
  image: string;
  has_gender: boolean;
  genders: any;
  has_size: boolean;
  sizes: any;
  product_type: string;
  product_kind: string;
}

export interface ContactUs {
  message: string;
  image_links?: string[];
}

export interface File {
  content_type: string;
  created_at: string;
  file_hash: string;
  id: string;
  is_processed: boolean;
  name: string;
  path: string;
  size: number;
  url: string;
}

export interface UserDevice {
  id: string;
  name: string;
  type: string;
  status: UserDeviceStatus;
  address: string;
  last_login: string;
}

export enum UserDeviceStatus {
  active = 'active',
  loggedOut = 'logged_out',
  onHold = 'on_hold',
}
