import { FC } from 'react';
import { Button, Space } from 'ebs-design';
import { Icon } from 'ui/atoms/Icon/Icon';
import { NotifyItemProps } from 'ebs-design/dist/components/molecules/Notify/NotifyItem';

export interface NotifyItemType {
  type?: 'regular' | 'primary' | 'success' | 'danger' | 'info' | 'warning';
}

export const NotifyItem: FC<NotifyItemProps> = ({
  type = 'success',
  description,
  icon,
  size = 'small',
  direction = 'vertical',
  onClose,
}) => {
  if (!icon) {
    switch (type) {
      case 'success':
        icon = 'notify-check';
        break;
      case 'danger':
        icon = 'notify-alert';
        break;
    }
  }

  return (
    <Space size={size} className="m-notify__item">
      {icon && (
        <div className="d-flex align-items--center">
          <Icon type={typeof icon === 'string' ? icon : undefined} />
        </div>
      )}

      <Space align="start" size="small" direction={direction} className="ebs-notify__item-icon">
        {description && <p className="m-notify__item-message">{description}</p>}
      </Space>
      <Button type="text" className="m-notify__item-close" onClick={onClose}>
        <Icon type="notify-close" />
      </Button>
    </Space>
  );
};
