import { useIntl } from 'estafette-intl';
import { Section } from '../components/atoms/Section';
import { SetNewPassForm } from '../components/molecules/SetNewPassForm';
import { users } from 'libs/http/api';
import { useClearCredentials } from 'hooks';
import { parseQuery } from 'libs/object';

export const SetPasswordPage = () => {
  const { t } = useIntl();

  const { auto_setup_token } = parseQuery<{ auto_setup_token?: string }>(window.location.search);

  useClearCredentials();

  return (
    <Section label={t('confirmAdminInvite')}>
      <SetNewPassForm apiRequest={users.tokenRegister.action} autoSetupToken={auto_setup_token} />
    </Section>
  );
};
