import React, { useContext } from 'react';
import cn from 'classnames';
import { useIntl } from 'estafette-intl';
import { breakpoints } from 'libs/globalVars';
import { Avatar, Badge, InputSearch, Space } from 'ebs-design';
import { HeaderTooltip } from 'ui/molecules/HeaderTooltip/HeaderTooltip';
import { ConfirmModal } from 'ui/molecules/ConfirmModal/ConfirmModal';
import { UserTooltip } from 'ui/atoms/UserTooltip/UserTooltip';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { SwitchDevicesTooltip } from 'features/programs/components/molecules/SwitchDevicesTooltip/SwitchDevicesTooltip';
import { getRoute } from 'libs/utils';
import { UserDeviceStatus } from 'libs/http/api/users/users.types';
import { history } from 'libs/history';
import { LanguagesTooltip } from 'ui/atoms/LanguagesTooltip/LanguagesTooltip';
import { Container } from 'ui/atoms';
import { ResponsiveContext } from 'contexts/ResponsiveContext';
import { StylesContext } from 'contexts/StylesContext';
import { UserContext } from 'contexts/UserContext';
import { QAContext } from 'contexts/QAContext';
import { useStateHandlers } from 'hooks';
import { Bell, Close, Logo, Search, SwitchDevice, UserIcon } from 'resources';

interface Props {
  onSideNavChange: any; //todo
  showDeviceSwitch: any; //todo
  thisDevice: any; //todo
  notifications: any; //todo
}

export const Header: React.FC<Props> = ({ onSideNavChange, showDeviceSwitch, thisDevice, notifications }) => {
  const { t } = useIntl();
  const { header: QAHeader } = useContext(QAContext);
  const { width } = useContext(ResponsiveContext);
  const { fullWindowClass } = useContext(StylesContext);
  const { logged, userData, onLogout } = useContext(UserContext);
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [state, setState] = useStateHandlers({
    showModal: false,
    search: '',
    showSwitchMenu: false,
    showSwitchDeviceMenu: false,
    openSearch: false,
  });

  const onModalChange = () => setState((prevState) => ({ showModal: !prevState.showModal }));

  const onSignOut = () => {
    onLogout(false);
    setState({ showModal: false });

    history.replace(getRoute('SignInPage'));
  };

  const handleSignInRedirect = () => push(getRoute('SignInPage'));

  const toggleSearch = () => {
    setState((s) => ({ openSearch: !s.openSearch }));
  };

  const onSearchChange = (search: string) => {
    setState({ search });
    if (search) {
      push(getRoute('SearchPage', { search }));
      setState({ openSearch: false });
    }
  };
  const onShowDeviceMenuClick = () =>
    setState((prevState) => ({ showSwitchDeviceMenu: !prevState.showSwitchDeviceMenu }));

  return (
    <>
      {state.showModal && (
        <ConfirmModal
          title={t('confirm')}
          bodyText={t('sure_sign_out')}
          okButtonText={t('on_sign_out')}
          cancelButtonText={t('close')}
          handleToggle={onModalChange}
          handleOk={onSignOut}
        />
      )}
      <div
        className={cn('header', fullWindowClass, {
          fixed: thisDevice && thisDevice.status !== UserDeviceStatus.active,
        })}
      >
        <Container className="header__wrapper">
          <Space justify="space-between">
            <Space>
              <Link to={getRoute(logged ? 'MyProgramsPage' : 'OtherProgramsPage')}>
                <Logo className="header__logo" />
              </Link>
            </Space>
            {width > breakpoints.sm ? (
              <Space align="center" size="large">
                {pathname.includes('q-and-a/')
                  ? QAHeader && QAHeader(toggleSearch)
                  : !pathname.includes('q-and-a') && (
                      <InputSearch
                        placeholder={t('Search')}
                        isClearable
                        onSearch={onSearchChange}
                        value={state.search}
                        iconAlign="prefix"
                        styleType="fill"
                        className="header__search-form"
                      />
                    )}
                {showDeviceSwitch && (
                  <HeaderTooltip tooltip={<SwitchDevicesTooltip />} onClick={onShowDeviceMenuClick}>
                    {thisDevice && thisDevice.status !== UserDeviceStatus.active && (
                      <SwitchDevice className="header__switch-device" />
                    )}
                  </HeaderTooltip>
                )}
                {logged && (
                  <Badge className="cursor-pointer" count={notifications.unseen} type="danger">
                    <Bell className="header__notification" onClick={() => onSideNavChange('notifications')} />
                  </Badge>
                )}
                {logged && (
                  <HeaderTooltip tooltip={<UserTooltip onSignOut={onModalChange} />}>
                    <Avatar circle img={userData.photo} alt={userData.full_name} className="with-action" />
                  </HeaderTooltip>
                )}
                {!logged && (
                  <span className="header__login" onClick={handleSignInRedirect}>
                    {t('login')}
                  </span>
                )}
                <LanguagesTooltip />
              </Space>
            ) : (
              <Space justify="end" align="center">
                <div className="header__mobile">
                  <span className="header__search" onClick={toggleSearch}>
                    <Search />
                  </span>
                  {!logged && (
                    <span className="header__login" onClick={handleSignInRedirect}>
                      <UserIcon />
                    </span>
                  )}
                  {logged && (
                    <div>
                      <HeaderTooltip tooltip={<UserTooltip onSignOut={onModalChange} />}>
                        <Avatar circle img={userData.photo} alt={userData.full_name} className="with-action" />
                      </HeaderTooltip>
                    </div>
                  )}
                  {state.openSearch && (
                    <div className="header__search-component">
                      <div className="header__search-form">
                        {pathname.includes('q-and-a/') ? (
                          <>
                            {QAHeader && QAHeader(toggleSearch)}
                            <Close onClick={toggleSearch} className="header__search-form__close" />
                          </>
                        ) : (
                          !pathname.includes('q-and-a') && (
                            <>
                              <InputSearch
                                placeholder={t('Search')}
                                onSearch={onSearchChange}
                                iconAlign="prefix"
                                styleType="fill"
                              />
                              <Close onClick={toggleSearch} className="header__search-form__close" />
                            </>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Space>
            )}
          </Space>
        </Container>
      </div>
    </>
  );
};
