import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'estafette-intl';
import { useNotify } from 'ebs-design';
import { useTimeUtils } from 'hooks';
import { getRoute } from 'libs/utils';
import { EpisodeReadOnly } from 'libs/http/api/education/education.types';
import { education } from 'libs/http/api/education/education';
import { ConfirmModal } from 'ui/molecules/ConfirmModal/ConfirmModal';
import { Play, Trash } from 'resources';

interface Props {
  episode: EpisodeReadOnly;
  programId: number;
  reFetch: (id: number) => void;
}

export const CollapseProgramItem: React.FC<Props> = ({ episode, programId, reFetch }) => {
  const { t } = useIntl();
  const { getTimeFrom } = useTimeUtils();
  const notify = useNotify();
  const [state, setState] = useState(false);

  const toggleBookmark = () => {
    setState((s) => !s);
  };

  const handleBookmark = () => {
    return education.deleteBookmark
      .action(episode.id)
      .then(() => {
        reFetch(episode.id);
        notify.success({ title: t('success'), description: t('removed_bookmark') });
        setState(false);
      })
      .catch(() => {
        setState(false);
        notify.error({ title: t('error'), description: t('something_wrong') });
      });
  };

  return (
    <>
      <div className="collapse-program-card__episodes__item">
        <div className="collapse-program-card__episodes__item__image">
          <img src={episode.cover_photo} alt={episode.title} />
          <div className="collapse-program-card__episodes__item__image-play">
            <Play />
          </div>
        </div>
        <div className="collapse-program-card__episodes__item__body">
          <div className="collapse-program-card__episodes__item__body__title-wrapper">
            <Link
              to={getRoute('WatchEpisodePage', { programId, episodeId: episode.id })}
              className="collapse-program-card__episodes__item__body__title"
            >
              <span>{episode.number}.</span>
              <span>{episode.title}</span>
            </Link>
            <Trash onClick={toggleBookmark} />
          </div>
          <div className="collapse-program-card__episodes__item__body__description">{episode.description}</div>
          <div className="collapse-program-card__episodes__item__body__continue">
            {episode.progress
              ? `${t('continueForm')}: ${getTimeFrom(episode.progress, true)}`
              : getTimeFrom(episode.duration, true)}
          </div>
        </div>
      </div>
      {state && (
        <ConfirmModal
          title={t('confirm')}
          okButtonText={t('Yes')}
          cancelButtonText={t('close')}
          handleToggle={toggleBookmark}
          handleOk={handleBookmark}
        />
      )}
    </>
  );
};
