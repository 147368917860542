import { AxiosResponse, Canceler } from 'axios';
import { axios, cancelToken } from '../../axios';
import { getHttpSocketLink } from '../../../utils';

export const devices = {
  getActiveDevices: {
    action: (): Promise<AxiosResponse<any>> =>
      axios.get(`/connections`, {
        cancelToken: new cancelToken((c: Canceler) => (devices.getActiveDevices.cancel = c)),
        baseURL: getHttpSocketLink(),
      }),
    cancel: (() => null) as Canceler,
  },
};
