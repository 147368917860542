import { createContext, Dispatch, ReactElement, ReactNode, SetStateAction, useContext, useEffect } from 'react';
import { useRequest } from 'estafette';
import {
  ChapterReadOnly,
  emptyEpisode,
  emptyProgram,
  EpisodeDetail,
  ProgramReadOnly,
} from 'libs/http/api/education/education.types';
import { useStateHandlers } from 'hooks';
import { education } from 'libs/http/api/education/education';
import { WithPagination } from 'libs/http/api/index.types';
import { UserContext } from './UserContext';
import { Notification } from 'libs/http/api/notifications/notifications.types';
import { notifications as notificationsApi } from 'libs/http/api';
import { useFirebaseMessaging } from 'hooks/useFirebaseMessaging';
import { useIntl } from 'estafette-intl';

interface Params {
  concat?: boolean | string;
  loading?: false;
}

interface Props {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  programs: { data: ProgramReadOnly[]; getData: () => void };
  currentProgram: {
    program: {
      data: ProgramReadOnly;
      getData: (id: number, { onlyDetails }: { onlyDetails: boolean }) => void;
      setData: Dispatch<SetStateAction<ProgramReadOnly>>;
    };
    chapters: { data: ChapterReadOnly[]; loading: boolean };
    lastEpisode?: any;
  };
  currentEpisode: {
    data: EpisodeDetail;
    setData: Dispatch<SetStateAction<EpisodeDetail>>;
    request: (
      fn:
        | {
            data: EpisodeDetail;
          }
        | Promise<{
            data: EpisodeDetail;
          }>,
      params?: Params,
    ) => Promise<EpisodeDetail>;
    loading: boolean;
  };
  notifications: {
    data: Notification[];
    loading: boolean;
    setNotifications: Dispatch<any>;
    unseen: number;
    setUnseen: (unseen: number) => void;
  };
  newProgram: {
    id: number;
    setId: Dispatch<number>;
  };
  sideNav: string;
  onSideNavChange: (sideNav: string) => void;
}

const stateDefaultValues = {
  loading: false,
  setLoading: () => null,
  programs: { getData: () => null },
  sideNav: '',
  unseenNotifications: 0,
  newProgram: 0,
};

export const CommunityContext = createContext<Props>({
  ...stateDefaultValues,
  programs: { data: [], getData: () => null },
  currentProgram: {
    program: { data: emptyProgram, getData: () => null, setData: () => null },
    chapters: { data: [], loading: false },
    lastEpisode: {},
  },
  currentEpisode: {
    data: emptyEpisode,
    setData: () => null,
    request: () => Promise.resolve({} as EpisodeDetail),
    loading: false,
  },
  notifications: {
    data: [],
    loading: false,
    setNotifications: () => null,
    unseen: 0,
    setUnseen: () => null,
  },
  newProgram: {
    id: 0,
    setId: () => null,
  },
  onSideNavChange: () => null,
});

export const CommunityProvider = ({ children }: { children: (values: Props) => ReactNode }): ReactElement => {
  const { logged } = useContext(UserContext);
  const { locale } = useIntl();

  const { initializeFirebaseMessaging } = useFirebaseMessaging();

  const programs = useRequest<WithPagination<ProgramReadOnly>>({ data: { results: [] } });
  const currentProgram = useRequest<ProgramReadOnly>({ data: {} });
  const currentEpisode = useRequest<EpisodeDetail>({ data: {} });
  const currentProgramChapters = useRequest<WithPagination<ChapterReadOnly>>({ data: { results: [] } });
  const lastEpisode = useRequest<any>();
  const notifications = useRequest<WithPagination<Notification>>({ data: { results: [] } });

  const [state, setState] = useStateHandlers(stateDefaultValues);

  useEffect(() => {
    initializeFirebaseMessaging();
  }, []);

  useEffect(() => {
    if (logged) {
      getNotifications();
    }
  }, [logged]);

  useEffect(() => {
    getPrograms();
  }, [locale]);

  useEffect(() => {
    const unseenNotifications = notifications.data.results.reduce((acc, current) => (current.seen ? acc : ++acc), 0);

    setUnseenNotifications(unseenNotifications);
  }, [notifications.data]);

  const getPrograms = () => programs.request(education.getPrograms.action());
  const getNotifications = () => notifications.request(notificationsApi.getNotifications.action());

  const getCurrentProgramData = (id: number, { onlyDetails }: { onlyDetails: boolean }) => {
    !onlyDetails && currentProgram.request(education.getProgramDetails.action(id));
    currentProgramChapters.request(education.getProgramChapters.action(id, { page_size: 1000 }));

    if (logged && !onlyDetails) {
      lastEpisode.request(education.getEpisodeByProgress.action({ program: id }));
    }
  };

  const onSideNavChange = (sideNav: string) => {
    if (sideNav) {
      document.body.style.overflowY = 'hidden';
      setState({ sideNav });
    } else {
      document.body.style.overflowY = 'auto';
      setState({ sideNav: '' });
    }
  };

  const setUnseenNotifications = (unseenNotifications: number) => setState({ unseenNotifications });
  const onNewProgramChange = (newProgram: number) => setState({ newProgram });

  const values = {
    loading:
      state.loading ||
      programs.loading ||
      currentProgram.loading ||
      lastEpisode.loading ||
      (currentEpisode.loading && state.newProgram !== currentProgram.data.id),
    setLoading: (loading: boolean) => setState({ loading }),
    programs: { data: programs.data.results, getData: getPrograms },
    currentProgram: {
      program: { data: currentProgram.data, setData: currentProgram.setData, getData: getCurrentProgramData },
      chapters: { data: currentProgramChapters.data.results, loading: currentProgramChapters.loading },
      lastEpisode: lastEpisode.data,
    },
    currentEpisode: {
      data: currentEpisode.data,
      setData: currentEpisode.setData,
      request: currentEpisode.request,
      loading: currentEpisode.loading,
    },
    notifications: {
      data: notifications.data.results,
      loading: notifications.loading,
      setNotifications: notifications.setData,
      unseen: state.unseenNotifications,
      setUnseen: setUnseenNotifications,
    },
    newProgram: {
      id: state.newProgram,
      setId: onNewProgramChange,
    },
    sideNav: state.sideNav,
    onSideNavChange: onSideNavChange,
  };

  return <CommunityContext.Provider value={values}>{children(values)}</CommunityContext.Provider>;
};
