import React, { useCallback, useContext, useMemo } from 'react';
import { Button, Textarea, useNotify } from 'ebs-design';
import cn from 'classnames';
import { ProgramReadOnly } from 'libs/http/api/education/education.types';
import { Rating, HeadTitle } from 'ui/atoms';
import { ProgressBar } from 'ui/atoms/ProgressBar/ProgressBar';
import { useStateHandlers, useIntl } from 'hooks';
import { education } from 'libs/http/api/education/education';
import { useRequest } from 'estafette';
import { UserContext } from 'contexts/UserContext';
import { Star } from 'resources';

interface Props {
  program: ProgramReadOnly;
  onSubmit: () => void;
}

const initialState = { text: '', rating: -1, textError: '', ratingError: '', addReview: false };

export const ReviewsTab = ({ program, onSubmit }: Props) => {
  const { t } = useIntl();
  const { logged } = useContext(UserContext);
  const notify = useNotify();

  const review = useRequest();

  const [state, setState] = useStateHandlers(initialState);

  const onTextChange = (text: string) => setState({ text });
  const onPreSelectedRatingOver = useCallback((rating: number) => setState({ rating: rating }), []);
  const onReviewSubmit = () => {
    review
      .request(education.postReview.action(program.id, { text: state.text, rating: state.rating }))
      .then(() => {
        notify.success({ title: t('success'), description: t('added_review') });
        onSubmit();
        setState(initialState);
      })
      .catch(({ text, rating }) => setState({ textError: text, ratingError: rating }));
  };

  const maxReview = useMemo(() => {
    return Math.max(...Object.values(program.reviews_statistic?.stats || {}));
  }, [program.reviews_statistic]);

  const handleAddReview = () => {
    setState({ addReview: true });
  };

  return (
    <div className="reviews-tab">
      <div className="reviews-tab__rating-wrapper">
        <HeadTitle className="reviews-tab__title">{t('rating')}</HeadTitle>
        <div className="reviews-tab__rating-body">
          <div className="reviews-tab__rating-body-wrapper">
            <div className="reviews-tab__rating-body__details">
              {Boolean(program?.reviews_statistic) &&
                Object.keys(program?.reviews_statistic?.stats)
                  .reverse()
                  .map((i, k) => (
                    <div key={k} className="reviews-tab__rating-body__detailed">
                      <div className="reviews-tab__rating-body__stars">
                        {i}
                        <Star />
                      </div>
                      <div className="reviews-tab__rating-body__progress-bar">
                        <ProgressBar progress={(program.reviews_statistic?.stats[i] * 100) / maxReview} />
                      </div>
                      <div className="reviews-tab__rating-body__stars">{program.reviews_statistic?.stats[i]}</div>
                    </div>
                  ))}
            </div>
            <div className="reviews-tab__rating-body__rating">
              <div className="reviews-tab__rating-body__rating-wrapper">
                <div className="reviews-tab__rating-body__rating-big">{program.reviews_statistic?.average}</div>
                <Rating className="reviews-tab__rating-body__rating-stars" value={program.reviews_statistic?.average} />
              </div>
              <span className="reviews-tab__rating-body__rating-text">
                {program?.reviews_statistic?.users} {t('customersReviewed')}
              </span>
            </div>
          </div>
          {Boolean(logged && program.access_status === 'subscribed') && (
            <>
              {!state.addReview ? (
                <div className="reviews-tab__add-rating">
                  <Button type="primary" onClick={handleAddReview}>
                    {t('add_review')}
                  </Button>
                </div>
              ) : (
                <div className="reviews-tab__add-review">
                  <HeadTitle className="reviews-tab__title">{t('add_review')}</HeadTitle>
                  <div className="">
                    <Textarea value={state.text} onChange={onTextChange} placeholder={t('write_your_experience')} />
                    {Boolean(state.textError) && <div className="error">{state.textError}</div>}
                    <div>
                      <div>
                        <div>{t('selectARating')}</div>
                        <div className="reviews-tab__add-review__star">
                          {Array.from({ length: 5 }, (i, key) => ++key).map((i) => (
                            <Star
                              onMouseOver={() => onPreSelectedRatingOver(i)}
                              className={cn({ filled: state.rating >= i })}
                            />
                          ))}
                        </div>
                      </div>
                      {Boolean(state.ratingError) && <div className="error">{state.ratingError}</div>}
                    </div>
                    <div className="reviews-tab__add-rating">
                      <Button type="primary" size="large" onClick={onReviewSubmit} loading={review.loading}>
                        {t('send_review')}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
