import { useIntl } from 'estafette-intl';
import { Section } from '../components/atoms/Section';
import { SetNewPassForm } from '../components/molecules/SetNewPassForm';
import { users } from 'libs/http/api';
import { useClearCredentials } from 'hooks';

export const ConfirmAdminInvitePage = () => {
  const { t } = useIntl();

  useClearCredentials();

  return (
    <Section label={t('confirmAdminInvite')}>
      <SetNewPassForm apiRequest={users.confirmAdminInvite.action} />
    </Section>
  );
};
