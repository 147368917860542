import { useContext, useEffect } from 'react';
import { UserContext } from 'contexts/UserContext';
import { load } from 'react-cookies';

export const useClearCredentials = () => {
  const { onLogout } = useContext(UserContext);

  useEffect(() => {
    if (load('jwt-token')) {
      onLogout();
    }
  }, []);
};
