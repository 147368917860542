import React from 'react';
import cn from 'classnames';
import { Space } from 'ebs-design';
import { useIntl as $useIntl } from 'hooks';
import { Star } from 'resources';

interface Props {
  value: number;
  className?: string;
}

export const Rating = ({ value, className }: Props) => {
  const { t } = $useIntl();
  const rating = Array.from({ length: Math.ceil(4.3) }).map((i, key) => {
    if (key + 1 <= value) {
      return <Star className="rating__star rating__star-filled" key={key} />;
    }

    return <Star className="rating__star" key={key} />;
  });

  return (
    <Space size={6} className={cn('rating', className)}>
      {rating}
      {!value && <span className="rating__no-reviews">{t('noReviewsYet')}</span>}
    </Space>
  );
};
