import React, { useEffect } from 'react';
import { Loader } from 'ebs-design';
import { useIntl } from 'estafette-intl';
import { useRequest } from 'estafette';
import { users } from 'libs/http/api/users/users';
import { WithPagination } from 'libs/http/api/index.types';
import { UserOrders } from 'libs/http/api/users/users.types';
import { Container, HeadTitle } from 'ui/atoms';
import { ProgramCardWide } from 'ui/organisms/ProgramCardWide/ProgramCardWide';

export const MyOrdersPage: React.FC = () => {
  const { t } = useIntl();
  const userOrder = useRequest<WithPagination<UserOrders>>({ data: {} });

  useEffect(() => {
    userOrder.request(users.getOrderUser.action());
  }, []);

  return (
    <>
      <Container className="my-order-page">
        <HeadTitle>{t('Purchases')}</HeadTitle>
        <Loader loading={userOrder.loading}>
          {userOrder.data.results &&
            userOrder.data.results.map((i) => (
              <ProgramCardWide key={i.id} image={i.price.product.image}>
                <ProgramCardWide.Order order={i} />
              </ProgramCardWide>
            ))}
        </Loader>
      </Container>
    </>
  );
};
