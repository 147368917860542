import { Space } from 'ebs-design';
import { useIntl } from 'estafette-intl';
import { HeadTitle } from 'ui/atoms';
import { Clock, Mail, Phone } from 'resources';

interface ContactsProps {
  contacts: {
    image: string;
    name: string;
    jobName: string;
    phone: string;
    program: string;
    email: string;
    language: string;
    signature: string;
  };
}

export const Contacts = ({ contacts }: ContactsProps) => {
  const { t } = useIntl();

  return (
    <div className="contacts__wrapper">
      <Space justify="space-around" align="start" className="contacts" size={20}>
        <div className="contacts__need-help">
          <HeadTitle>{t('needHelp')}</HeadTitle>
          <span className="contacts__need-help-description">{t('needCustomerCare')}</span>
        </div>
        <div>
          {contacts && (
            <>
              <Space className="contacts__carrier" size={30}>
                <div className="contacts__carrier-photo">
                  <img
                    src={contacts.image ? contacts.image : 'https://bloomanity.ro/img/customer-support-ana-maria-i.png'}
                    alt="All About Parenting"
                    className=""
                  />
                </div>
                <div className="contacts__carrier-details">
                  <span className="contacts__carrier-full-name">
                    {contacts.name ? contacts.name : 'Ana Maria Iancu'}
                  </span>
                  <span className="contacts__carrier-job-title">
                    {contacts.jobName ? contacts.jobName : 'Customer Care Fairy'}
                  </span>
                </div>
              </Space>
              <div className="contacts__carrier-contact">
                {contacts.program && (
                  <Space className="contacts__carrier-contact__item" align="start" size={15}>
                    <Clock />
                    <div dangerouslySetInnerHTML={{ __html: contacts.program }} />
                  </Space>
                )}
                {contacts.email && (
                  <Space className="contacts__carrier-contact__item" align="start" size={15}>
                    <Mail />
                    <a href={`mailto:contact@${contacts.email}`}>{contacts.email}</a>
                  </Space>
                )}
                {contacts.phone && (
                  <Space className="contacts__carrier-contact__item" align="start" size={15}>
                    <Phone />
                    <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
                  </Space>
                )}
              </div>
            </>
          )}
        </div>
      </Space>
    </div>
  );
};
