import React from 'react';
import { Col, Row } from 'ebs-design';

export const ProgressBar = ({ progress }: { progress: number }) => {
  return (
    <Row className="p-0">
      <Col className="m-progress-bar p-0">
        <Row className="p-0">
          <Col className="p-0">
            <div className="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default">
              <div className="ant-progress-outer">
                <div className="ant-progress-inner">
                  <div className="ant-progress-bg" style={{ width: `${progress}%` }} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
