import { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { CreateIntl } from 'estafette-intl';
import { NotifyProvider } from 'ebs-design';
import { load, save } from 'react-cookies';
import { messages } from 'libs/translations/messages';
import { Layout } from 'ui/organisms/Layout/Layout';
import { UserProvider } from 'contexts/UserContext';
import { BrowserRouter } from 'react-router-dom';
import { RouterSwitch } from 'ui/molecules/RouterSwitch/RouterSwitch';
import { defaultLang } from 'libs/globalVars';
import { BreadcrumbsProvider } from 'contexts/BreadcrumbsContext';
import { ResponsiveProvider } from 'contexts/ResponsiveContext';
import { GoogleTagManagerProvider } from 'contexts/GoogleTagManagerContext';
import { StylesProvider } from 'contexts/StylesContext';
import { QAProvider } from 'contexts/QAContext';
import { useFirebaseMessaging } from 'hooks/useFirebaseMessaging';
import { setTokens, StorageKeys } from 'libs/cookies';
import { getActiveRouteByPath } from 'libs/utils';
import { parseQuery } from 'libs/object';

import 'assets/scss/index.scss';

const App = () => {
  const { language } = parseQuery<{ language?: string }>(window.location.search);
  const savedLanguage = load(StorageKeys.localization);

  const { initializeFirebaseApp } = useFirebaseMessaging();

  useEffect(() => {
    const storage = load(StorageKeys.parentsWeb);

    if (!storage) {
      save(StorageKeys.parentsWeb, {}, { path: '/' });
    }

    if (language) {
      setTokens({ language });
    }

    initializeFirebaseApp();
  }, []);

  const activeRouteName = useMemo(() => getActiveRouteByPath(window.location.pathname), [window.location.pathname]);

  return (
    <NotifyProvider>
      <ResponsiveProvider>
        <StylesProvider>
          <UserProvider>
            {({ userData }) => (
              <CreateIntl
                defaultLocale={userData.language || language || savedLanguage || defaultLang}
                messages={messages}
              >
                <BreadcrumbsProvider>
                  <QAProvider>
                    <BrowserRouter>
                      <GoogleTagManagerProvider>
                        {activeRouteName ? (
                          <Layout activeRouteName={activeRouteName}>
                            <RouterSwitch />
                          </Layout>
                        ) : (
                          <RouterSwitch />
                        )}
                      </GoogleTagManagerProvider>
                    </BrowserRouter>
                  </QAProvider>
                </BreadcrumbsProvider>
              </CreateIntl>
            )}
          </UserProvider>
        </StylesProvider>
      </ResponsiveProvider>
    </NotifyProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
