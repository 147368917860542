import React, { useState } from 'react';
import { useIntl } from 'estafette-intl';
import { OrderProps } from './types';
import { format } from 'libs/date';
import { ConfirmModal } from 'ui/molecules/ConfirmModal/ConfirmModal';
import { Clock, WarningCircle } from 'resources';

const payURL = process.env.REACT_APP_CHECKOUT_URL;

export const Order: React.FC<OrderProps> = ({ order }) => {
  const { t, locale } = useIntl();
  const [retry, setRetry] = useState(false);

  const handleToggle = () => {
    setRetry((s) => !s);
  };

  const handleGoToInvoice = () => {
    if (order.failed_invoice.id) window.open(`${payURL}/checkout/pay-invoice/${order.failed_invoice.id}`);
    if (order.failed_invoice_url) window.open(order.failed_invoice_url);
  };

  return (
    <>
      {retry && (
        <ConfirmModal
          title={t('retry_payment')}
          bodyText={
            <>
              {t('retryPaymentDescription1')}
              <br />
              {t('retryPaymentDescription2')}
            </>
          }
          okButtonText={t('Retry')}
          cancelButtonText={t('Cancel')}
          handleOk={handleGoToInvoice}
          handleToggle={handleToggle}
        />
      )}
      <div className="program-card-wide__header program-card-wide__header-border">
        <div className="program-card-wide__header-title">
          <div className="program-card-wide__name">{order.price.product.name}</div>
          {order.payment_failed && (order.failed_invoice.id || order.failed_invoice_url) && (
            <div className="program-card-wide__error-payment" onClick={handleToggle}>
              <WarningCircle />
            </div>
          )}
        </div>
        {order.start && (
          <div className="program-card-wide__started">
            <Clock />
            {t('startedOn', { date: format(order.start, 'D MMM YYYY', locale) })}
          </div>
        )}
      </div>
      <div className="program-card-wide__footer">
        <div className="program-card-wide__attributes">
          <div className="program-card-wide__attribute">
            {t('offer_type')} {t(order.price.price_type)}
          </div>
          {(order.paid_installments || order.price.installments) && (
            <div className="program-card-wide__attribute">
              {t('number_of_payments')} {order.paid_installments || 0}/{order.price.installments || 1}
            </div>
          )}
          {order.billing_status && (
            <div className="program-card-wide__attribute">
              {t('order_status')}: {t(`billing_status_${order.billing_status}`)}
            </div>
          )}
          {order.next_debit_date && (
            <div className="program-card-wide__attribute">
              {t('next_debit')} {format(order.next_debit_date, 'D MMM YYYY', locale)}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
