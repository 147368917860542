import React from 'react';
import cn from 'classnames';
import { ArrowLeft, ArrowRight } from 'resources';
import { useNrOfSlidesByScreenWidth } from 'hooks';

interface Props {
  next?: () => void;
  previous?: () => void;
  count: number;
  carouselState?: { currentSlide: number };
}

export const CarouselButtonGroup: React.FC<Props> = ({ next, previous, count, carouselState }) => {
  const { nrOfSlides } = useNrOfSlidesByScreenWidth();

  return (
    <div className="carousel-buttons">
      <div className={cn('carousel-buttons__left', { disabled: !carouselState?.currentSlide })} onClick={previous}>
        <ArrowLeft />
      </div>
      <div
        className={cn('carousel-buttons__right', {
          disabled: count < nrOfSlides + 1 || carouselState?.currentSlide === count - nrOfSlides,
        })}
        onClick={next}
      >
        <ArrowRight />
      </div>
    </div>
  );
};
