import { Dispatch, SetStateAction } from 'react';
import { useStateHandlers } from '.';
import { $Object } from 'libs/globalTypes';
import { load, save } from 'react-cookies';
import { StorageKeys } from 'libs/cookies';

type Result<T extends object> = {
  storage: T;
  setStorage: Dispatch<SetStateAction<T>>;
  onStorageChange: (newValues: $Object) => void;
};

export const useCookies = <T extends object>(initialFilters: T, keyword: string): Result<T> => {
  const storedValues = load(StorageKeys.parentsWeb) || {};

  const [storage, setStorage] = useStateHandlers({ ...initialFilters, ...storedValues[keyword] });

  const onStorageChange = (newValues: $Object) => {
    const newFilters = { ...storage, ...newValues };

    setStorage(newFilters);
    save(StorageKeys.parentsWeb, { ...storedValues, [keyword]: newFilters }, { path: '/' });
  };

  return { storage, setStorage, onStorageChange };
};
