import { useContext, useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import { Avatar, Loader, Space } from 'ebs-design';
import { useStateHandlers, useTimeUtils } from 'hooks';
import { UserContext } from 'contexts/UserContext';
import { WriteComment } from '../WriteComment/WriteComment';
import { EditComment } from '../EditComment/EditComment';
import { education } from 'libs/http/api/education/education';
import { clearUserInfo } from 'libs/utils';
import { useIntl } from 'hooks/useIntl';
import { Comment as CommentProps } from 'libs/http/api/education/education.types';
import { Heart } from 'resources';

interface Props {
  comment: CommentProps;
  noReply?: boolean;
  increaseCommentCount: () => void;
}

export const Comment = ({ comment, noReply = false, increaseCommentCount }: Props) => {
  const { getDiffFromToday } = useTimeUtils();
  const { userData } = useContext(UserContext);
  const { t, tPlural } = useIntl();
  const ref = useRef<HTMLDivElement>(null);

  const [state, setState] = useStateHandlers<{
    text: string;
    showReply: boolean;
    editComment: boolean;
    readMore: boolean;
    refIsVisible: boolean;
    replies: any[];
    comment: CommentProps;
    loading: boolean;
    showReplies: boolean;
  }>({
    text: comment.text,
    showReply: false,
    editComment: false,
    readMore: false,
    refIsVisible: false,
    replies: [comment.last_reply],
    comment: comment,
    loading: false,
    showReplies: false,
  });

  const onEditComment = () => setState((prevState) => ({ editComment: !prevState.editComment }));
  const onShowReplyChange = () => setState((prevState) => ({ showReply: !prevState.showReply }));
  const onReplyAdd = (newReply: any) => setState((prevState) => ({ replies: [...prevState.replies, newReply] }));
  const onFinishEdit = (comment: any) => setState({ text: comment.text, editComment: false });
  const onShowPreviousClick = () => {
    setState({ loading: true });

    education.getReplies
      .action(comment.id)
      .then(({ data }) => {
        if (data.results && data.results.length > 0) {
          setState({ replies: data.results });
        }
      })
      .finally(() => setState({ loading: false }));
  };

  const handleReadMore = () => {
    setState({ readMore: true });
  };

  const likeComment = () => {
    if (state.comment.is_liked) {
      setState((prev) => ({
        comment: {
          ...comment,
          is_liked: false,
          reactions_count: prev.comment.reactions_count - 1,
        },
      }));
      education.unLikeComment.action(Number(comment.id));
    } else {
      setState((prev) => ({
        comment: {
          ...comment,
          is_liked: true,
          reactions_count: prev.comment.reactions_count + 1,
        },
      }));
      education.likeComment.action(Number(comment.id));
    }
  };

  const isEllipsisActive = useMemo(() => {
    if (!ref?.current) return false;
    return ref.current.offsetHeight < ref.current.scrollHeight;
  }, [ref, state.refIsVisible]);

  useEffect(() => {
    setTimeout(() => setState({ refIsVisible: true }), 500);
  }, []);

  const isAdmin = useMemo(() => comment.author.groups.length > 0, [comment]);

  return (
    <div className={cn('comment', { replay: noReply })}>
      <div className={cn('comment__avatar', { 'is-admin': isAdmin })}>
        <Avatar circle img={comment && comment.author.photo} alt={comment && comment.author.full_name} />
      </div>
      <div className="comment__body">
        <div
          className={cn('comment__body__text-wrapper', {
            bordered: state.replies.filter((i) => i).length > 0 || state.showReply,
          })}
        >
          {comment && (
            <div className="comment__avatar__full-name">
              <div>{comment.author.full_name}</div>
              {comment.author.groups.length > 0 && (
                <div className="comment__avatar__position">{t('parenting_expert')} ⭐</div>
              )}
            </div>
          )}
          {state.text &&
            (state.editComment ? (
              <EditComment comment={{ ...comment, text: state.text }} callback={onFinishEdit} />
            ) : (
              <div ref={ref} className={cn('comment__body__text', { 'read-more': state.readMore })}>
                {clearUserInfo(state.text)}
              </div>
            ))}
          {!state.readMore && isEllipsisActive && (
            <div className="comment__body__text__read-more" onClick={handleReadMore}>
              {t('readMore')}
            </div>
          )}
        </div>
        <div className="comment__footer">
          <Space justify="space-between">
            <Space justify="start" size={20}>
              <div className="comment__avatar__created-at">{getDiffFromToday(comment.created_at) || 'now'}</div>
              <div className="comment__avatar__created-at">
                {state.comment.reactions_count} {t('likes')}
              </div>
              {!noReply &&
                (comment.children_count > 1 && state.replies && state.replies.length < 2 ? (
                  <div className="comment__avatar__created-at cursor-pointer" onClick={onShowPreviousClick}>
                    {tPlural('view_pervious_replies', comment.children_count - 1)}
                  </div>
                ) : (
                  (userData?.id === comment.author?.id || userData.groups?.length) && (
                    <div className="comment__avatar__created-at cursor-pointer" onClick={onShowReplyChange}>
                      {t(state.showReply ? 'hide' : 'reply')}
                    </div>
                  )
                ))}
              {userData?.id === comment.author?.id && !state.editComment && (
                <div className="comment__avatar__created-at cursor-pointer" onClick={onEditComment}>
                  {t('edit')}
                </div>
              )}
            </Space>
            <div onClick={likeComment} className="comment__footer__reaction">
              <Heart className={cn({ liked: state.comment.is_liked })} />
            </div>
          </Space>
        </div>
        {!noReply && (state.replies.filter((i) => i).length > 0 || state.showReply) && (
          <div className="comment__children">
            <Loader loading={state.loading}>
              {!noReply &&
                state.replies.length > 0 &&
                state.replies
                  .filter((i) => i)
                  .map((i, k) => (
                    <Comment
                      increaseCommentCount={increaseCommentCount}
                      comment={i}
                      key={`child-comment-${k}`}
                      noReply
                    />
                  ))}
            </Loader>
            {!noReply && state.showReply && (
              <div>
                <WriteComment increaseCommentCount={increaseCommentCount} replyTo={comment.id} callback={onReplyAdd} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
