import { createContext, ReactElement } from 'react';
import TagManager from 'react-gtm-module';

const gtmId = process.env.REACT_APP_GTMID || '';

export enum GoogleTagEvents {
  BANNER_CLICK = 'banner-click',
}

interface Props {
  addDataLayer: (event: any) => void;
}

export const GoogleTagManagerContext = createContext<Props>({
  addDataLayer: () => {},
});

export const GoogleTagManagerProvider = ({ children }: { children: ReactElement }): ReactElement => {
  TagManager.initialize({
    gtmId,
  });

  const { dataLayer }: { dataLayer?: any[] } = window as any;
  const addDataLayer = (tagEvent: { event: GoogleTagEvents }) => {
    dataLayer?.push(tagEvent);
  };

  return <GoogleTagManagerContext.Provider value={{ addDataLayer }}>{children}</GoogleTagManagerContext.Provider>;
};
