import { useState } from 'react';
import { Input } from 'ebs-design';
import { InputProps } from 'ebs-design/dist/components/atoms/Input/Input';
import { Eye, HideEye } from 'resources';

export const PasswordInput = ({ type, ...props }: InputProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const onShowPasswordChange = () => setShowPassword((prevState) => !prevState);

  return (
    <Input
      type={showPassword ? 'text' : 'password'}
      {...props}
      suffix={showPassword ? <HideEye onClick={onShowPasswordChange} /> : <Eye onClick={onShowPasswordChange} />}
    />
  );
};
