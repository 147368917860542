import { FC } from 'react';

interface CardsContainerProps {
  className?: string;
}

export const Container: FC<CardsContainerProps> = ({ className, children }) => (
  <div className={`card-container ${className}`}>
    <div className="card-container__wrapper">{children}</div>
  </div>
);
