import React, { useContext } from 'react';
import { useIntl } from 'estafette-intl';
import { Col, Row, Space, Tooltip, useNotify } from 'ebs-design';
import dayjs from 'dayjs';
import { EventActionButton } from '../Event/EventActionButton';
import { Attending } from 'ui/molecules/Attending/Attending';
import { Icon } from 'ui/atoms/Icon/Icon';
import { UserContext } from 'contexts/UserContext';
import { addToURL, getEventSubTitle, isHistory } from 'libs/utils';
import { format } from 'libs/date';
import { events } from 'libs/http/api/events/events';
import { EventsProps } from './types';
import { PaperAirPlane, UserIcon } from 'resources';
import { EventLink } from './ProgramCard';

export const Events: React.FC<EventsProps> = ({ webinar, isEnrolled }) => {
  const { t, locale } = useIntl();
  const notify = useNotify();
  const { userData } = useContext(UserContext);

  const onCopyEpisode = () => {
    if (userData.id) {
      events.shareLink
        .action({
          type: 'webinar_card',
          url: webinar.quiz_url,
        })
        .then(({ data }) => {
          navigator.clipboard.writeText(data.url).then(
            () => notify.success({ title: t('success'), description: t('copied') }),
            () => notify.success({ title: t('error'), description: t('cant_copy') }),
          );
        })
        .catch((e) => {
          notify.error({ title: t('error'), description: e.message });
        });
    } else {
      navigator.clipboard.writeText(addToURL(webinar.quiz_url, { source_type: 'referral' })).then(
        () => notify.success({ title: t('success'), description: t('copied') }),
        () => notify.success({ title: t('error'), description: t('cant_copy') }),
      );
    }
  };

  return (
    <div className="program-card__body">
      <div className="program-card__body__title__event-custom">
        <EventLink isEnrolled={isEnrolled} {...webinar}>
          <span className="program-card__body__title">{webinar.title}</span>
        </EventLink>
      </div>
      <div className="program-card__body__footer">
        <Space justify="space-between" align="start" className="program-card__timeline">
          {webinar.date && (
            <div className="program-card__timeline__time">
              {`${format(webinar.date, 'dddd, MMMM D', locale)}`}
              <br />
              {`${format(webinar.date, 'HH:mm')} - ${dayjs(webinar.date).add(2, 'hours').format('HH:mm')}`}
            </div>
          )}
          {!isHistory(webinar) && (
            <Space align="start">
              <div className="program-card__timeline__live">{t('live')}</div>
              <Tooltip
                placement="top"
                trigger="click"
                tooltip={
                  <div className="m-copy-episode">
                    <Row>
                      <Col className="m-copy-link" size={10}>
                        {t('copy_link')}
                      </Col>
                      <Col className="p-0" size={2}>
                        <Icon type="copy-icon" onClick={onCopyEpisode} />
                      </Col>
                    </Row>
                  </div>
                }
              >
                <PaperAirPlane className="program-card__timeline__send" />
              </Tooltip>
            </Space>
          )}
        </Space>
        <Space justify="space-between">
          <div>
            <div className="program-card-wide__footer__status">{getEventSubTitle(webinar, t, isEnrolled)}</div>
            {Boolean(webinar.attending) ? (
              <div className="program-card-wide__footer__attending">
                <Attending users={webinar.latest_attending} />
                {webinar.attending} {t('attending')}
              </div>
            ) : (
              <div className="program-card-wide__footer__attending">
                <div className="program-card__user-icon attending">
                  <UserIcon />
                </div>
                {t('noAttendeesYet')}
              </div>
            )}
          </div>
          <EventActionButton webinar={webinar} isEnrolled={isEnrolled} />
        </Space>
      </div>
    </div>
  );
};
