import { FC } from 'react';
import { Row } from 'ebs-design';

interface Props {
  label: string;
  style?: object;
}

export const Section: FC<Props> = ({ label, children, style }) => {
  return (
    <Row className="m-section">
      <Row className="p-0">
        <Row className="p-0 m-section-name" style={style}>
          {label}
        </Row>
        <Row className="p-0">
          <div className="m-bar" />
        </Row>
      </Row>
      <Row className="m-section-content p-0">{children}</Row>
    </Row>
  );
};
