import React, { useMemo } from 'react';
import { CarouselButtonGroup } from 'ui/organisms/Carousel/CarouselButtonGroup';
import { ProgramReadOnly } from 'libs/http/api/education/education.types';
import { ProgramCard } from 'ui/organisms/ProgramCard/ProgramCard';
import { Carousel } from 'ui/organisms/Carousel/Carousel';

interface Props {
  programs: ProgramReadOnly[];
}

export const ProgramsCarousel: React.FC<Props> = ({ programs }) => {
  const carouselCards = useMemo(
    () =>
      programs.map((i) => (
        <ProgramCard is_video={true} id={i.id} image={i.cover_photo} title={i.title} key={i.id}>
          <ProgramCard.Program program={i} />
        </ProgramCard>
      )),
    [programs],
  );

  return (
    <Carousel
      items={carouselCards}
      className="programs-carousel"
      buttonGroup={<CarouselButtonGroup count={carouselCards.length} />}
    />
  );
};
