import React from 'react';
import { UserIcon } from 'resources';

interface Props {
  users: { avatar?: string }[];
  length?: number;
}

export const Attending: React.FC<Props> = ({ users, length = 3 }) => {
  return (
    <div className="attending">
      {users.slice(0, length).map((i, k) =>
        i.avatar ? (
          <div key={k} className="attending__img" style={{ backgroundImage: `url('${i.avatar}')` }} />
        ) : (
          <div key={k} className="attending__icon">
            <UserIcon />
          </div>
        ),
      )}
    </div>
  );
};
