import { RefObject, useEffect, useRef, useState } from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';

export type VideoJsConfig = {
  src?: string | null;
  width?: number;
  height?: number;
  fluid?: boolean;
  fill?: boolean;
  muted?: boolean;
  posterUrl?: string | null;
  startTime?: number;
  onDataLoaded?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  onEnd?: () => void;
  onTimeUpdated?: (time: number) => void;
};

type VideoJsPlayerHook = (config: VideoJsConfig) => [VideoJsPlayer | null, RefObject<HTMLVideoElement>];

export const useVideoJsPlayer: VideoJsPlayerHook = ({
  fill,
  fluid,
  height,
  src,
  width,
  muted = false,
  posterUrl,
  startTime = 0,
  onDataLoaded,
  onPlay,
  onPause,
  onEnd,
  onTimeUpdated,
}) => {
  const playerRef = useRef<HTMLVideoElement>(null);

  const [player, setPlayer] = useState<VideoJsPlayer | null>(null);

  useEffect(() => {
    const videoJsOptions: VideoJsPlayerOptions = {
      controlBar: {
        volumePanel: false,
        playToggle: false,
        captionsButton: false,
        chaptersButton: false,
        subtitlesButton: false,
        remainingTimeDisplay: false,
        progressControl: false,
        fullscreenToggle: false,
        playbackRateMenuButton: false,
        pictureInPictureToggle: false,
        currentTimeDisplay: false,
        timeDivider: false,
        durationDisplay: false,
        liveDisplay: false,
        seekToLive: false,
        customControlSpacer: false,
        descriptionsButton: false,
        subsCapsButton: false,
        audioTrackButton: false,
      },
      controls: true,
      bigPlayButton: false,
      nativeControlsForTouch: true,
    };

    const playerInstance = videojs(playerRef.current || '', videoJsOptions);

    setPlayer(playerInstance);

    return () => {
      playerInstance.dispose();
    };
  }, []);

  useEffect(() => {
    if (!player || !src) {
      return;
    }
  }, [player, src]);

  useEffect(() => {
    if (!player || !width) {
      return;
    }

    player.width(width);
  }, [player, width]);

  useEffect(() => {
    if (!player || !height) {
      return;
    }

    player.height(height);
  }, [player, height]);

  useEffect(() => {
    if (!player) {
      return;
    }

    player.fluid(Boolean(fluid));
  }, [player, fluid]);

  useEffect(() => {
    if (!player) {
      return;
    }

    // @ts-ignore @types/video.js is outdated and doesn't provide types for some newer video.js features
    player.fill(Boolean(fill));
  }, [player, fill]);

  useEffect(() => {
    if (!player) {
      return;
    }

    player.muted(muted);
  }, [player]);

  useEffect(() => {
    if (!player || !posterUrl) {
      return;
    }

    player.poster(posterUrl);
  }, [player, posterUrl]);

  useEffect(() => {
    if (!player || !onDataLoaded) {
      return;
    }

    player.on('loadeddata', onDataLoaded);

    return () => {
      player.off('loadeddata', onDataLoaded);
    };
  }, [player, onDataLoaded]);

  useEffect(() => {
    if (!player || !startTime) {
      return;
    }

    player.currentTime(startTime);
  }, [player, startTime]);

  useEffect(() => {
    if (!player || !onPlay) {
      return;
    }

    player.on('play', onPlay);

    return () => {
      player.off('play', onPlay);
    };
  }, [player, onPlay]);

  useEffect(() => {
    if (!player || !onPause) {
      return;
    }

    player.on('pause', onPause);

    return () => {
      player.off('pause', onPause);
    };
  }, [player, onPause]);

  useEffect(() => {
    if (!player || !onEnd) {
      return;
    }
    player.on('ended', onEnd);

    return () => player.off('ended', onEnd);
  }, [player, onEnd]);

  useEffect(() => {
    if (!player || !onTimeUpdated) {
      return;
    }
    const handler = () => onTimeUpdated(player.currentTime());
    player.on('timeupdate', handler);

    return () => player.off('timeupdate', handler);
  }, [onTimeUpdated, player]);

  return [player, playerRef];
};
