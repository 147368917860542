import React /*, { useCallback, useContext, useEffect, useMemo, useState }*/ from 'react';
import { Redirect /*, useHistory, useParams*/ } from 'react-router-dom';
// import cn from 'classnames';
// import { useRequest } from 'estafette';
// import { useIntl } from 'estafette-intl';
// import { Row, Col, Chips, Space, Collapse, Loader, Button } from 'ebs-design';
// import { Animated, BackBreadCrumbs, Container, HeadTitle } from 'ui/atoms';
// import { QAContext } from 'contexts/QAContext';
// import { NotFoundPage } from 'features/notFound/pages/NotFoundPage';
// import { Search } from 'features/qa/components';
// import { qa as api } from 'libs/http/api';
// import { QAItem } from 'libs/http/api/qa/qa.types';
// import { setHTMLClassName } from 'libs/utils';
//
// import { Banner, Webinars } from '../../components';
// import { Store } from '../List';

export const QADetails: React.FC = () => {
  return <Redirect to="/" />;
  // const { t, locale, setLocale } = useIntl();
  // const { id } = useParams<{ id: string }>();
  // const { push } = useHistory();
  // const { queryId, position, userToken, setHeader, setData } = useContext(QAContext);
  // const [sourcesCollapsed, setSourcesCollapsed] = useState(true);
  //
  // const [expandQuestion, setExpandQuestion] = useState(false);
  // const [query, setQuery] = useState('');
  // const [recentSearches, setRecentSearches] = useState(
  //   localStorage.getItem(Store.RECENT) ? JSON.parse(localStorage.getItem(Store.RECENT) as string) : [],
  // );
  //
  // const { request, data, loading } = useRequest<QAItem>({ data: {} });
  //
  // useEffect(() => {
  //   setHTMLClassName('qa-details-page');
  //
  //   return () => {
  //     setHTMLClassName('');
  //   };
  // }, []);
  //
  // const onResetRecent = () => {
  //   setRecentSearches([]);
  //   localStorage.setItem(Store.RECENT, JSON.stringify([]));
  // };
  //
  // const onEnter = useCallback((value) => {
  //   setData([]);
  //   localStorage.removeItem(Store.ID);
  //   push(`/q-and-a?query=${value}`);
  // }, []);
  //
  // useEffect(() => {
  //   document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
  //     anchor.addEventListener('click', function (e) {
  //       e.preventDefault();
  //
  //       setSourcesCollapsed(false);
  //
  //       setTimeout(() => {
  //         const el = document.getElementById(anchor.getAttribute('href')?.slice(1) as string);
  //
  //         el && window.scrollTo({ top: el.offsetTop - 100, behavior: 'smooth' });
  //       }, 250);
  //     });
  //   });
  // }, [loading]);
  //
  // useEffect(() => {
  //   setHeader((toggle: () => void) => {
  //     const onEnterHandler = (value: string) => {
  //       onEnter(value);
  //       toggle();
  //     };
  //
  //     return (
  //       <Search
  //         value={query}
  //         recentSearches={recentSearches}
  //         defaultOpened={false}
  //         hideMobileFilters
  //         onEnter={onEnterHandler}
  //         onChange={setQuery}
  //         onResetRecent={onResetRecent}
  //       />
  //     );
  //   });
  // }, [query, recentSearches, onEnter]);
  //
  // useEffect(() => {
  //   if (locale !== 'ro') {
  //     setLocale('ro');
  //     localStorage.setItem('locale', 'ro');
  //   }
  // }, [locale]);
  //
  // useEffect(() => {
  //   request(
  //     queryId
  //       ? api.getQAItem.action(parseInt(id), {
  //           user: userToken,
  //           query_id: queryId,
  //           object_ids: [id],
  //           positions: [position?.toString()],
  //         })
  //       : api.getQATranslate.action(parseInt(id)),
  //   );
  //
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  // }, []);
  //
  // const sources = useMemo(
  //   () =>
  //     data?.sources?.reduce((prev, curr, i) => {
  //       const index = curr.match(/\[(.*?)\]/);
  //       const [, source] = curr.split(/\[[0-9]*\] /);
  //       return { ...prev, [index ? index[1] : i]: source };
  //     }, {}),
  //   [data],
  // );
  //
  // return (
  //   <div className="qa-details dark-blue">
  //     <Container className="qa-details__container">
  //       <Row g={0}>
  //         {loading ? (
  //           <Col size={12}>
  //             <Loader loading />
  //           </Col>
  //         ) : Object.keys(data).length ? (
  //           <Animated loading={false} animateOpacity>
  //             <Row g={3}>
  //               <Col size={12}>
  //                 <BackBreadCrumbs />
  //               </Col>
  //               <Col size={12}>
  //                 <HeadTitle className="m-0">{data?.title}</HeadTitle>
  //               </Col>
  //               <Col size={12}>
  //                 <Space direction="vertical" align="start" size="small">
  //                   <Space size="small" wrap>
  //                     {data?.tags?.map((tag, i) => (
  //                       <Chips key={i} text={tag} />
  //                     ))}
  //                   </Space>
  //                   <Space size="small" wrap>
  //                     {data?.child_ages?.map((age, i) => (
  //                       <Chips
  //                         key={i}
  //                         text={`${age} ${t(locale === 'ru' && age > 4 ? `qa_yr` : `qa_year${age > 1 ? 's' : ''}`)}`}
  //                       />
  //                     ))}
  //                   </Space>
  //                 </Space>
  //               </Col>
  //               <Col size={12}>
  //                 <Space className="qa-details__container-data" direction="vertical" size="large" align="start">
  //                   <div className="qa-details__container-question">
  //                     <div className={cn('qa-details__container-question__expand', { open: expandQuestion })}>
  //                       <h3 className="mb-24">
  //                         {data.question_author
  //                           ? `${t('qa_question_from')} ${data.question_author}`
  //                           : t('qa_parent_question')}
  //                       </h3>
  //                       <div dangerouslySetInnerHTML={{ __html: data.question }} />
  //                     </div>
  //                     {!expandQuestion && (
  //                       <Space justify="end">
  //                         <Button type="text" onClick={() => setExpandQuestion(true)}>
  //                           {t('qa_read_more')}
  //                         </Button>
  //                       </Space>
  //                     )}
  //                   </div>
  //                   <h3>{t('qa_answer')}</h3>
  //                   <div
  //                     className="qa-details__container-answer"
  //                     dangerouslySetInnerHTML={{
  //                       __html: data.answer.replaceAll(
  //                         new RegExp(/\[(.*?)\] \((.*?)\)/g),
  //                         ` [<a href="#source-$1" id="link-$1">$1</a>] ($2)`,
  //                       ),
  //                     }}
  //                   />
  //                 </Space>
  //               </Col>
  //               {Object.keys(sources)?.length ? (
  //                 <Col size={12}>
  //                   <Collapse collapsed={sourcesCollapsed}>
  //                     <Collapse.Header>
  //                       {Object.keys(sources).length} {t('qa_sources')}
  //                     </Collapse.Header>
  //                     <Collapse.Body>
  //                       <Space direction="vertical" align="start">
  //                         {Object.keys(sources)?.map((num, i) => (
  //                           <div key={i} id={`source-${num}`}>
  //                             {num || i}. {sources[num as keyof typeof sources]}
  //                           </div>
  //                         ))}
  //                       </Space>
  //                     </Collapse.Body>
  //                   </Collapse>
  //                 </Col>
  //               ) : null}
  //             </Row>
  //           </Animated>
  //         ) : (
  //           <Animated loading={false} animateOpacity>
  //             <Row g={3}>
  //               <Col size={12}>
  //                 <BackBreadCrumbs />
  //               </Col>
  //               <Col size={12}>
  //                 <NotFoundPage />
  //               </Col>
  //             </Row>
  //           </Animated>
  //         )}
  //       </Row>
  //     </Container>
  //
  //     <Webinars />
  //     <Banner />
  //   </div>
  // );
};
