import {useContext, useMemo} from "react";
import {ResponsiveContext} from "../contexts/ResponsiveContext";

export const useNrOfSlidesByScreenWidth = () => {
  const { isDesktop, isFhd, isSm } = useContext(ResponsiveContext);

  const nrOfSlides = useMemo(() => {
    if (isSm) {
      return 1;
    }

    if (isDesktop) {
      return 2;
    }

    if (isFhd) {
      return 3;
    }

    return 3;
  }, [isSm, isDesktop, isFhd]);

  return { nrOfSlides };
};