import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { useIntl } from 'estafette-intl';
import { Col, Row, Space, Tooltip, useNotify } from 'ebs-design';
import { Attending } from 'ui/molecules/Attending/Attending';
import { Icon } from 'ui/atoms/Icon/Icon';
import { EventActionButton } from '../Event/EventActionButton';
import { UserContext } from 'contexts/UserContext';
import { addToURL, getEventSubTitle } from 'libs/utils';
import { format } from 'libs/date';
import { events } from 'libs/http/api/events/events';
import { EventsProps } from './types';
import { PaperAirPlane, UserIcon } from 'resources';

export const Events: React.FC<EventsProps> = ({ webinar, isEnrolled }) => {
  const { t, locale } = useIntl();
  const notify = useNotify();
  const { userData } = useContext(UserContext);

  const onCopyEpisode = () => {
    if (userData.id) {
      events.shareLink
        .action({
          type: 'webinar_card',
          url: webinar.quiz_url,
        })
        .then(({ data }) => {
          navigator.clipboard.writeText(data.url).then(
            () => notify.success({ title: t('success'), description: t('copied') }),
            () => notify.success({ title: t('error'), description: t('cant_copy') }),
          );
        })
        .catch((e) => {
          notify.error({ title: t('error'), description: e.message });
        });
    } else {
      navigator.clipboard
        .writeText(
          addToURL(webinar.quiz_url, {
            source_type: 'referral',
          }),
        )
        .then(
          () => notify.success({ title: t('success'), description: t('copied') }),
          () => notify.success({ title: t('error'), description: t('cant_copy') }),
        );
    }
  };

  return (
    <>
      <div className="program-card-wide__header events__header">
        <div className="program-card-wide__header-title">
          <div className="program-card-wide__name">{webinar.title}</div>
        </div>
        <Space justify="space-between" className="program-card-wide__timeline">
          {webinar.date && (
            <div className="program-card-wide__timeline__time">
              {`${format(webinar.date, 'dddd, MMMM D  HH:mm', locale)} - ${dayjs(webinar.date)
                .add(2, 'hours')
                .format('HH:mm')}`}
            </div>
          )}
          <Space align="center">
            <div className="program-card-wide__timeline__live">{t('live')}</div>
            <Tooltip
              placement="top"
              trigger="click"
              tooltip={
                <div className="m-copy-episode">
                  <Row>
                    <Col className="m-copy-link" size={10}>
                      {t('copy_link')}
                    </Col>
                    <Col className="p-0" size={2}>
                      <Icon type="copy-icon" onClick={onCopyEpisode} />
                    </Col>
                  </Row>
                </div>
              }
            >
              <PaperAirPlane className="program-card-wide__timeline__send" />
            </Tooltip>
          </Space>
        </Space>
      </div>
      <Space justify="space-between" align="end" className="program-card-wide__footer">
        <div>
          <div className="program-card-wide__footer__status">{getEventSubTitle(webinar, t, isEnrolled)}</div>
          {Boolean(webinar.attending) ? (
            <div className="program-card-wide__footer__attending">
              <Attending users={webinar.latest_attending} /> {webinar.attending} {t('attending')}
            </div>
          ) : (
            <div className="program-card-wide__footer__attending">
              <div className="program-card__user-icon attending">
                <UserIcon />
              </div>
              {t('noAttendeesYet')}
            </div>
          )}
        </div>

        <EventActionButton webinar={webinar} isEnrolled={isEnrolled} />
      </Space>
    </>
  );
};
