import React from 'react';
import { useIntl } from 'estafette-intl';
import { Link } from 'react-router-dom';
import { ChapterReadOnly } from 'libs/http/api/education/education.types';
import { GoToIcon } from 'resources';
import { getRoute } from 'libs/utils';

interface Props {
  chapter: ChapterReadOnly;
}

export const ChapterBlock: React.FC<Props> = ({ chapter }) => {
  const { t } = useIntl();

  return (
    <div className="chapter-block">
      <div className="chapter-block__image">
        <img src={chapter.cover_photo || '' /*todo default image*/} alt="" />
      </div>
      <div className="chapter-block__body">
        <div className="chapter-block__wrapper">
          <div className="chapter-block__number">
            {t('chapter')} {chapter.number}
            <div className="chapter-block__program-title">{chapter.program?.title}</div>
          </div>
          <Link to={getRoute('WatchProgramPage', { programId: chapter.program.id })}>
            <div className="chapter-block__title">{chapter.title}</div>
          </Link>
          <div className="chapter-block__description">{chapter.description}</div>
        </div>
        <Link className="chapter-block__button" to={getRoute('WatchProgramPage', { programId: chapter.program.id })}>
          <GoToIcon />
        </Link>
      </div>
    </div>
  );
};
