import { useContext, useEffect, useMemo } from 'react';
import { useRequest } from 'estafette';
import { useIntl } from 'estafette-intl';
import dayjs from 'dayjs';
import { Loader } from 'ebs-design';
import { EventsCarousel } from 'features/programs/components/molecules/EventsCarousel/EventsCarousel';
import { Container, HeadTitle } from 'ui/atoms';
import { ProgramCardWide } from 'ui/organisms/ProgramCardWide/ProgramCardWide';
import { WithData } from 'libs/http/api/index.types';
import { events } from 'libs/http/api/events/events';
import { Webinar } from 'libs/http/api/events/events.types';
import { isNextWeek, isThisWeek } from 'libs/date';
import { breakpoints, country } from 'libs/globalVars';
import { UserContext } from 'contexts/UserContext';
import { ResponsiveContext } from 'contexts/ResponsiveContext';
import { isHistory, isMyEvents } from 'libs/utils';

export const EventsPage = () => {
  const { t, locale } = useIntl();
  const { userData } = useContext(UserContext);
  const { width } = useContext(ResponsiveContext);

  const profileWebinars = useRequest<WithData<Webinar>>({ data: {} });
  const webinars = useRequest<WithData<Webinar>>({ data: {} });

  useEffect(() => {
    webinars.request(
      events.webinar.action({
        country:
          country[
            (userData.language as 'en' | 'en-us' | 'ru' | 'pl' | 'ro' | 'pt' | 'de' | 'uk') ||
              (locale as 'en' | 'en-us' | 'ru' | 'pl' | 'ro' | 'pt' | 'de' | 'uk')
          ],
      }),
    );
  }, [userData, locale]);

  useEffect(() => {
    if (userData.id) {
      profileWebinars.request(
        events.profileWebinars.action({
          country:
            country[
              (userData.language as 'en' | 'en-us' | 'ru' | 'pl' | 'ro' | 'pt' | 'de' | 'uk') ||
                (locale as 'en' | 'en-us' | 'ru' | 'pl' | 'ro' | 'pt' | 'de' | 'uk')
            ],
        }),
      );
    }
  }, [userData]);
  //todo end date e date +2hours
  //todo isEnrolled=true daca eventul este din my profile

  //todo
  //todo
  //todo my events daca isEnrolled si now < end date || (watch_replay === true && from date  + 3 to end of the day)
  //todo my history daca (isEnrolled &&  now > end date && !watch_replay )|| ( now > end of the day && watch_replay)

  //todo live and share dispare de pe history

  //todo subtitle (return la fiecare if) -
  //todo attended -> show attended
  //todo not_attended ->  not_attended
  //todo watch_replay === true && now >= date + 3 && now < end of the day -> show when is expired
  //todo watch_replay === true  && (now < date || now > end of the day) -> is enrolled
  //todo status === attended && now > date + 2 -> not_attended
  //todo isEnrolled -> is enrolled
  //todo status ==='pending' -> limited invite

  //todo action button (return la fiecare if) -
  //todo !isEnrolled -> get a free invitation
  //todo status === 'attended' && isEnrolled && now > date + 2 -> rate this masterclass
  //todo watch_replay === true && now >= date + 3 && now < end of the day -> show button to video (url from live_room_url)
  //todo watch_replay === true  &&  now > end of the day && status==='not_attended' -> not_attended label
  //todo watch_replay === true  &&  now > end of the day && status==='attended' -> rate this masterclass
  //todo watch_replay === true -> inregistrarea video in x minute (pana la date + 3)
  //todo watch_replay !== true && status != 'not_attended' && isEnrolled && now > date + 2 -> not_attended
  //todo watch_replay !== true && isEnrolled && now > date - 2 && now < date - 10m -> live in x minutes
  //todo watch_replay !== true && isEnrolled && now > date -10m && now < date +2-> attend now (url from live_room_url)

  const [firstWeek, secondWeek, eventsHistory, myWebinars, enrolledId] = useMemo(() => {
    return [
      !Object.keys(webinars.errors).length ? webinars.data.data?.filter((i) => isThisWeek(i.date)) : [],
      !Object.keys(webinars.errors).length ? webinars.data.data?.filter((i) => isNextWeek(i.date)) : [],
      !Object.keys(profileWebinars.errors).length
        ? profileWebinars.data.data?.filter((i) => isHistory(i) && i.language.toLowerCase() === locale)
        : [],
      !Object.keys(profileWebinars.errors).length
        ? profileWebinars.data.data
            ?.filter((i) => isMyEvents(i) && i.language.toLowerCase() === locale)
            .sort((a, b) => dayjs(a.date).diff(b.date, 'minutes'))
        : [],
      !Object.keys(profileWebinars.errors).length ? profileWebinars.data.data?.map((i) => i.id) : [],
    ];
  }, [webinars, profileWebinars]);

  return (
    <Container className="events-page">
      <Loader loading={profileWebinars.loading || webinars.loading} height="500px">
        {Boolean(myWebinars?.length) && (
          <div className="mb-20 pb-20">
            <HeadTitle>{t('enrolledSeminar')}</HeadTitle>
            {width >= breakpoints.lg &&
              myWebinars.map((i) => (
                <ProgramCardWide key={i.id} image={i.image_url}>
                  <ProgramCardWide.Events webinar={i} isEnrolled />
                </ProgramCardWide>
              ))}
            {width <= breakpoints.lg && <EventsCarousel webinars={myWebinars} isEnrolled />}
          </div>
        )}

        {Boolean(firstWeek?.length) && (
          <div className="mb-30">
            <HeadTitle>{t('this_week')}</HeadTitle>
            <EventsCarousel webinars={firstWeek} enrolledList={enrolledId} />
          </div>
        )}

        {Boolean(secondWeek?.length) && (
          <div className="mb-30">
            <HeadTitle>{t('next_week')}</HeadTitle>
            <EventsCarousel webinars={secondWeek} enrolledList={enrolledId} />
          </div>
        )}
        {Boolean(eventsHistory?.length) && (
          <div className="mb-30 eventsHistory">
            <HeadTitle>{t('History')}</HeadTitle>
            <EventsCarousel webinars={eventsHistory} isEnrolled />
          </div>
        )}
      </Loader>
    </Container>
  );
};
