import React, { useContext } from 'react';
import { Loader } from 'ebs-design';
import { useIntl } from 'hooks/useIntl';
import { DevicesContext } from 'contexts/DevicesContext';
import { DeviceItem } from '../../atoms/DeviceItem';
import { UserDeviceStatus } from 'libs/http/api/users/users.types';
import { SwitchDevicePlaceholder } from 'resources';

interface Props {
  closeTooltip?: () => void;
}

export const SwitchDevicesTooltip: React.FC<Props> = ({ closeTooltip }) => {
  const { activeDevices, loading } = useContext(DevicesContext);

  const { t } = useIntl();

  const handleChoose = () => {
    if (closeTooltip) {
      closeTooltip();
    }
  };

  return (
    <div className="switch-devices-tooltip">
      <div className="switch-devices-tooltip__placeholder">
        <SwitchDevicePlaceholder />
        <span>{t('my_devices')}</span>
      </div>
      <Loader loading={loading} height={200}>
        {activeDevices &&
          activeDevices.map(
            (i, k) =>
              i.id &&
              i.id !== 'None' &&
              i.status !== UserDeviceStatus.loggedOut && <DeviceItem key={k} onChoose={handleChoose} device={i} />,
          )}
      </Loader>
    </div>
  );
};
