import { $Object } from './globalTypes';

export const genders = [
  {
    label: 'Female',
    value: 'female',
  },
  {
    label: 'Male',
    value: 'male',
  },
];

export const languages: $Object = {
  en: 'English',
  ro: 'Romana',
  ru: 'Русский',
  pl: 'Polski',
  pt: 'Português',
};

export const country = {
  en: 'gb',
  'en-us': 'us',
  ru: 'ru',
  pl: 'pl',
  ro: 'ro',
  pt: 'pt',
  de: 'de',
  uk: 'ua',
};

export const pageSize = 20;

export const defaultLang = 'ro';

export const currencies: $Object = {
  GBP: '£',
  USD: '$',
  EUR: '€',
};

export const breakpoints = {
  mobile: 320,
  'small-tablet': 480,
  sm: 612,
  tablet: 640,
  'small-desktop': 768,
  md: 817,
  desktop: 1024,
  lg: 1024,
  xl: 1260,
  wide: 1400,
  xxl: 1248,
  'extra-wide': 1600,
  fhd: 1920,
  qhd: 2560,
  _2k: 2048,
};
