import { useEffect, Dispatch, createContext, ReactElement, SetStateAction, ReactNode } from 'react';
import { useRequest } from 'estafette';
import { Loader } from 'ebs-design';
import { history } from 'libs/history';
import { users } from 'libs/http/api';
import { User } from 'libs/http/api/users/users.types';
import { useStateHandlers } from 'hooks';
import { getRoute } from 'libs/utils';
import { clearCookies, ifAuthTokenExist, setTokens } from 'libs/cookies';

interface Props {
  readonly loading: boolean;
  readonly logged: boolean;
  readonly userData: User;
  setUserData: Dispatch<SetStateAction<User>>;
  onFetchUserData: () => Promise<void>;
  onLogout: (withRefresh?: boolean) => void;
}

export const UserContext = createContext<Props>({
  loading: false,
  logged: false,
  userData: {} as User,
  setUserData: () => ({} as User),
  onFetchUserData: async () => undefined,
  onLogout: (): null => null,
});

export const UserProvider = ({ children }: { children: (values: Props) => ReactNode }): ReactElement => {
  const user = useRequest<User>({
    data: {},
  });

  const [state, setState] = useStateHandlers({ logged: false, loading: false });

  useEffect(() => {
    onFetchUserData();

    if (user.errors && user.errors.detail === 'User not found') {
      clearCookies();

      onRedirect();
    }
  }, []);

  const onFetchUserData = async () => {
    if (ifAuthTokenExist()) {
      user.request(users.get.action()).then(({ language }) => {
        setState({ logged: true });
        setTokens({ language: language });
      });
    }
  };

  const onLogout = (withRefresh = true): void => {
    clearCookies();

    setState({ logged: false });

    user.setData({});

    if (withRefresh) {
      history.go(0);
    }
  };

  const onRedirect = (params?: { [key: string]: any }): void => history.push(getRoute('IndexEntry', params));

  const values = {
    loading: state.loading || user.loading,
    logged: state.logged,
    userData: user.data,
    setUserData: user.setData,
    onFetchUserData,
    onLogout,
  };

  return (
    <UserContext.Provider value={values}>
      <Loader loading={state.loading || user.loading} height="100vh">
        {children(values)}
      </Loader>
    </UserContext.Provider>
  );
};
