import { forwardRef } from 'react';

interface Props {
  value?: boolean;
  onChange?: () => void;
  defaultChecked?: boolean;
  checked?: boolean;
}

export const Switch = forwardRef<HTMLButtonElement, Props>(
  ({ value, onChange, defaultChecked, checked, ...props }, ref) => {
    const localState = defaultChecked || checked;

    return (
      <button
        {...props}
        type="button"
        role="switch"
        ref={ref}
        aria-checked={localState}
        onClick={onChange}
        className={`ant-switch ${localState ? 'ant-switch-checked' : ''}`}
      >
        <div className="ant-switch-handle" />
        <span className="ant-switch-inner" />
      </button>
    );
  },
);
