import { useCallback, useContext, useEffect } from 'react';
import { useRequest } from 'estafette';
import { Link, useParams } from 'react-router-dom';
import cn from 'classnames';
import { load } from 'react-cookies';
import { useIntl } from 'hooks/useIntl';
import { Loader, Space, useNotify } from 'ebs-design';
import { EpisodeReadOnly, ProgramReadOnly } from 'libs/http/api/education/education.types';
import { education } from 'libs/http/api/education/education';
import { WithPagination } from 'libs/http/api/index.types';
import { StorageKeys } from 'libs/cookies';
import { getRoute } from 'libs/utils';
import { UserContext } from 'contexts/UserContext';
import { useTimeUtils } from 'hooks';
import { Bookmark, Lock, Play } from 'resources';

interface Props {
  chapter: number;
  program: ProgramReadOnly;
}

export const Episodes = ({ chapter, program }: Props) => {
  const { logged } = useContext(UserContext);
  const { getTimeFrom } = useTimeUtils();
  const notify = useNotify();
  const { t } = useIntl();
  const token = load(StorageKeys.jwtToken);
  const { episodeId } = useParams<{ episodeId: string }>();

  const episodes = useRequest<WithPagination<EpisodeReadOnly>>({ data: { results: [] } });

  useEffect(() => {
    episodes.request(education.getChapterEpisodes.action(chapter, { page_size: 1000 }));
  }, [chapter]);

  const handleSubScribeFree = () => {
    if (program.payment_type === 'free' && !program.program_subscription) {
      education.subscribeFree.action(program.id);
    }
  };

  const episodePageLink = useCallback(
    (episode: EpisodeReadOnly, key: number) => {
      return episode.access_status === 'subscribed' &&
        ((program.program_subscription && !program.program_subscription.payment_failed) ||
          program.payment_type === 'free') ? (
        <Link
          to={getRoute('WatchEpisodePage', {
            programId: program.id,
            episodeId: episode.id,
          })}
        >{`${key + 1}. ${episode.title}`}</Link>
      ) : episode.access_status !== 'locked' && program?.sales_page ? (
        <a
          href={`${program.sales_page}${token ? `${program.sales_page?.includes('?') ? '&' : '?'}token=${token}` : ''}`}
          target="_blank"
          rel="noreferrer"
          onClick={handleSubScribeFree}
        >
          {`${key + 1}. ${episode.title}`}
        </a>
      ) : (
        <span>{`${key + 1}. ${episode.title}`}</span>
      );
    },
    [program, handleSubScribeFree],
  );

  const onBookmarkAdd = (episode: EpisodeReadOnly) => {
    if (episode.is_bookmarked) {
      return education.deleteBookmark.action(Number(episode.id)).then(() => {
        episodes.setData((prev: WithPagination<EpisodeReadOnly>) => ({
          ...prev,
          results: prev.results?.map((i) => ({ ...i, is_bookmarked: episode.id === i.id ? false : i.is_bookmarked })),
        }));
        notify.success({ title: t('success'), description: t('removed_bookmark') });
      });
    } else {
      try {
        education.addBookmark.action(Number(episode.id)).then(({ data }) => {
          episodes.setData((prev: WithPagination<EpisodeReadOnly>) => ({
            ...prev,
            results: prev.results?.map((i) => ({ ...i, is_bookmarked: episode.id === i.id ? true : i.is_bookmarked })),
          }));
          notify.success({ title: t('success'), description: t('added_bookmark') });
        });
      } catch {
        notify.error({ title: t('error'), description: t('something_wrong') });
      }
    }
  };

  return (
    <div className="episodes">
      <Loader loading={episodes.loading}>
        {episodes.data.results?.map((i, key) => {
          const hasAccess = i.access_status && i.access_status !== 'locked';

          return (
            <div key={i.id}>
              <div className={cn('episodes__item', { active: parseInt(episodeId) === i.id })}>
                <Space direction="vertical" align="start" size="small">
                  <Space size={16} justify="start">
                    <div
                      className={cn('episodes__item-access', { 'has-progress': i.progress })}
                      style={{ ...(hasAccess && { background: `url(${i.cover_photo})` }) }}
                    >
                      <div className={cn('episodes__item-access__cover')}>
                        {!hasAccess ? (
                          <Lock />
                        ) : (
                          <div className="episodes__item-access__cover-play">
                            <Play />
                          </div>
                        )}
                        {i.progress ? (
                          <>
                            <div
                              className="episodes__item-access__cover-progress"
                              style={{ width: `${(i.progress / i.duration) * 100}%` }}
                            />
                            <div className="episodes__item-access__cover-duration" />
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div className="episodes__item__title">
                      {episodePageLink(i, key)}
                      <div className="episodes__item__subtitle">
                        {i.whatched_video || parseInt(episodeId) === i.id
                          ? getTimeFrom(i.duration, true)
                          : i.progress
                          ? `${t('continueForm')}: ${getTimeFrom(i.progress, true)}`
                          : getTimeFrom(i.duration, true)}
                      </div>
                    </div>
                  </Space>

                  {i.description ? (
                    <div className="episodes__item-description">
                      {i.description.length > 111 ? `${i.description.slice(0, 111)}...` : i.description}
                    </div>
                  ) : null}

                  {i.unlock_drip ? (
                    <div className="episodes__item-unlock">
                      {t('episode_unlock').replace('$1', i.unlock_drip.toString())}
                    </div>
                  ) : null}
                </Space>
                {logged && (
                  <Bookmark
                    className={cn('episodes__item__bookmark', { bookmarked: i.is_bookmarked })}
                    onClick={() => onBookmarkAdd(i)}
                  />
                )}
              </div>
            </div>
          );
        })}
      </Loader>
    </div>
  );
};
