import React, { useContext, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { useRequest } from 'estafette';
import { Loader } from 'ebs-design';
import { Scroll, HeadTitle } from 'ui/atoms';
import { ProgressBar } from 'ui/atoms/ProgressBar/ProgressBar';
import { Chapter } from '../Chapter/Chapter';
import { ChapterReadOnly, EpisodeDetail, ProgramReadOnly } from 'libs/http/api/education/education.types';
import { WithPagination } from 'libs/http/api/index.types';
import { education } from 'libs/http/api/education/education';
import { UserContext } from 'contexts/UserContext';
import { useIntl as $useIntl } from 'hooks';

interface Props {
  program: ProgramReadOnly;
  nextEpisode?: EpisodeDetail;
  activeChapter?: number;
  className?: string;
}

export const Chapters: React.FC<Props> = ({ program, nextEpisode, activeChapter, className }) => {
  const { t } = $useIntl();
  const { userData } = useContext(UserContext);

  const chaptersRequest = useRequest<WithPagination<ChapterReadOnly>>();

  useEffect(() => {
    if (program.id) {
      chaptersRequest.request(education.getProgramChapters.action(program.id));
    }
  }, [program]);

  const programProgress = useMemo(() => (program.progress / program.episodes_duration) * 100 || 0, [program]);

  return (
    <div className={cn('chapters', className)}>
      <HeadTitle className="chapters__program-title">{program.title}</HeadTitle>
      {Boolean(userData.id && program.program_subscription && program.access_status === 'subscribed') && (
        <div className="chapters__progress-bar">
          <span>{t('next_episode')}: </span>
          {nextEpisode?.title}
          <ProgressBar progress={programProgress} />
        </div>
      )}
      <Loader loading={chaptersRequest.loading} className="ebs-loader">
        <div className="chapters__wrapper">
          <Scroll height="1350px">
            {chaptersRequest.data?.results?.map((i) => (
              <Chapter chapter={i} key={i.id} activeChapter={activeChapter} program={program} />
            ))}
          </Scroll>
        </div>
      </Loader>
    </div>
  );
};
